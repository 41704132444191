import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PoliciesFilters } from "../../models/policies/PoliciesFilters";
import { environment } from "src/environments/environment";
import { ApiRoutes } from "../../models/app/ApiRoutes";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { PolicyIssueReq } from "../../payloads/requests/policy/PolicyIssueReq";
import { delayedRetry } from "../../helpers/utilities/DelayedRetry.rx";
import { tap } from "rxjs/operators";
import { SmsRequest } from "../../models/smsLogger/SmsRequest";

@Injectable({
  providedIn: "root",
})
export class PoliciesService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient, private router: Router) {}

  /**
   * Fetches a list of policies for the tenant
   *
   * @param {PoliciesFilters} filters
   * @returns {Observable<any>}
   * @memberof PoliciesService
   */
  getPolicies(filters: PoliciesFilters): Observable<any> {
    return this.http.post(this.apiUrl + ApiRoutes.Policies.Search, filters, {
      observe: "response",
    });
  }

  getPolicyIssuePageData(clientQuoteId: string): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}${ApiRoutes.client.QuotationDetails}?ClientQuoteId=${clientQuoteId}`,
        {}
      )
      .pipe(delayedRetry(500, 2));
  }

  getPolicyDetailsPageData(policyId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}${ApiRoutes.Policies.details}${policyId}`)
      .pipe(delayedRetry(500, 2));
  }

  pendingCancellationPolicy(PolicyId: string) {
    return this.http.post(this.apiUrl + ApiRoutes.Policies.initCancel, null, {
      params: { PolicyId },
    });
  }

  /**
   * Sends a policy issue request
   *
   * @param {PolicyIssueReq} policyIssueReq
   * @memberof PoliciesService
   */
  issuePolicy(policyIssueReq: PolicyIssueReq): Promise<any> {
    return this.http
      .post<any>(`${this.apiUrl}${ApiRoutes.Policies.Issue}`, policyIssueReq)
      .toPromise();
  }

  // cancelPolicy(info): Observable<any> {
  //   return this.http
  //     .post(this.apiUrl + ApiRoutes.Policies.cancel, info, {
  //       observe: "response",
  //     })
  //     .pipe(delayedRetry(500, 2));
  // }
  cancelPolicy(info): Observable<any> {
    return this.http.post(this.apiUrl + ApiRoutes.Policies.cancel, info, {
      observe: "response",
    });
  }

  renewalRequest(list) {
    return this.http
      .post(this.apiUrl + ApiRoutes.Policies.RenewalReminder, list)
      .pipe(delayedRetry(500, 2));
  }

  renewPolicy(info) {
    return this.http
      .post(this.apiUrl + ApiRoutes.Quotation.RequestQuote, info)
      .pipe(delayedRetry(500, 2));
  }

  /**
   * Sends a request with a Policy ID to download its doc
   *
   * @param {policyMotorId} The Policy's ID
   * @memberof PoliciesService
   */
  printPolicy(policyMotorId: string): Observable<any> {
    return this.http
      .get(`${this.apiUrl}${ApiRoutes.Policies.Print}/${policyMotorId}`, {
        responseType: "blob",
        observe: "response",
      })
      .pipe(
        tap((res) => {
          let fileName = res.headers.get("Content-FileName");

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([res.body], { type: "application/pdf" });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            let file = new File([res.body], fileName, {
              type: "application/pdf",
            });
            let exportUrl = URL.createObjectURL(file);
            var win = window.open(exportUrl, "_blank");
            win.focus();
          }
        })
      );
  }

  /**
   * Sends a request with a Wataniya Policy ID to download its doc
   *
   * @param {policyMotorId} The Policy's ID
   * @memberof PoliciesService
   */
  printWataniyaCertificate(policyMotorId: string): Observable<any> {
    return this.http
      .get(
        `${this.apiUrl}${ApiRoutes.Policies.WataniyaPrint}/${policyMotorId}`,
        { responseType: "blob", observe: "response" }
      )
      .pipe(
        tap((res) => {
          let fileName = res.headers.get("Content-FileName");

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            let blob = new Blob([res.body], { type: "application/pdf" });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            let file = new File([res.body], fileName, {
              type: "application/pdf",
            });
            let exportUrl = URL.createObjectURL(file);
            var win = window.open(exportUrl, "_blank");
            win.focus();
          }
        })
      );
  }

  // Send SMS or Email to the client
  sendSMSOrEmail(info): Observable<any> {
    return this.http
      .post(this.apiUrl + ApiRoutes.Policies.SendSMSOrEmail, info)
      .pipe(delayedRetry(500, 1));
  }
}
