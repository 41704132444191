import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedIconsModule } from "./shared-icons.module";
import { NgSelectModule } from '@ng-select/ng-select';


// Components
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SidebarIconsModule } from "./components/sidebar/sidebar-icons.module";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { ToastComponent } from "./widgets/toast/toast.component";
import { UpdatesListComponent } from "./components/updates-list/updates-list.component";
import { NotificationsBarComponent } from "./components/notifications-bar/notifications-bar.component";

// Services
import { NavService } from "./services/nav.service";

// Directives
import { ImageCellRendererComponent } from './components/image-cell-renderer/image-cell-renderer.component';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslationModule } from "./translation.module";
import { PolicyShareComponent } from "./components/policy-share/policy-share.component";
import { NgxMaskModule } from "ngx-mask";


@NgModule({

   declarations: [
      HeaderComponent,
      FooterComponent,
      SidebarComponent,
      ContentLayoutComponent,
      FeatherIconsComponent,
      ImageCellRendererComponent,
      ToastComponent,
      UpdatesListComponent,
      NotificationsBarComponent,
      PolicyShareComponent
   ],

   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NgbToastModule,
      NgbModule,
      SidebarIconsModule,
      SharedIconsModule,
      TranslationModule,
      NgSelectModule,
      NgxMaskModule.forRoot()
   ],

   exports: [
      FeatherIconsComponent,
      ToastComponent,
      UpdatesListComponent,
      NotificationsBarComponent,
      PolicyShareComponent
   ],

   providers: [
      NavService
   ],

})


export class SharedModule { }
