import { Component, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { LanguageService } from "../../services/app/language.service";


@Component({
   selector: "app-daterangepicker",
   templateUrl: "./daterangepicker.component.html",
   styleUrls: ["./daterangepicker.component.scss"],
})


export class DaterangepickerComponent {

   @Output() choosedDate = new EventEmitter();

   year = new Date().getFullYear();
   state = {
      lang: this.languageService.getAppLang().value,
      selectedDateRange: null as { startDate: moment.Moment, endDate: moment.Moment },
      isClearButtonVisible: false,
      isPolicy: false,
      q1: new Date(this.year, 0, 1),
      q2: new Date(this.year, 3, 1),
      q3: new Date(this.year, 6, 1),
      q4: new Date(this.year, 9, 1),
      lastDay: new Date(this.year, 11, 31)
   }
   

   isReportsPage(){
      if(window.location.pathname.includes("/yaqeen")){
         this.state.isPolicy = true
         return true
      }else {
         this.state.isPolicy = false
         return false
       }
   }

   // Normal calender
   ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
         moment().subtract(1, "month").startOf("month"),
         moment().subtract(1, "month").endOf("month"),
      ],
   };

   // Fiscal calender{Quarters}
   quarterRanges: any = {
      "First Quarter":  [this.state.q1, this.state.q2.getTime() - 1],
      "Second Quarter": [this.state.q2, this.state.q3.getTime() - 1],
      "Third Quarter":  [this.state.q3, this.state.q4.getTime() - 1],
      "Fourth Quarter": [this.state.q4, this.state.lastDay]
   };


   constructor(private languageService: LanguageService) { }


   onDateSelect(rangesDate): void {
      if (rangesDate.startDate && rangesDate.endDate) {

         this.state.isClearButtonVisible = true;

         const startDate = this.formatDate(rangesDate.startDate._d);
         const endDate = this.formatDate(rangesDate.endDate._d);

         this.state.selectedDateRange = rangesDate;
         this.choosedDate.emit({ startDate: startDate, endDate: endDate });

      } else {
         this.state.isClearButtonVisible = false;
      }
   }


   onDateClear(): void {
      this.state.selectedDateRange = null;
      this.choosedDate.emit({ startDate: null, endDate: null });
      this.state.isClearButtonVisible = false;
   }


   formatDate(date) {

      var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");

   }

}
