import { Component, OnInit } from "@angular/core";
import { SmsLoggerService } from "../../shared/services/sms-logger/sms-logger.service";
import { SmsRequest } from "../../shared/models/smsLogger/SmsRequest";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { SmsLoggerFilters } from "../../shared/models/smsLogger/smsLoggerFilters";
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  GridApi,
} from "ag-grid-community";
import { TenantService } from "src/app/shared/services/app/tenant.service";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import { LanguageService } from "src/app/shared/services/app/language.service";
import { AppLanguage } from "src/app/shared/models/app/AppLanguage";
import TablesColsDefs from "src/app/shared/models/grid/TablesColsDefs";
import { SpreadsheetService } from "src/app/shared/services/app/spreadsheet.service";
import { ExportedBatchDataTypes } from "src/app/shared/models/app/ExportedBatchDataTypes";

@Component({
  selector: "app-sms-logger",
  templateUrl: "./sms-logger.component.html",
  styleUrls: ["./sms-logger.component.scss"],
})
export class SmsLoggerComponent implements OnInit {
  /* State */
  state = {
    formsPreData: {
      staticData: {
        smsStatus: [
          {
            id: 1,
            name: "Send",
            nameAr: "تم الإرسال",
          },
          {
            id: 0,
            name: "Failed",
            nameAr: "فشل الإرسال",
          },
        ],
      },
    },
    sms: {
      filters: { pageNumber: 1, pageSize: 50 } as SmsLoggerFilters,
      list: [] as SmsRequest[],
      totalCount: 0,
    },
  };

  /* UI State */
  uiState = {
    isLoading: false,
    appLang: "",
    tenant: this.tenantService.getTenantFromHost(),
    userRole: undefined,
    isGridReady: false,
    areFiltersVisible: false,
    smsSearch: {
      isLoading: false,
    },
    export: {
      isLoading: false,
      isVisibleDataLoading: false,
    },
    lettersColors: new Map(),
  };

  /* Form */
  smsLoggerFiltersFormGroup: FormGroup<SmsLoggerFilters>;

  constructor(
    private formBuilder: FormBuilder,
    private smsLoggerService: SmsLoggerService,
    private tenantService: TenantService,
    private langService: LanguageService,
    private spreadsheet: SpreadsheetService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.uiState.appLang = this.langService.getAppLang().value;
    //  Get Current Tenant ID
    this.state.sms.filters.financialEntityId =
      this.tenantService.getCurrentTenantId();
  }

  initForm() {
    // Construct form
    this.smsLoggerFiltersFormGroup = this.formBuilder.group({
      smsTo: [null],
      status: [null],
      dateFrom: [null],
      dateTo: [null],
      financialEntityId: [null],
      pageNumber: [1],
      pageSize: [50],
    });
  }

  async fetchAllSms(): Promise<any> {
    // Filters
    const filters = this.smsLoggerFiltersFormGroup.value;
    filters.financialEntityId = this.state.sms.filters.financialEntityId;
    let tempPageSize = this.state.sms.filters.pageSize; // Old page size
    filters.pageSize = 2147483647; // All of em entries, ALL!

    // Get client quotations
    await this.smsLoggerService
      .getSmsLoggerByFilters(filters)
      .toPromise()
      .then((res: any) => {
        this.state.sms.totalCount = JSON.parse(
          res.headers.get("x-pagination")
        ).TotalCount;
        this.state.sms.list = res.body.data;
        //   this.state.sms.totalCount = res.totalRecord;

        // Reset to old page size
        this.state.sms.filters.pageSize = tempPageSize;
      });
  }

  onFilterationDateRangePick(e): void {
    this.smsLoggerFiltersFormGroup.controls.dateFrom.setValue(e.startDate);
    this.smsLoggerFiltersFormGroup.controls.dateTo.setValue(e.endDate);
  }

  filterSmsLogger(): void {
    // Display table loader
    this.smsLoggerGridApi.showLoadingOverlay();

    // Display loader
    this.uiState.smsSearch.isLoading = true;

    // Filters
    const filters = this.smsLoggerFiltersFormGroup.value;
    filters.financialEntityId = this.state.sms.filters.financialEntityId;
    if (filters.status === 1) filters.status = true;
    else if (filters.status === 0) filters.status = false;
    // Set the filters
    this.state.sms.filters = filters;

    // Update data source
    this.smsLoggerGridApi.setDatasource(this.smsLoggerDataSource);
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Ag Grid                                  */
  /* -------------------------------------------------------------------------- */

  smsLoggerGridApi: GridApi = <GridApi>{};

  smsLoggerColumnsDefs = [];

  smsLoggerGridOpts: GridOptions = {
    pagination: true,
    rowModelType: "infinite",
    cacheBlockSize: this.state.sms.filters.pageSize,
    paginationPageSize: this.state.sms.filters.pageSize,
  };

  smsLoggerDataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      // Display table loader
      this.smsLoggerGridApi.showLoadingOverlay();

      // Display loader
      this.uiState.smsSearch.isLoading = true;

      // Get quotations list
      this.smsLoggerService
        .getSmsData(this.state.sms.filters)
        .subscribe((res: any) => {
          this.state.sms.totalCount = JSON.parse(
            res.headers.get("x-pagination")
          ).TotalCount;
          this.state.sms.list = res.body.data;
          //  this.state.sms.totalCount = res.totalRecord;

          // Conceal loader
          this.uiState.smsSearch.isLoading = false;

          // Set table data and count
          params.successCallback(
            this.state.sms.list,
            this.state.sms.totalCount
          );

          // Conceal loader
          this.smsLoggerGridApi.hideOverlay();
        });
    },
  };

  onSmsLoggerGridReady(params) {
    this.smsLoggerGridApi = params.api;
    this.smsLoggerGridApi.setDatasource(this.smsLoggerDataSource);
    this.initSmsLoggerGridLanguage();
  }

  private initSmsLoggerGridLanguage(): void {
    if (this.uiState.appLang === AppLanguage.ENGLISH) {
      // English default table
      this.smsLoggerGridApi.setColumnDefs(TablesColsDefs.SmsLoggerTableEN);
    } else if (this.uiState.appLang === AppLanguage.ARABIC) {
      // Arabic default table
      this.smsLoggerGridApi.setColumnDefs(TablesColsDefs.SmsLoggerTableAr);
    }

    this.smsLoggerGridApi.sizeColumnsToFit();
    this.uiState.isGridReady = true;
  }

  onSmsLoggerPageSizeChange() {
    this.smsLoggerGridApi.paginationSetPageSize(
      Number(this.state.sms.filters.pageSize)
    );
    this.smsLoggerGridOpts.cacheBlockSize = Number(
      this.state.sms.filters.pageSize
    );
    this.smsLoggerGridApi.showLoadingOverlay();
    this.smsLoggerGridApi.setDatasource(this.smsLoggerDataSource);
  }

  onSmsLoggerPageChange(e) {
    if (this.uiState.isGridReady) {
      this.state.sms.filters.pageNumber =
        this.smsLoggerGridApi.paginationGetCurrentPage() + 1;
    }
  }

  onSmsLoggerCsvExport() {
    this.uiState.export.isVisibleDataLoading = true;
    var params = {
      skipHeader: false,
      skipFooters: true,
      skipGroups: true,
      allColumns: true,
      fileName: "SMS Logger Request List",
    };

    this.smsLoggerGridOpts.api.exportDataAsCsv(params);
    this.uiState.export.isVisibleDataLoading = false;
  }

  onExportAll() {
    // Display loader
    this.uiState.export.isLoading = true;

    // Fetch ALL data
    this.fetchAllSms().then(() => {
      // Conceal loader
      this.uiState.export.isLoading = false;

      // Export Spreadsheet
      this.spreadsheet.exportBatchData(
        ExportedBatchDataTypes.SmsLogger,
        this.state.sms.list,
        this.smsLoggerGridApi.getColumnDefs()
      );
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                                End of AgGrid                               */
  /* -------------------------------------------------------------------------- */
}
