import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { content } from "./shared/routes/content-routes";
import { AppRoutes } from "./shared/models/app/AppRoutes";
import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './shared/helpers/guards/auth.guard';
import { UnauthGuard } from './shared/helpers/guards/unauth.guard';


const routes: Routes = [
   {
      path: AppRoutes.Authentication.login.full,
      component: LoginComponent,
      pathMatch: "full",
      canActivate: [UnauthGuard]
   },
   {
      path: "",
      redirectTo: AppRoutes.home.full,
      pathMatch: "full"
   },
   {
      path: "",
      component: ContentLayoutComponent,
      children: content,
      canActivate: [AuthGuard]
   },
   {
      path: "**",
      redirectTo: AppRoutes.home.full,
   }
];


@NgModule({
   imports: [
      RouterModule.forRoot(
         routes, {
         preloadingStrategy: PreloadAllModules,
         relativeLinkResolution: 'legacy'
      }
      )
   ],
   exports: [RouterModule],
})


export class AppRoutingModule { }
