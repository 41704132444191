import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ApiLoggerComponent } from "./api-logger/api-logger.component";
import { AppRoutes } from "../shared/models/app/AppRoutes";
import { SmsLoggerComponent } from './sms-logger/sms-logger.component';


const routes: Routes = [
   {
      path: AppRoutes.Analytics.sub,
      children: [
         {
            path: AppRoutes.Analytics.apiLogger.sub,
            component: ApiLoggerComponent,
         },
         {
            path: AppRoutes.Analytics.smsLogger.sub,
            component: SmsLoggerComponent
         }
      ],
   },
];


@NgModule({
   imports: [RouterModule.forChild(routes)],
   exports: [RouterModule],
})


export class AnalyticsRoutingModule { }
