import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";

export const VehiclesColumnsDefsEn = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "Status",
    field: "isContainActivePolicy",
    sortable: false,
    filter: false,
    cellRenderer: (e) => {
      if (e.value && e.value === true) {
        return "Policy Issued";
      } else {
        return "Pending";
      }
    },
    minWidth: 150,
    cellStyle: StatusBadgeRenderer.clientVehcleStatusBadgeEn,
  },
  {
    headerName: "Expiring After",
    field: "expiringDays",
    cellRenderer: (e) => CellRenderers.dayValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Registration Type",
    field: "vehicleIdTypeName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },

  {
    headerName: "Vehicle ID",
    field: "vehicleId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Chassis No.",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "Plate No.",
    field: "vehiclePlate",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Leasing Contract Period",
    field: "leasingContractNoOfYears",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Maker",
    field: "vehicleMakerNameAr",
    sortable: false,
    filter: false,
    minWidth: 130,
  },
  {
    headerName: "Model",
    field: "vehicleModelNameAr",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Model Year",
    field: "vehicleModelYear",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Major Color",
    field: "vehicleMajorColorNameAr",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Repair Method",
    field: "vehicleRepairMethodName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Body Type",
    field: "vehicleBodyTypeName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Transmission Type",
    field: "vehicleTransmissionTypeName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Use Purpose",
    field: "vehicleUseName",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Weight",
    field: "vehicleWeight",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 100,
  },
  {
    headerName: "Submitted On",
    field: "createdDate",
    sortable: false,
    filter: false,
    cellRenderer: DateRenderer.specificDateFormatterEn,
    minWidth: 250,
  },
  {
    headerName: "Submitted By",
    field: "createdBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
];

export const VehiclesColumnsDefsAr = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "الحالة",
    field: "isContainActivePolicy",
    sortable: false,
    filter: false,
    cellRenderer: (e) => {
      if (e.value && e.value === true) {
        return "وثيقة تأمين";
      } else {
        return "قيد الإنتظار";
      }
    },
    minWidth: 150,
    cellStyle: StatusBadgeRenderer.clientVehcleStatusBadgeAr,
  },
  {
    headerName: "تنتهي بعد",
    field: "expiringIn",
    cellRenderer: (e) => CellRenderers.dayValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "نوع التسجيل",
    field: "vehicleIdTypeName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "الرقم التسلسلي",
    field: "vehicleId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "رقم الهيكل",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "رقم اللوحة",
    field: "vehiclePlate",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "مدة عقد التأجير التمويلي",
    field: "leasingContractNoOfYears",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "الماركة",
    field: "vehicleMakerNameAr",
    sortable: false,
    filter: false,
    minWidth: 130,
  },
  {
    headerName: "الطراز",
    field: "vehicleModelNameAr",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "سنة التصنيع",
    field: "vehicleModelYear",
    sortable: false,
    filter: false,
    minWidth: 120,
  },
  {
    headerName: "اللون",
    field: "vehicleMajorColorNameAr",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "طريقة الصيانة",
    field: "vehicleRepairMethodNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "نوع الهيكل",
    field: "vehicleBodyTypeNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "نوع ناقل السرعة",
    field: "vehicleTransmissionTypeNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "الإستخدام",
    field: "vehicleUseNameAr",
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "الوزن",
    field: "vehicleWeight",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 100,
  },
  {
    headerName: "تاريخ الإنشاء",
    field: "createdDate",
    sortable: false,
    filter: false,
    cellRenderer: DateRenderer.specificDateFormatterAr,
    minWidth: 250,
  },
  {
    headerName: "أنشئ بواسطة",
    field: "createdBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
];
