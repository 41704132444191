import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../../services/app/language.service';


@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss']
})


export class FooterComponent implements OnInit {

   state = {};


   uiState = {
      appLang: '',
      appVersion: environment.appVersion,
      currentYear: new Date().getFullYear()
   };


   constructor(
      private langService: LanguageService
   ) { }


   ngOnInit() {
      this.uiState.appLang = this.langService.getAppLang().value;
   }


}
