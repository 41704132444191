import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";
import { AuthService } from "../auth/auth.service";
import { LanguageService } from "./language.service";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AppService {
  uiState = {
    appLang: "",
  };
  private timeoutId: any;
  // private readonly idleTimeout = 5000; // 5 minutes
  private readonly idleTimeout = 30 * 60 * 1000; // 30 minutes
  areConnectionChecksEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private authService: AuthService,
    private ngZone: NgZone,
    private langService: LanguageService
  ) {
    this.uiState.appLang = this.langService.getAppLang().value;
  }

  startWatching(): void {
    this.resetTimeout();
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener("mousemove", () => this.resetTimeout());
      window.addEventListener("keydown", () => this.resetTimeout());
    });
  }

  private resetTimeout(): void {
    if (this.authService.isLoggedIn.value) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.logout(), this.idleTimeout);
    }
  }

  private logout(): void {
    this.ngZone.run(() => {
      this.displayLogoutDialog();
    });
  }

  // premission dialog
  private displayLogoutDialog(): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-info",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    let isCurrentLangEn = this.uiState.appLang === "en";
    const titleMessage = isCurrentLangEn
      ? "Your session is about to expire."
      : "جلستك على وشك الانتهاء.";
    const message = isCurrentLangEn
      ? "Your organization's policy enforces automatic sign out after a period of inactivity on the applications."
      : "تفرض سياسة مؤسستك تسجيل الخروج التلقائي بعد فترة من عدم النشاط على التطبيقات.";
    swalWithBootstrapButtons
      .fire({
        title: titleMessage,
        text: message,
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Stay signed in",
        allowOutsideClick: () => !Swal.isLoading(),
        timer: 10000,
      })
      .then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          this.authService.logout();
        } else {
          this.resetTimeout();
        }
      });
  }
  setConnectionChecksActivity(areEnabled: boolean): void {
    this.areConnectionChecksEnabled.next(areEnabled);
  }

  getConnectionChecksActivity(): BehaviorSubject<boolean> {
    return this.areConnectionChecksEnabled;
  }

  areConnectionChecksActive(): { isConnectionCheckActivated: boolean } {
    return (
      JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.ConnectionChecks)
      ) || { isConnectionCheckActivated: false }
    );
  }
}
