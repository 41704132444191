import { CellRenderers } from "./CellRenderers";


export const AddressesColumnsDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "City",
      field: "city",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Country",
      field: "country",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "District",
      field: "district",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Latitude",
      field: "latitude",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Longitude",
      field: "longitude",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Postal Code",
      field: "postalCode",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Street",
      field: "street",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Building No.",
      field: "buildingNumber",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Additional No.",
      field: "additionalNumber",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Unit No.",
      field: "unitNo",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Source",
      field: "source",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
];

export const AddressesColumnsDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "المدينة",
      field: "city",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "الدولة",
      field: "country",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "المقاطعة",
      field: "district",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "خط الطول",
      field: "latitude",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "خط العرض",
      field: "longitude",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "الرمز اليريدي",
      field: "postalCode",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "الشارع",
      field: "street",
      tooltip: (e) => { return e.value; },
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "رقم المبنى",
      field: "buildingNumber",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "الرقم الإضافي",
      field: "additionalNumber",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "رقم الوحدة",
      field: "unitNo",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "المصدر",
      field: "source",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
];
