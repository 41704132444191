export class ConfigDB {

   static enData = {
      settings: {
         layout_type: 'ltr',
         sidebar: {
            type: 'compact-wrapper',
            body_type: 'sidebar-icon'
         },
         sidebar_setting: 'default-sidebar',
         sidebar_backround: 'color3-sidebar'
      },
      color: {
         layout_version: 'light',
         color: 'color-2',
         primary_color: '#0288d1',
         secondary_color: '#26c6da',
         mix_layout: 'default'
      },
      router_animation: 'fadeIn'
   }

   static arData = {
      settings: {
         layout_type: 'rtl',
         sidebar: {
            type: 'compact-wrapper',
            body_type: 'sidebar-icon'
         },
         sidebar_setting: 'default-sidebar',
         sidebar_backround: 'color3-sidebar'
      },
      color: {
         layout_version: 'light',
         color: 'color-2',
         primary_color: '#0288d1',
         secondary_color: '#26c6da',
         mix_layout: 'default'
      },
      router_animation: 'fadeIn'
   }

}
