<div class="sms-list-container flex-column page-container">
  <!-- ----------------------------------------------------------------------- -->
  <!--                                 Header                                  -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div class="header flex-container mb-0">
    <!-- Back Button -->
    <a routerLink="" href="javascript:void(0)" class="back">
      <fa-icon icon="arrow-left"></fa-icon>
    </a>

    <!-- Title -->
    <span class="title">{{ "Analytics.Logger.SMS.Title" | translate }}</span>

    <!-- Tools -->
    <div class="tools">
      <!-- Excel Export Menu -->
      <div
        ngbDropdown
        class="tool-menu d-inline-block"
        [autoClose]="uiState.export.isLoading ? false : true"
      >
        <div
          ngbDropdownToggle
          [ngbTooltip]="'MISC.Tools.DataExport' | translate"
          placement="top"
          tooltipClass="app-tooltip"
          class="tool-btn dd-button flex-container"
          style="background: #ff9f40"
        >
          <span
            *ngIf="
              uiState.export.isLoading || uiState.export.isVisibleDataLoading
            "
            class="icon spinner-border spinner-border-sm flex-centered"
            role="status"
            aria-hidden="true"
            style="color: white"
          ></span>
          <fa-icon
            *ngIf="
              !uiState.export.isLoading && !uiState.export.isVisibleDataLoading
            "
            icon="file-export"
            style="color: white"
            class="flex-centered"
          ></fa-icon>
        </div>
        <div ngbDropdownMenu class="mt-2">
          <div
            (click)="onSmsLoggerCsvExport()"
            class="menu-item"
            ngbDropdownItem
          >
            {{ "MISC.Tools.VisibleDataExport" | translate }}
          </div>
          <div (click)="onExportAll()" class="menu-item" ngbDropdownItem>
            <span
              *ngIf="uiState.export.isLoading"
              class="icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{ "MISC.Tools.AllDataExport" | translate }}
          </div>
        </div>
      </div>
      <!-- Advanced Search -->
      <div
        (click)="uiState.areFiltersVisible = !uiState.areFiltersVisible"
        [ngbTooltip]="'MISC.Tools.AdvSearch' | translate"
        placement="top"
        tooltipClass="app-tooltip"
        class="tool-btn"
        style="background: #007bff"
      >
        <fa-icon icon="search" style="color: white"></fa-icon>
      </div>
    </div>
  </div>

  <!-- #endregion -->

  <!-- ----------------------------------------------------------------------- -->
  <!--                                 Filters                                 -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div class="filters accordion-box headerless">
    <div
      class="boxed long content filters-content form-container"
      [class.collapsed]="uiState.areFiltersVisible"
    >
      <!-- Title -->
      <span class="title">{{ "Analytics.Logger.SMS.Search" | translate }}</span>

      <!-- Content -->
      <div
        [formGroup]="smsLoggerFiltersFormGroup"
        class="filters-group content-container grid g-4-cols"
      >
        <!-- sms status -->
        <div class="form-group">
          <label class="form-label">{{
            "Analytics.Logger.SMS.Status" | translate
          }}</label>
          <ng-select
            [items]="state.formsPreData.staticData.smsStatus"
            [loading]="!state.formsPreData.staticData.smsStatus"
            bindLabel="name"
            bindValue="id"
            formControlName="status"
            [placeholder]="'Analytics.Logger.SMS.Status' | translate"
            autocomplete="off"
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{
                uiState.appLang === "ar" ? item.nameAr : item.name
              }}</span>
            </ng-template>
            <ng-template
              ng-option-tmp
              let-item="item"
              let-search="searchTerm"
              let-index="index"
            >
              <span>{{
                uiState.appLang === "ar" ? item.nameAr : item.name
              }}</span>
            </ng-template>
          </ng-select>
        </div>

        <!-- Sms To -->
        <div class="form-group">
          <label class="form-label">{{
            "Analytics.Logger.SMS.SmsTo" | translate
          }}</label>
          <input
            formControlName="smsTo"
            class="form-control"
            type="text"
            [placeholder]="'Analytics.Logger.SMS.SmsTo' | translate"
            autocomplete="off"
          />
        </div>

        <!-- Duration -->
        <div>
          <app-daterangepicker
            (choosedDate)="onFilterationDateRangePick($event)"
          ></app-daterangepicker>
        </div>

        <!-- Placeholders -->
        <!-- <div></div> -->

        <!-- Search Button -->
        <div class="filter-btn">
          <button type="submit" class="btn primary" (click)="filterSmsLogger()">
            <fa-icon
              *ngIf="!uiState.smsSearch.isLoading"
              icon="search"
            ></fa-icon>
            <span
              *ngIf="uiState.smsSearch.isLoading"
              style="color: white"
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            >
            </span>
            <span>{{ "MISC.Search" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- #endregion -->

  <!-- ----------------------------------------------------------------------- -->
  <!--                              Policies List                              -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div>
    <!-- ----------------------------------------------------------------------- -->
    <!--                               Table Tools                               -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="flex-container pt-2 pr-1 pb-2 pl-0">
      <div class="flex-centered ml-0 mr-3" style="width: 150px">
        <ng-select
          [clearable]="false"
          (change)="onSmsLoggerPageSizeChange()"
          [(ngModel)]="state.sms.filters.pageSize"
        >
          <ng-option [value]="5">{{
            "MISC.Tools.PageSelector.5PerPage" | translate
          }}</ng-option>
          <ng-option [value]="10">{{
            "MISC.Tools.PageSelector.10PerPage" | translate
          }}</ng-option>
          <ng-option [value]="20">{{
            "MISC.Tools.PageSelector.20PerPage" | translate
          }}</ng-option>
          <ng-option [value]="30">{{
            "MISC.Tools.PageSelector.30PerPage" | translate
          }}</ng-option>
          <ng-option [value]="40">{{
            "MISC.Tools.PageSelector.40PerPage" | translate
          }}</ng-option>
          <ng-option [value]="50">{{
            "MISC.Tools.PageSelector.50PerPage" | translate
          }}</ng-option>
          <ng-option [value]="100">{{
            "MISC.Tools.PageSelector.100PerPage" | translate
          }}</ng-option>
        </ng-select>
      </div>
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                                  Table                                  -->
    <!-- ----------------------------------------------------------------------- -->

    <ag-grid-angular
      [gridOptions]="smsLoggerGridOpts"
      (gridReady)="onSmsLoggerGridReady($event)"
      (paginationChanged)="onSmsLoggerPageChange($event)"
      [columnDefs]="smsLoggerColumnsDefs"
      suppressRowClickSelection="true"
      [enableRtl]="uiState.appLang === 'ar'"
      style="width: 100%; height: 67vh"
      class="ag-theme-alpine"
    >
    </ag-grid-angular>
  </div>

  <!-- #endregion -->
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                                 Loader                                  -->
<!-- ----------------------------------------------------------------------- -->
<!-- #region -->

<div *ngIf="uiState.isLoading" class="page-loading-container">
  <app-loading
    [message]="'MESSAGES.Loading.QuotationsList' | translate"
  ></app-loading>
</div>

<!-- #endregion -->
