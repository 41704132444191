import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TenantService } from "../../services/app/tenant.service";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenantService) {}

  intercept(
    request: HttpRequest<any>,
    handler: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentTenant =
      this.tenantService.getCurrentTenant().value ??
      this.tenantService.getTenantFromHost();
    let headers = request.headers.append("X-Tenant-ID", "0");

    request = request.clone({
      headers: headers,
    });

    // Handle the request and move into next interceptors if available
    return handler.handle(request);
  }
}
