<div class="page-main-header " [ngClass]="{ open: navService.collapseSidebar }" id="page-main-header" [style]="navService.collapseSidebar === false &&uiState.appLang =='ar' ?
   'margin-right:126px !important;width:calc(100% - 126px) !important;margin-left:0px !important;'
   :navService.collapseSidebar === false &&uiState.appLang !=='ar' ?
   'margin-left:126px !important;margin-right:0px !important;width:calc(100% - 126px) !important;':''">
  <div class="main-header-right row">
    <!-- ----------------------------------------------------------------------- -->
    <!--                               Tenant Logo                               -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->



    <!-- #endregion -->

    <!-- ----------------------------------------------------------------------- -->
    <!--                             Sidebar Toggler                             -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->
    <!-- [ngClass]="navService.collapseSidebar ? '':'d-none'" -->
    <div class="mobile-sidebar " *ngIf="navService.screenWidth < 992 ">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>

    <!-- #endregion -->
    <div class="main-header-left " *ngIf="navService.screenWidth > 992 ">
      <div class="logo-wrapper">
        <a routerLink="/home"><img style="padding: 5px;" src="assets/images/favicon.ico" alt="Logo" height="50" /></a>
      </div>
    </div>
    <!-- ----------------------------------------------------------------------- -->
    <!--                         Tenant Welcome Message                          -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->

    <div class="tenant-welcome-msg">
      <span *ngIf="uiState.appLang === 'en'">
        {{ "MISC.Welcome" | translate }}, oasis

      </span>
      <span *ngIf="uiState.appLang === 'ar'">
        {{ "MISC.Welcome" | translate }}, أويسيس

      </span>
    </div>

    <!-- #endregion -->

    <!-- ----------------------------------------------------------------------- -->
    <!--                              Header Items                               -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->

    <div class="nav-right col">
      <!-- ----------------------------------------------------------------------- -->
      <!--                                Nav Menus                                -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <ul class="nav-menus" [ngClass]="{ open: openNav }">
        <!-- Place Holders -->
        <!-- #region -->
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <!-- #endregion -->

        <!-- ----------------------------------------------------------------------- -->
        <!--                           Notifications Menu                            -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- #region -->

        <li class="onhover-dropdown">
          <div *ngIf="uiState.app.isUpdateAvailable" class="notification-badge flex-container">
            <span class="flex-centered">1</span>
          </div>

          <app-feather-icons [icon]="'bell'"></app-feather-icons>

          <span *ngIf="uiState.app.isUpdateAvailable" class="dot"></span>

          <ul class="notification-dropdown onhover-show-div">
            <li>
              {{ "MISC.Notifications" | translate }}
              <span class="badge badge-pill badge-primary pull-right">
                {{ uiState.app.isUpdateAvailable ? 1 : 0 }}
              </span>
            </li>
            <li *ngIf="uiState.app.isUpdateAvailable" (click)="applyUpdates()">
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0">
                    <span>
                      <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                    </span>
                    {{ "MISC.NewUpdate" | translate }}
                  </h6>
                  <p class="mb-0">{{ "MISC.Update" | translate }}</p>
                </div>
              </div>
            </li>
          </ul>
        </li>

        <!-- #endregion -->

        <!-- ----------------------------------------------------------------------- -->
        <!--                              Language Menu                              -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- #region -->

        <li class="onhover-dropdown">
          <!-- Lang Switch Link -->
          <a (click)="switchAppLanguage()" class="txt-dark" href="javascript:void(0)">
            <h6 style="text-transform: uppercase">{{ uiState.appLang }}</h6>
          </a>

          <!-- Languages Menu right: 10px; -->
          <ul class="lang-dropdown profile-dropdown onhover-show-div p-15" style="top: 45px">
            <!-- Arabic -->
            <li>
              <a (click)="setAppLanguage('ar')" href="javascript:void(0)">
                <img src="../../../../assets/images/flags/KsaFlag.png" alt=""
                  style="width: 23px; height: 15px; margin: auto 7px" />
                العربية
              </a>
            </li>

            <!-- English -->
            <li>
              <a (click)="setAppLanguage('en')" href="javascript:void(0)">
                <img src="../../../../assets/images/flags/UkFlag.png" alt=""
                  style="width: 23px; height: 15px; margin: auto 7px" />
                English
              </a>
            </li>
          </ul>
        </li>

        <!-- #endregion -->

        <!-- ----------------------------------------------------------------------- -->
        <!--                                User Menu                                -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- #region -->

        <li class="onhover-dropdown">
          <!-- Avatar -->
          <div class="media align-items-center">
            <div class="client-avatar flex-container user-log">
              <div class="flex-centered">{{ state.user.nameLetters }}</div>
            </div>
            <div class="dotted-animation">
              <span class="animate-circle"></span>
              <span class="main-circle"></span>
            </div>
          </div>

          <!-- Menu Items -->
          <ul class="profile-dropdown onhover-show-div p-20">
            <!-- Profile -->
            <!-- <li>
                     <a href="javascript:void(0)">
                        <app-feather-icons [icon]="'user'"></app-feather-icons>
                        Profile
                     </a>
                  </li> -->

            <!-- Settings -->
            <li>
              <a (click)="toggleNotificationsBar()" href="javascript:void(0)">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>
                {{ "MISC.Settings" | translate }}
              </a>
            </li>

            <!-- Password Reset -->
            <!-- <li>
                     <a (click)="openPasswordResetModal()" href="javascript:void(0)">
                        <app-feather-icons [icon]="'lock'"></app-feather-icons>
                        Reset Password
                     </a>
                  </li> -->

            <!-- Logout -->
            <li>
              <a (click)="logout()" href="javascript:void(0)">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                {{ "MISC.Logout" | translate }}
              </a>
            </li>
          </ul>
        </li>

        <!-- #endregion -->
      </ul>

      <!-- #endregion -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                            Mobile Nav Toggle                            -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>

      <!-- #endregion -->
    </div>

    <!-- #endregion -->
  </div>
</div>
<!-- navvv -->

<!-- ----------------------------------------------------------------------- -->
<!--                          Password Reset Modal                           -->
<!-- ----------------------------------------------------------------------- -->
<!-- #region -->

<ng-template #passwordResetModal let-modal>
  <!-- ----------------------------------------------------------------------- -->
  <!--                                 Header                                  -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Password Reset</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- #endregion -->

  <!-- ----------------------------------------------------------------------- -->
  <!--                           Password Reset Form                           -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div class="modal-body">
    <form [formGroup]="passwordResetFormGroup" class="flex-centered flex-column form-container">
      <!-- Error(s) Alert -->
      <ngb-alert *ngIf="uiState.alert.error.isVisible" type="danger"
        (close)="uiState.alert.error.isVisible = !uiState.alert.error.isVisible"
        style="width: 100%; margin-bottom: -20px; margin-top: 15px">
        <small>{{ uiState.alert.error.message }}</small>
        <small *ngIf="uiState.alert.error.validationErrors">
          <ul class="errors-list">
            <li *ngFor="let error of uiState.alert.error.validationErrors">
              {{ error }}
            </li>
          </ul>
        </small>
      </ngb-alert>

      <!-- Success Alert -->
      <ngb-alert *ngIf="uiState.alert.success.isVisible" type="success" (close)="
          uiState.alert.success.isVisible = !uiState.alert.success.isVisible
        ">
        <small>{{ uiState.alert.success.message }}</small>
      </ngb-alert>

      <!-- Form Inputs -->
      <div class="inputs">
        <!-- #region Email  -->

        <!-- Input -->
        <div class="input-group flex-container">
          <i class="input-icon fa fa-user flex-centered"></i>
          <input formControlName="emailCtrl" type="email" placeholder="Email" />
        </div>

        <!-- Validation Errors -->
        <div class="validation" *ngIf="
            uiState.passwordReset.isSubmitting &&
            passwordResetForm.emailCtrl.errors
          ">
          <small class="v-message" *ngIf="passwordResetForm.emailCtrl.errors.required">
            Field is empty
          </small>
          <small class="v-message" *ngIf="passwordResetForm.emailCtrl.errors.pattern">
            Invalid email
          </small>
        </div>

        <!-- #endregion -->

        <!-- #region Verification Code -->

        <!-- Input -->
        <div *ngIf="uiState.passwordReset.step === 'ResetRequest'" class="input-group flex-container"
          style="width: 100%">
          <i class="input-icon fa fa-lock flex-centered"></i>
          <input formControlName="codeCtrl" placeholder="Verification Code" type="text" class="verification-code-ipt" />
        </div>

        <!-- Validation Errors -->
        <div class="validation" *ngIf="
            uiState.passwordReset.step === 'ResetRequest' &&
            uiState.passwordReset.isSubmitting &&
            passwordResetForm.codeCtrl.errors
          ">
          <small class="v-message" *ngIf="passwordResetForm.codeCtrl.errors.required">
            Field is empty
          </small>
        </div>

        <!-- #endregion -->

        <!-- #region New Password -->

        <!-- Input -->
        <div *ngIf="uiState.passwordReset.step === 'ResetRequest'" class="input-group flex-container"
          style="width: 100%">
          <i class="input-icon fa fa-key flex-centered"></i>
          <input formControlName="newPasswordCtrl" placeholder="New Password"
            [type]="uiState.isPasswordVisible ? 'text' : 'password'" class="verification-code-ipt" />
          <i (click)="uiState.isPasswordVisible = !uiState.isPasswordVisible" class="password-eye bouncy" [ngClass]="
              uiState.isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
            "></i>
        </div>

        <!-- Validation Errors -->
        <div *ngIf="
            uiState.passwordReset.step === 'ResetRequest' &&
            uiState.passwordReset.isSubmitting &&
            passwordResetForm.newPasswordCtrl.errors
          " class="validation">
          <small class="v-message" *ngIf="passwordResetForm.newPasswordCtrl.errors.required">
            Field is empty
          </small>
        </div>

        <!-- #endregion -->
      </div>
    </form>
  </div>

  <!-- #endregion -->

  <!-- ----------------------------------------------------------------------- -->
  <!--                                 Footer                                  -->
  <!-- ----------------------------------------------------------------------- -->
  <!-- #region -->

  <div class="modal-footer">
    <!-- Issue Reset Request Button -->
    <button *ngIf="uiState.passwordReset.step === 'ResetIssue'" (click)="issuePasswordReset()"
      class="submit-btn bouncy flex-centered">
      <span *ngIf="uiState.passwordReset.isLoading" class="icon spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      <fa-icon *ngIf="!uiState.passwordReset.isLoading" icon="user-check" size="md"></fa-icon>
    </button>

    <!-- Password Reset -->
    <button *ngIf="uiState.passwordReset.step === 'ResetRequest'" (click)="resetPassword()"
      class="submit-btn bouncy flex-centered">
      <span *ngIf="uiState.passwordReset.isLoading" class="icon spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      <fa-icon *ngIf="!uiState.passwordReset.isLoading" icon="user-check" size="md"></fa-icon>
    </button>
  </div>

  <!-- #endregion -->
</ng-template>

<!-- #endregion -->