export class LettersColors {
    
    static AlphaColors: { letter: string; color: string }[] = [
        {
            letter: 'A',
            color: '#8A9A5B'
        },
        {
            letter: 'B',
            color: '#FAD6A5'
        },

        {
            letter: 'C',
            color: '#7C4848'
        },

        {
            letter: 'D',
            color: '#66023C'
        },

        {
            letter: 'E',
            color: '#D9004C'
        },

        {
            letter: 'F',
            color: '#4166F5'
        },

        {
            letter: 'G',
            color: '#014421'
        },

        {
            letter: 'H',
            color: '#664228'
        },

        {
            letter: 'I',
            color: '#C5B358'
        },

        {
            letter: 'J',
            color: '#C80815'
        },

        {
            letter: 'K',
            color: '#43B3AE'
        },

        {
            letter: 'L',
            color: '#D9381E'
        },

        {
            letter: 'M',
            color: '#40826D'
        },

        {
            letter: 'N',
            color: '#009698'
        },

        {
            letter: 'O',
            color: '#9F1D35'
        },

        {
            letter: 'P',
            color: '#A2ADD0'
        },

        {
            letter: 'Q',
            color: '#FC6C85'
        },

        {
            letter: 'R',
            color: '#A75502'
        },

        {
            letter: 'S',
            color: '#722F37'
        },

        {
            letter: 'T',
            color: '#673147'
        },

        {
            letter: 'U',
            color: '#FF007C'
        },

        {
            letter: 'V',
            color: '#56887D'
        },

        {
            letter: 'W',
            color: '#C9A0DC'
        },

        {
            letter: 'X',
            color: '#C19A6B'
        },

        {
            letter: 'Y',
            color: '#0014A8'
        },

        {
            letter: 'Z',
            color: '#39A78E'
        }

    ];

    public static  getClientBg(letter) {
        const data = this.AlphaColors.filter(color => color.letter == letter)[0];
        return data.color;
    }
}





