import { DraftPolicyQuote } from "../../payloads/requests/quote/DraftPolicyQuote";
import { QuotePrintReq } from "../../payloads/requests/quote/QuotePrintReq";
import { Quote } from "../../models/quote/Quote";
import { InsuranceCompaniesIds } from "../../models/insurance/InsuranceCompaniesIds";
import { environment } from "src/environments/environment";
import { PolicyIssueReq } from "../../payloads/requests/policy/PolicyIssueReq";
import { PolicyIssueForm } from "../../models/insurance/PolicyIssueForm";
import { InsuranceService } from "../../services/insurance/insurance.service";
import AppUtils from "./AppUtils";
import { PremiumBreakdownsTypeIds } from "../../models/quote/PremiumBreakdownsTypeIds";

export default class QuoteUtils {
  /* -------------------------------------------------------------------------- */
  /*                             Models Construction                            */
  /* -------------------------------------------------------------------------- */
  //#region

  public static constructPolicyIssueReq(
    selectedQuote: Quote,
    selectedClientId?: string,
    selectedVehicleId?: string,
    policyIssueForm?: PolicyIssueForm,
    clientQuotationId?: string
  ): PolicyIssueReq {
    const policyIssueReq: PolicyIssueReq = {
      clientQuotationId: clientQuotationId || null,
      email: policyIssueForm.email,
      contractNumber: policyIssueForm.contractNumber,
      phoneNumber: policyIssueForm.phoneNumber,
      policyEffectiveDate: policyIssueForm.policyEffectiveDate,
      vehicleIdTypeId: policyIssueForm.vehicleIdTypeId,
      vehicleId: policyIssueForm.vehicleId
        ? AppUtils.strToNum(policyIssueForm.vehicleId.toString())
        : null,
      vehiclePlateNumber: policyIssueForm.vehiclePlateNumber
        ? AppUtils.strToNum(policyIssueForm.vehiclePlateNumber.toString())
        : null,
      vehiclePlateFirstLetterId: policyIssueForm.vehiclePlateFirstLetterId,
      vehiclePlateSecondLetterId: policyIssueForm.vehiclePlateSecondLetterId,
      vehiclePlateThirdLetterId: policyIssueForm.vehiclePlateThirdLetterId,
      vehicleChassisNumber: policyIssueForm.vehicleChassisNumber,
      vehiclePlateTypeId: policyIssueForm.vehiclePlateTypeId,
      vehicleBodyTypeId: policyIssueForm.vehicleBodyTypeId,
      vehicleRegistrationCityId: policyIssueForm.vehicleRegistrationCityId,
      vehicleRegistrationExpiryDate:
        policyIssueForm.vehicleRegistrationExpiryDate,
      vehicleWeight: policyIssueForm.vehicleWeight,
      vehicleAge: policyIssueForm.vehicleAge,
      depreciationRate: policyIssueForm.depreciationRate,
      vehicleUseId: policyIssueForm.vehicleUseId,
    };

    return policyIssueReq;
  }

  /**
   * Constructs a Draft Policy quote that'll be used in a Draft Issue Policy request
   *
   * @static
   * @param {Quote} selectedQuote
   * @returns {DraftPolicyQuote}
   * @memberof QuoteUtils
   */
  public static constructDraftPolicyIssueReq(
    selectedQuote: Quote,
    selectedClientId?: string,
    selectedVehicleId?: string
  ): DraftPolicyQuote {
    // Deep clone the selected quote
    selectedQuote = JSON.parse(JSON.stringify(selectedQuote));

    // Construct the request to issue the draft policy
    const draftPolicyIssueReq: DraftPolicyQuote = {
      quotationRequestId: selectedQuote.quotationReqtId,
      clientId: selectedClientId,
      quotationProductId: selectedQuote.products[0].quotationProductId,
      clientVehicleId: selectedVehicleId,
      insuranceCompanyId: selectedQuote.insuranceCompanyId,
      requestReferenceId: selectedQuote.requestReferenceId,
      insurQuotationId: selectedQuote.products[0].quotationId,
      quotationStartDate: selectedQuote.quotationStartDate,
      quotationEndDate: selectedQuote.quotationEndDate,
      deductibleValue:
        selectedQuote.products[0].selectedDeductible.deductibleValue,
      policyPremium: selectedQuote.products[0].selectedDeductible.policyPremium,
      basePremium: selectedQuote.products[0].selectedDeductible.basePremium,
      totalAdditioanlBenefits:
        selectedQuote.products[0].selectedDeductible.totalAdditionalBenefits,
      totalPaidAmount:
        selectedQuote.products[0].selectedDeductible.totalPaidAmount,
      taxableAmount: selectedQuote.products[0].selectedDeductible.taxableAmount,
      benefits: selectedQuote.products[0].benefits,
      premiumBreakdowns:
        selectedQuote.products[0].selectedDeductible.premiumBreakdowns,
      discounts: selectedQuote.products[0].selectedDeductible.discounts,
    };

    // Condition the benefits
    draftPolicyIssueReq.benefits.forEach((b) => {
      delete b.name;
      delete b.nameAr;
      delete b.benefitTaxableAmount;
      if (!b.isBaseOnDeductible) b.isBaseOnDeductible = false;
    });

    // Delete uneeded data
    draftPolicyIssueReq.premiumBreakdowns.forEach((pmbd) => {
      delete pmbd.name;
      delete pmbd.nameAr;
    });
    draftPolicyIssueReq.discounts.forEach((b) => {
      delete b.name;
      delete b.nameAr;
    });
    draftPolicyIssueReq.benefits = [
      ...new Map(
        draftPolicyIssueReq.benefits.map((item, key) => [
          item["benefitId"],
          item,
        ])
      ).values(),
    ];

    return draftPolicyIssueReq;
  }

  public static constructQuotePrintReq(
    selectedQuote: Quote,
    selectedVehicleId?: string,
    depreciationRate?: number
  ): QuotePrintReq {
    // Deep clone the selected quote
    selectedQuote = JSON.parse(JSON.stringify(selectedQuote));

    // Inject auth service
    let insuranceService: InsuranceService = InsuranceService.injector.get(
      InsuranceService
    );

    // Construct the request to set the selected quote
    const quotePrintReq: QuotePrintReq = {
      quotationRequestId: selectedQuote.quotationReqtId,
      clientId: insuranceService.getQuotationSessionData().value.clientId,
      quotationProductId: selectedQuote.products[0].quotationProductId,
      quotationStartDate: selectedQuote.quotationStartDate,
      quotationEndDate: selectedQuote.quotationEndDate,
      insuranceCompanyId: selectedQuote.insuranceCompanyId,
      insuranceCompanyName: selectedQuote.insuranceCompany.name,
      requestReferenceId: selectedQuote.requestReferenceId,
      insurQuotationId: selectedQuote.products[0].quotationId,
      clientVehicleId: selectedVehicleId,
      depreciationRate: depreciationRate,
      totalAdditioanlBenefits:
        selectedQuote.products[0].selectedDeductible.totalAdditionalBenefits,
      taxableAmount: selectedQuote.products[0].selectedDeductible.taxableAmount,
      basePremium: selectedQuote.products[0].selectedDeductible.basePremium,
      policyPremium: selectedQuote.products[0].selectedDeductible.policyPremium,
      technicalPremium:
        selectedQuote.products[0].selectedDeductible.technicalPremium || 0,
      totalPaidAmount:
        selectedQuote.products[0].selectedDeductible.totalPaidAmount,
      deductibleValue:
        selectedQuote.products[0].selectedDeductible.deductibleValue,
      maxLiability: selectedQuote.products[0].selectedDeductible.maxLiability,
      benefits: selectedQuote.products[0].benefits,
      premiumBreakdowns:
        selectedQuote.products[0].selectedDeductible.premiumBreakdowns,
      discounts: selectedQuote.products[0].selectedDeductible.discounts,
    };

    // Condition the benefits
    quotePrintReq.benefits.forEach((b) => {
      delete b.benefitTaxableAmount;
      if (!b.isBaseOnDeductible) b.isBaseOnDeductible = false;
    });

    return quotePrintReq;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                      Insurance Companies Calculations                      */
  /* -------------------------------------------------------------------------- */
  //#region

  /**
   * Initializes insurance company specific properties and calculations
   *
   * @static
   * @param {Quote} quote
   * @returns {Quote}
   * @memberof QuoteUtils
   */
  public static initInsuranceCompanyProperties(quote: Quote): Quote {
    switch (quote.insuranceCompanyId) {
      case InsuranceCompaniesIds.Tauwuniya:
        this.conductTauwuniyaCalcs(quote);
        break;

      case InsuranceCompaniesIds.MedGulf:
        this.conductMedGulfCalcs(quote);
        break;

      case InsuranceCompaniesIds.Wataniya:
        this.conductWataniyaCalcs(quote);
        break;

      case InsuranceCompaniesIds.GGI:
        this.conductGGICalcs(quote);
        break;

      case InsuranceCompaniesIds.Rajhi:
        this.conductRajhiCalcs(quote);
        break;
    }

    return quote;
  }

  /**
   * Conducts Tauwuniya Insurance Company related calculations
   *
   * @private
   * @static
   * @param {Quote} quote The quote
   * @memberof QuoteUtils
   */
  private static conductTauwuniyaCalcs(quote: Quote): void {
    // Get the VAT breakdown
    let vatPremiumBreakdown = quote.products[0].selectedDeductible.premiumBreakdowns.filter(
      (pmbd) => pmbd.breakdownTypeId === 5
    )[0];

    if (!vatPremiumBreakdown) {
      // Add the VAT breakdown
      quote.products.forEach((p) => {
        p.deductibles.forEach((d) => {
          d.premiumBreakdowns.push({
            breakdownTypeId: 5,
            name: "Value Added Tax (VAT)",
            nameAr: "ضريبة القيمة المضافة",
            breakdownAmount: d.taxableAmount * environment.SaudiVatPercentage,
          });
        });
      });
    }
  }

  /**
   * Conducts MedGulf Insurance Company related calculations
   *
   * @private
   * @static
   * @param {Quote} quote The quote
   * @memberof QuoteUtils
   */
  private static conductMedGulfCalcs(quote: Quote): void {
    // Get the VAT breakdown
    let vatPremiumBreakdown = quote.products[0].selectedDeductible.premiumBreakdowns.filter(
      (pmbd) => pmbd.breakdownTypeId === 5
    )[0];

    if (!vatPremiumBreakdown) {
      // Add the VAT breakdown
      quote.products.forEach((p) => {
        p.deductibles.forEach((d) => {
          d.premiumBreakdowns.push({
            breakdownTypeId: 5,
            name: "Value Added Tax (VAT)",
            nameAr: "ضريبة القيمة المضافة",
            breakdownAmount: d.taxableAmount * environment.SaudiVatPercentage,
          });
        });
      });
    }

    /* quote.products.forEach(p => {
         p.deductibles.forEach(d => {
            d.policyPremium += (d.policyPremium * environment.SaudiVatPercentage);
         });
      }); */

    // Get the VAT Breakdown of the selected deductible
    /* let vatPremiumBreakdown = quote.products[0].selectedDeductible.premiumBreakdowns.filter(pmbd => pmbd.breakdownTypeId === 5)[0]; */

    // Calculate the total paid amount for the selected deductible
    /* quote.products[0].selectedDeductible.totalPaidAmount += vatPremiumBreakdown.breakdownAmount; */
  }

  /**
   * Conducts Wataniya Insurance Company related calculations
   *
   * @private
   * @static
   * @param {Quote} quote The quote
   * @memberof QuoteUtils
   */
  private static conductWataniyaCalcs(quote: Quote): void {
    // Filter breakdowns
    quote.products.forEach((p) => {
      p.deductibles.forEach((d) => {
        d.premiumBreakdowns = d.premiumBreakdowns.filter(
          (pmbd) =>
            !(
              pmbd.breakdownTypeId ==
              PremiumBreakdownsTypeIds.AnnualPremiumAfterNCD
            ) &&
            !(pmbd.breakdownTypeId == PremiumBreakdownsTypeIds.BasicPremium) &&
            !(
              pmbd.breakdownTypeId ==
              PremiumBreakdownsTypeIds.AnnualPremiumBeforeNCD
            )
        );
      });
    });

    // Add Admin Fees VAT to Total VAT
    /* quote.products[0].deductibles.forEach(d => {

         // Calculate admin fees VAT
         let adminFees = d.premiumBreakdowns.filter(pmbd => pmbd.breakdownTypeId == PremiumBreakdownsTypeIds.AdminFees)[0].breakdownAmount;
         let adminFeesVAT = (adminFees * environment.SaudiVatPercentage);

         // Add admin fees VAT to total VAT premium breakdown
         let vat = d.premiumBreakdowns.filter(pmbd => pmbd.breakdownTypeId == PremiumBreakdownsTypeIds.VAT)[0];
         vat.breakdownAmount += adminFeesVAT;

         // Add admin fees VAT to total paid amount
         d.totalPaidAmount += adminFeesVAT;

      }); */
  }

  /**
   * Conducts GGI Insurance Company related calculations
   *
   * @private
   * @static
   * @param {Quote} quote The quote
   * @memberof QuoteUtils
   */
  private static conductGGICalcs(quote: Quote): void {
    // Get the VAT breakdown
    let vatPremiumBreakdown = quote.products[0].selectedDeductible.premiumBreakdowns.filter(
      (pmbd) => pmbd.breakdownTypeId === 5
    )[0];

    if (!vatPremiumBreakdown) {
      // Add the VAT breakdown
      quote.products.forEach((p) => {
        p.deductibles.forEach((d) => {
          d.premiumBreakdowns.push({
            breakdownTypeId: 5,
            name: "Value Added Tax (VAT)",
            nameAr: "ضريبة القيمة المضافة",
            breakdownAmount: d.taxableAmount * environment.SaudiVatPercentage,
          });
        });
      });
    }
  }

  /**
   * Conduct Al-Rajhi Insurance Company related calculations
   *
   * @private
   * @static
   * @param quote
   * @memberof QuoteUtils
   */
  private static conductRajhiCalcs(quote: Quote): void {
    // Remove the deductible with the '0' amount from the deductibles list
    quote.products[0].deductibles = quote.products[0].deductibles.filter(
      (d) => d.deductibleValue !== 0
    );

    // Update selected deductible
    quote.products[0].selectedDeductible = quote.products[0].deductibles[0];
  }

  //#endregion
}
