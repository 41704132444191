export enum InsuranceCompaniesIds {
  Wataniya = 1,
  Tauwuniya = 2,
  TokioMarine = 3,
  MedGulf = 4,
  GGI = 5,
  Rajhi = 6,
  Walaa = 8,
  Etihad = 14,
}
