import { Component, OnInit } from "@angular/core";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import { PolicyCancelComponent } from "src/app/policies/policy-cancel/policy-cancel.component";
import { CancelledPoliciesListComponent } from "../cancelled-policies-list.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-edit-cancelled-btn",
  template: `
    <span style="border-radius:5px ; padding:5px" (click)="edit()">
      <fa-icon
        [icon]="['far', 'edit']"
        size="lg"
        style="color: black; "
      ></fa-icon>
    </span>
  `,
  styles: [],
})
export class EditCancelledBtnComponent implements ICellRendererAngularComp {
  id: any;
  uiState = {
    isLoading: false,
    modalConfig: {
      centered: true,
      size: "lg",
      backdrop: "static",
    } as NgbModalOptions,
  };
  public params!: ICellRendererParams;
  public comp!: CancelledPoliciesListComponent;
  modalRef!: NgbModalRef;
  gridApi: GridApi = <GridApi>{};
  subscribes: Subscription[] = [];

  constructor(private modalService: NgbModal) {}

  refresh(_params: ICellRendererParams): boolean {
    this.params = _params;
    return true;
  }

  agInit(params: ICellRendererParams) {
    this.params = params.data;
    this.comp = params.context?.comp;
  }

  edit(): void {
    // open Cancel Policy modal
    this.comp?.openCancelPolicy(this.params);
  }
}
