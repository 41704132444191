import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/shared/models/app/AppRoutes';
import { AuthService } from '../../services/auth/auth.service';


/**
 * Intercepts and handles API errors / error related HTTP status codes 
 *
 * @export
 * @class ErrorInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


   constructor(
      private router: Router,
      private authService: AuthService
   ) { }


   intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
      return handler.handle(request).pipe(
         catchError(err => this.handleErrors(err))
      );
   }


   handleErrors(error: HttpErrorResponse) {

      switch (error.status) {

         /**
          * DNS/Certificate/Hosting/Server Errors
          */
         case 0:
            this.router.navigate([AppRoutes.App.Error.full]);
         break;

         
         /**
          * Server Error
          */
         case 500:
            this.router.navigate([AppRoutes.App.Error.full]);
         break;


         /**
          * Unauthorized 
          */
         case 401:
            this.authService.logout().then(() => this.router.navigate([AppRoutes.Authentication.login.full]));
         break;


         /**
          * Other
          */
         default: break;

      }

      return throwError(error);
   }

}
