import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { NavService, Menu } from "../../services/nav.service";
import { UserRoles } from "../../models/app/UserRoles";
import { LanguageService } from "../../services/app/language.service";
import { TenantService } from "../../services/app/tenant.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  //#region

  state = {};

  uiState = {
    appLang: "",
    userRole: "",
  };

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  //#endregion

  constructor(
    private router: Router,
    public navServices: NavService,
    private authService: AuthService,
    private langService: LanguageService,
    private tenantService: TenantService
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                               Initialization                               */
  /* -------------------------------------------------------------------------- */
  //#region

  ngOnInit(): void {
    this.initSidebarItems();
    this.uiState.appLang = this.langService.getAppLang().value;
  }

  removeFromSidebarItems(originalItems, removedItems) {
    return originalItems.filter((value) => !removedItems.includes(value.title));
  }

  initSidebarItems(): void {
    // Get sidebar items
    let sidebarItems = this.navServices.items.value;

    // Get the authenticated user role
    const userRole = this.authService.getUserRole();
    this.uiState.userRole = userRole;

    // Get current tenant
    const tenant = this.tenantService.getCurrentTenant().value;
    let removeItem = [];
    switch (userRole) {
      case UserRoles.User:
        // Remove this item from  user role
        removeItem = [
          "USERS",
          "REPORTS",
          "INSURANCE ACCOUNTS",
          "SMS",
          "CANCELLED POLICIES",
        ];
        const userItems = this.removeFromSidebarItems(sidebarItems, removeItem);
        sidebarItems = userItems;
        break;

      case UserRoles.Accountant:
        // Remove this item from  Accountant role
        removeItem = [
          "USERS",
          "REPORTS",
          "INSURANCE ACCOUNTS",
          "SMS",
          "CANCELLED POLICIES",
        ];
        const accountantItems = this.removeFromSidebarItems(
          sidebarItems,
          removeItem
        );
        sidebarItems = accountantItems;
        break;
      case UserRoles.CustomerServices:
        // Remove this item from  CustomerServices role
        removeItem = [
          "USERS",
          "REPORTS",
          "DRAFT POLICIES",
          "QUOTATIONS",
          "CLIENTS",
          "DASHBOARD",
          "SMS",
          "CANCELLATION",
          "CANCELLED POLICIES",
        ];
        const customerServicesItems = this.removeFromSidebarItems(
          sidebarItems,
          removeItem
        );
        sidebarItems = customerServicesItems;
        break;
      case UserRoles.Supervisor:
        // Remove this item from  Supervisor role
        removeItem = ["USERS"];
        const supervisorItems = this.removeFromSidebarItems(
          sidebarItems,
          removeItem
        );
        sidebarItems = supervisorItems;
        break;
    }

    //  switch (tenant) {
    //    case "taajeer":
    //      // Remove the users menu
    //      sidebarItems = sidebarItems.filter((item) => item.title !== "REPORTS");
    //      break;

    //    case "aljabr":
    //      // Remove the users menu
    //      sidebarItems = sidebarItems.filter((item) => item.title !== "REPORTS");
    //      break;
    //  }

    // Set the menu items
    this.menuItems = sidebarItems;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems.filter((items) => {
          if (items.path === event.url) this.setNavActive(items);
          if (!items.children) return false;
          items.children.filter((subItems) => {
            if (subItems.path === event.url) this.setNavActive(subItems);
            if (!subItems.children) return false;
            subItems.children.filter((subSubItems) => {
              if (subSubItems.path === event.url)
                this.setNavActive(subSubItems);
            });
          });
        });
      }
    });
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                               User Interface                               */
  /* -------------------------------------------------------------------------- */
  //#region

  onThemeChange(e) {
    if (e) {
      var body = document.body;
      body.classList.add("dark-theme");
    } else {
      var body = document.body;
      body.classList.remove("dark-theme");
    }
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                Miscellaneous                               */
  /* -------------------------------------------------------------------------- */
  //#region

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };
  }

  //#endregion
}
