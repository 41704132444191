import { Injectable, HostListener, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber, from } from "rxjs";
import { AppRoutes } from "../models/app/AppRoutes";
import { log } from "console";

export interface Menu {
  path?: string;
  title?: string;
  titleAr?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnInit {
  public screenWidth: any = window.innerWidth;
  public isOpenMobile: boolean = false;
  public collapseSidebar: boolean = true;
  public isNotificationBarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {}
  ngOnInit(): void {}
  MENUITEMS: Menu[] = [
    {
      title: "DASHBOARD",
      titleAr: "الرئيسية",
      icon: "tachometer-alt",
      type: "link",
      path: "/home",
      active: false,
    },
    {
      title: "CLIENTS",
      titleAr: "العملاء",
      icon: "user-shield",
      type: "link",
      path: "/clients",
      active: false,
    },
    {
      title: "QUOTATIONS",
      titleAr: "عروض الأسعار",
      icon: "funnel-dollar",
      type: "link",
      path: "/quotations",
      active: false,
    },
    {
      title: "DRAFT POLICIES",
      titleAr: "الوثائق المسودة",
      icon: "file-alt",
      type: "link",
      path: "draft-policies",
      active: false,
    },
    {
      title: "POLICIES",
      titleAr: "وثائق التأمين",
      icon: "file-invoice-dollar",
      type: "link",
      path: "/policies",
      active: false,
    },
    {
      title: "INSURANCE ACCOUNTS",
      titleAr: "حسابات التأمين",
      icon: "dollar-sign",
      type: "link",
      path: "/insurance-accounts",
      active: false,
    },
    {
      title: "PENDING CANCELLATION",
      titleAr: "وثائق قيد الإلغاء",
      icon: "file-signature",
      type: "link",
      path: AppRoutes.PendingCancellationPolicies.details.pending,
      active: false,
    },
    {
      title: "CANCELLED POLICIES",
      titleAr: "الوثائق الملغية",
      icon: "file-excel",
      type: "link",
      path: AppRoutes.CancelledPolicies.full,
      active: false,
    },
    {
      title: "SMS",
      titleAr: "ارشيف الرسائل",
      type: "sub",
      icon: "chart-bar",
      active: false,
      children: [
        // {
        //    path: "/analytics/logger/api",
        //    title: "API Logger",
        //    type: "link",
        // },
        {
          path: "/analytics/logger/sms",
          title: "SMS Logger",
          titleAr: "مسجل الرسائل القصيرة",
          type: "link",
        },
      ],
    },
    {
      title: "USERS",
      titleAr: "المستخدمين",
      icon: "id-badge",
      type: "link",
      path: "/users",
      active: false,
    },

    // charts tab
    /*{
         title: "REPORTS",
         titleAr: "التقارير",
         icon: "chart-bar",
         type: "sub",
         active: false,
         children: [
            {
               path: "reports/policies",
               title: "Policies Report",
               type: "link",
               titleAr: "تقرير الوثائق",
               icon: "chart-bar",
            },
            // {
            //    path: "reports/finance",
            //    title: "Finance Report",
            //    type: "link",
            //    titleAr: "التقرير المالي",
            //    icon: "chart-bar",

            // },
            {
               path: "reports/yaqeen",
               title: "Yaqeen Report",
               type: "link",
               titleAr: "تقرير يقين",
               icon: "chart-bar",

            },
         ]
      },*/
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
