import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faTimesCircle,
  faGlobe,
  faInfoCircle,
  faKey,
  faLock,
  faSignInAlt,
  faUser,
  faUserCheck,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

@NgModule({
  declarations: [],

  imports: [CommonModule, FontAwesomeModule],

  exports: [FontAwesomeModule],
})
export class AuthenticationIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUser,
      faKey,
      faGlobe,
      faInfoCircle,
      faSignInAlt,
      faUserCheck,
      faLock,
      faTimesCircle,
      faArrowAltCircleLeft
    );
  }
}
