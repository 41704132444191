import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import AppUtils from "../../helpers/utilities/AppUtils";
import { ApiRoutes } from "../../models/app/ApiRoutes";

@Injectable({
  providedIn: "root",
})
export class TenantService {
  private apiUrl = environment.apiUrl;
  currentTenant: BehaviorSubject<Tenant> = new BehaviorSubject(undefined);

  constructor(private http: HttpClient) {}

  getAllTenants(): Observable<any> {
    return this.http.get(`${this.apiUrl}${ApiRoutes.Auth.Login}`);
  }

  getTenantFromHost(): Tenant {
    // Get tenant from subdomain
    var host = location.hostname.split(".")[0];

    for (const e in Tenant) {
      // Find tenant from tenants list
      if (e.toLowerCase() === host.toLowerCase()) return Tenant[e] as Tenant;
    }

    // Return Almamoon by default
    return Tenant.Almamoon;
  }

  getTenantId(tenant: Tenant): number {
    return AppUtils.strToNum(TenantID[AppUtils.capitalize(tenant)]) ?? 0;
  }

  getCurrentTenantId(): number {
    return this.getTenantId(this.getTenantFromHost());
  }

  initTenantTheme(): void {
    var docStyle = document.documentElement.style;

    switch (this.getTenantFromHost()) {
      case Tenant.Almamoon:
        docStyle.setProperty("--tenant-primary-col", "#0087C1");
        docStyle.setProperty("--tenant-primary-light", "#0087C1");
        docStyle.setProperty("--tenant-primary-tint", "#0087C1");
        docStyle.setProperty("--tenant-primary-tint-light", "#0087C1");
        docStyle.setProperty("--tenant-primary-tint-dark", "#006b99");
        docStyle.setProperty("--tenant-primary-dark", "#006b99");
        break;
      //  case Tenant.Taajeer:
      //    docStyle.setProperty("--tenant-primary-col", "#2a58ad");
      //    docStyle.setProperty("--tenant-primary-light", "#2b91ff");
      //    docStyle.setProperty("--tenant-primary-tint", "#2a58ad");
      //    docStyle.setProperty("--tenant-primary-tint-light", "#346dd6");
      //    docStyle.setProperty("--tenant-primary-tint-dark", "#264f9b");
      //    docStyle.setProperty("--tenant-primary-dark", "#016ada");
      //    break;
      //  case Tenant.Aljabr:
      //    docStyle.setProperty("--tenant-primary-col", "#232c65");
      //    docStyle.setProperty("--tenant-primary-light", "#38448b");
      //    docStyle.setProperty("--tenant-primary-tint", "#313863");
      //    docStyle.setProperty("--tenant-primary-tint-light", "#414981");
      //    docStyle.setProperty("--tenant-primary-tint-dark", "#2a2f4b");
      //    docStyle.setProperty("--tenant-primary-dark", "#1f2658");
      //    break;
      //  case Tenant.Tamweely:
      //    docStyle.setProperty("--tenant-primary-col", "#2a58ad");
      //    docStyle.setProperty("--tenant-primary-light", "#2b91ff");
      //    docStyle.setProperty("--tenant-primary-tint", "#2a58ad");
      //    docStyle.setProperty("--tenant-primary-tint-light", "#346dd6");
      //    docStyle.setProperty("--tenant-primary-tint-dark", "#264f9b");
      //    docStyle.setProperty("--tenant-primary-dark", "#016ada");
      //    break;
      //  case Tenant.Raya:
      //    docStyle.setProperty("--tenant-primary-col", "#1947ba");
      //    docStyle.setProperty("--tenant-primary-light", "#2b91ff");
      //    docStyle.setProperty("--tenant-primary-tint", "#1947ba");
      //    docStyle.setProperty("--tenant-primary-tint-light", "#346dd6");
      //    docStyle.setProperty("--tenant-primary-tint-dark", "#2a2f4b");
      //    docStyle.setProperty("--tenant-primary-dark", "#1844b4");
      //    break;
      //  case Tenant.Etiman:
      //    docStyle.setProperty("--tenant-primary-col", "#737373");
      //    docStyle.setProperty("--tenant-primary-light", "#999999");
      //    docStyle.setProperty("--tenant-primary-tint", "#737373");
      //    docStyle.setProperty("--tenant-primary-tint-light", "#8c8c8c");
      //    docStyle.setProperty("--tenant-primary-tint-dark", "#595959");
      //    docStyle.setProperty("--tenant-primary-dark", "#4d4d4d");
      //    break;
      default:
        break; // Default is set in variables.scss
    }
  }

  getTenantLogoFileName(): string {
    var tenant = this.getTenantFromHost();

    switch (tenant) {
      case Tenant.Almamoon:
        return "almamoon.png";
      // case Tenant.Taajeer:
      //   return "taajeer.png";
      // case Tenant.Aljabr:
      //   return "aljabr.png";
      // case Tenant.Tamweely:
      //   return "tamweely.jpeg";
      // case Tenant.Raya:
      //   return "raya.png";
      // case Tenant.Etiman:
      //   return "etiman.png";
    }
  }

  setCurrentTenant(tenant: Tenant): void {
    this.currentTenant.next(tenant);
  }

  getCurrentTenant(): BehaviorSubject<Tenant> {
    return this.currentTenant;
  }
}

export enum Tenant {
  Almamoon = "Almamoon",
  //   Taajeer = "taajeer",
  //   Aljabr = "aljabr",
  //   Tamweely = "tamweely",
  //   Raya = "Raya",
  //   Etiman = "Etiman",
}

export enum TenantID {
  Almamoon = 0,
  //   Taajeer = 1,
  //   Aljabr = 2,
  //   Tamweely = 3,
  //   Raya = 4,
  //   Etiman = 5,
}
