import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComparisonSheetComponent } from './pages/comparison-sheet/comparison-sheet.component';
import { TranslationModule } from "./translation.module";


@NgModule({

   declarations: [
      ComparisonSheetComponent
   ],

   imports: [
      CommonModule,
      TranslationModule
   ],

   exports: [
      ComparisonSheetComponent
   ],

})


export class PrintablePagesModule { }
