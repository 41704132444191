import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";
import { GridColumn } from "./GridColumn";
import AppUtils from "../../helpers/utilities/AppUtils";
import { ColDef } from "ag-grid-community";
import { EditCancelledBtnComponent } from "src/app/cancelled-policies/cancelled-policies-list/edit-cancelled-btn/edit-cancelled-btn.component";

/* -------------------------------------------------------------------------- */
/*                               Default Columns                              */
/* -------------------------------------------------------------------------- */
//#region

export const CancelledPoliciesColumnsDefsEn: GridColumn[] & ColDef[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "Identity Number",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeEn(e),
    field: "identityNumber",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "Lessee Name",
    field: "clientName",
    minWidth: 220,
  },
  //   {
  //     headerName: "Date of Birth",
  //     cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeEn(e),
  //     field: "birthDate",
  //     minWidth: 140,
  //     sortable: false,
  //     filter: false,
  //   },
  {
    headerName: "Quotation Reference",
    field: "quotationRequestRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 155,
  },
  {
    headerName: "Policy No.",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 170,
  },
  //   {
  //     headerName: "Policy Reference",
  //     field: "policyMotorRefId",
  //     cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  //     sortable: false,
  //     filter: false,
  //     minWidth: 140,
  //   },

  {
    headerName: "Insurance Company",
    field: "insuranceCompanyName",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 170,
  },
  {
    headerName: "Policy Effective Date",
    field: "policyEffectiveDate",
    cellRenderer: DateRenderer.dateFormatterEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Policy Expiry Date",
    field: "policyExpiryDate",
    cellRenderer: DateRenderer.dateFormatterEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  //   {
  //     headerName: "Vehicle Type",
  //     field: "vehicleIdTypeName",
  //     sortable: false,
  //     filter: false,
  //     minWidth: 200,
  //   },
  {
    headerName: "Vehicle ID",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "NCD amount",
    field: "totalDiscounts ",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 170,
  },
  {
    headerName: "Total Premium Before Discounts",
    field: "totalPremiumBeforeDiscounts",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Total Premium After Discounts",
    field: "totalPremiumAfterDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "Refunded Amount",
    field: "refundedAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "Cancellation Refund Net Premium",
    field: "cancelationRefundNetPremium",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "Cancellation Refund VAT",
    field: "cancelationRefundVAT",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "Cancellation Refund Total Premium",
    field: "cancelationRefundTotalPremium",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "Cancellation Reason",
    field: "cancellationReason",
    minWidth: 220,
  },
  {
    headerName: "IC Cancelled On",
    field: "icCancelationDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    //  cellRenderer: DateRenderer.specificDateFormatterEn,
  },
  {
    headerName: "Cancelled On",
    field: "canceledDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    //  cellRenderer: DateRenderer.specificDateFormatterEn,
  },
  {
    headerName: "Cancelled By",
    field: "canceledBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
  {
    headerName: "Edit",
    colId: "Edit",
    cellRendererFramework: EditCancelledBtnComponent,
    sortable: false,
    filter: false,
    minWidth: 80,
    resizable: true,
    hide: false,
    cellClass: "pointer-cursor",
  },
];

export const CancelledPoliciesColumnsDefsAr: GridColumn[] & ColDef[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "رقم الهوية",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeAr(e),
    field: "identityNumber",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "أسم المستأجر",
    field: "clientNameAr",
    minWidth: 220,
  },
  //   {
  //     headerName: "تاريخ الميلاد",
  //     cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeAr(e),
  //     field: "birthDate",
  //     minWidth: 140,
  //     sortable: false,
  //     filter: false,
  //   },
  {
    headerName: "الرقم المرجعي للتسعيرة",
    field: "quotationRequestRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "رقم الوثيقة",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "شركة التأمين",
    field: "insuranceCompanyNameAr",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 220,
  },
  {
    headerName: "تاريخ إصدار الوثيقة",
    field: "policyIssueDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "تاريخ بداية الوثيقة",
    field: "policyEffectiveDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "تاريخ نهاية الوثيقة",
    field: "policyExpiryDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "الرقم التسلسلي",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "القيمة المدفوعة",
    field: "totalPaidAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "قسط شركة التأمين",
    field: "insuranceCaompanyPaidAmount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "المبلغ المسترد",
    field: "refundedAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "صافي قسط التأمين",
    field: "cancelationRefundNetPremium",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "ضريبة القيمة المضافة",
    field: "cancelationRefundVAT",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "إجمالي الأقساط",
    field: "cancelationRefundTotalPremium",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "سبب إلغاء الوثيقة",
    field: "cancellationReason",
    minWidth: 220,
  },
  {
    headerName: "تاريخ إلغاء الوثيقة من شركة التأمين",
    field: "icCancelationDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    //  cellRenderer: DateRenderer.specificDateFormatterAr,
  },
  {
    headerName: "تاريخ الإلغاء",
    field: "canceledDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    //  cellRenderer: DateRenderer.specificDateFormatterAr,
  },
  {
    headerName: "ألغية بواسطة",
    field: "canceledBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
  {
    headerName: "تعديل",
    colId: "Edit",
    cellRendererFramework: EditCancelledBtnComponent,
    sortable: false,
    filter: false,
    minWidth: 80,
    resizable: true,
    hide: false,
    cellClass: "pointer-cursor",
  },
];

//#endregion

/* -------------------------------------------------------------------------- */
/*                           Concord Default Columns                          */
/* -------------------------------------------------------------------------- */
//#region

export const ConcordCancelledPoliciesColsEn: GridColumn[] = AppUtils.injectAt(
  CancelledPoliciesColumnsDefsEn.length - 2,
  CancelledPoliciesColumnsDefsEn.concat(),
  [
    {
      headerName: "Lessor",
      field: "financialEntityName",
      minWidth: 170,
    },
  ]
);

export const ConcordCancelledPoliciesColsAr: GridColumn[] = AppUtils.injectAt(
  CancelledPoliciesColumnsDefsAr.length - 2,
  CancelledPoliciesColumnsDefsAr.concat(),
  [
    {
      headerName: "المؤجر",
      field: "financialEntityNameAr",
      minWidth: 170,
    },
  ]
);

//#endregion
