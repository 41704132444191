import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faTrashAlt,
  faSearch,
  faThList,
  faTh,
  faSave,
  faFileDownload,
  faPen,
  faFileExport,
  faEnvelope,
  faPhoneAlt,
  faUser,
  faBell,
  faCar,
  faCopy,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMedical,
  faBuilding,
  faTimes,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faEdit } from "@fortawesome/free-regular-svg-icons";

@NgModule({
  declarations: [],

  imports: [CommonModule, FontAwesomeModule],

  exports: [FontAwesomeModule],
})
export class AnalyticsIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faArrowLeft,
      faCalendarAlt,
      faEdit,
      faPlus,
      faTrashAlt,
      faSearch,
      faThList,
      faTh,
      faSave,
      faFileDownload,
      faPen,
      faFileExport,
      faPhoneAlt,
      faEnvelope,
      faUser,
      faBell,
      faCar,
      faCopy,
      faFileInvoice,
      faFileInvoiceDollar,
      faFileMedical,
      faBuilding,
      faTimes,
      faDollarSign
    );
  }
}
