<!-- ----------------------------------------------------------------------- -->
<!--                               Brand/Logo                                -->
<!-- ----------------------------------------------------------------------- -->
<div *ngIf="uiState.userRole != 'Customer Services'" class="brand-container" routerLink="/home">
  <a href="javascript:void(0)"><img src="../../../../assets/images/favicon.ico" style="border-radius: 50%" /></a>
</div>
<div *ngIf="uiState.userRole == 'Customer Services'" class="brand-container" routerLink="/policies">
  <a href="javascript:void(0)"><img src="../../../../assets/images/favicon.ico" style="border-radius: 50%" /></a>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                              Sidebar Link                               -->
<!-- ----------------------------------------------------------------------- -->
<div class="sidebar custom-scrollbar">
  <ul class="sidebar-menu">
    <!-- ----------------------------------------------------------------------- -->
    <!--                                  Links                                  -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- <li *ngFor="let menuItem of menuItems" [ngClass]="{ active: menuItem.active }"> -->
    <li *ngFor="let menuItem of menuItems">
      <!-- Sub -->
      <a *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)" href="javascript:void(0)"
        class="sidebar-header">
        <fa-icon [icon]="menuItem.icon"></fa-icon>
        <span>
          {{ uiState.appLang === "ar" ? menuItem.titleAr : menuItem.title }}
          <span *ngIf="menuItem.badgeType" class="badge badge-{{ menuItem.badgeType }} ml-3">
            {{ menuItem.badgeValue }}
          </span>
        </span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>

      <!-- Link -->
      <a *ngIf="menuItem.type === 'link'" [routerLink]="!menuItem.type ? null : [menuItem.path]"
        routerLinkActive="active" class="sidebar-header">
        <fa-icon [icon]="menuItem.icon"></fa-icon>
        <span>
          {{ uiState.appLang === "ar" ? menuItem.titleAr : menuItem.title }}
          <span class="badge badge-{{ menuItem.badgeType }} ml-3" *ngIf="menuItem.badgeType">
            {{ menuItem.badgeValue }}
          </span>
        </span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>

      <!-- External Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header" *ngIf="menuItem.type === 'extLink'">
        <fa-icon [icon]="menuItem.icon"></fa-icon>
        <span>{{ uiState.appLang === "ar" ? menuItem.titleAr : menuItem.title
          }}<span class="badge badge-{{ menuItem.badgeType }} ml-3" *ngIf="menuItem.badgeType">{{ menuItem.badgeValue
            }}</span></span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>

      <!-- External Tab Link -->
      <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header"
        *ngIf="menuItem.type === 'extTabLink'">
        <fa-icon [icon]="menuItem.icon"></fa-icon>
        <span>
          {{ uiState.appLang === "ar" ? menuItem.titleAr : menuItem.title }}
          <span class="badge badge-{{ menuItem.badgeType }} ml-3" *ngIf="menuItem.badgeType">
            {{ menuItem.badgeValue }}
          </span>
        </span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>

      <!-- 2nd Level Menu -->
      <ul class="sidebar-submenu" [ngClass]="{
          'menu-open': menuItem.active,
          'menu-close': !menuItem.active
        }" *ngIf="menuItem.children">
        <li *ngFor="let childrenItem of menuItem.children" [ngClass]="{ active: childrenItem.active }">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" (click)="toggletNavActive(childrenItem)">
            <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>
            {{
            uiState.appLang === "ar"
            ? childrenItem.titleAr
            : childrenItem.title
            }}
            <span class="badge badge-{{ childrenItem.badgeType }} pull-right" *ngIf="childrenItem.badgeType">
              {{ childrenItem.badgeValue }}
            </span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>

          <!-- Link -->
          <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            {{
            uiState.appLang === "ar"
            ? childrenItem.titleAr
            : childrenItem.title
            }}
            <span class="badge badge-{{ childrenItem.badgeType }} pull-right" *ngIf="childrenItem.badgeType">{{
              childrenItem.badgeValue }}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>

          <!-- External Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" *ngIf="childrenItem.type === 'extLink'"
            routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
            <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>{{
            uiState.appLang === "ar"
            ? childrenItem.titleAr
            : childrenItem.title
            }}
            <span class="badge badge-{{ childrenItem.badgeType }} pull-right" *ngIf="childrenItem.badgeType">{{
              childrenItem.badgeValue }}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>

          <!-- External Tab Link -->
          <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
            *ngIf="childrenItem.type === 'extTabLink'">
            <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>{{
            uiState.appLang === "ar"
            ? childrenItem.titleAr
            : childrenItem.title
            }}
            <span class="badge badge-{{ childrenItem.badgeType }} pull-right" *ngIf="childrenItem.badgeType">{{
              childrenItem.badgeValue }}</span>
            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
          </a>

          <!-- 3rd Level Menu -->
          <ul class="sidebar-submenu" *ngIf="childrenItem.children">
            <li *ngFor="let childrenSubItem of childrenItem.children">
              <!-- Link -->
              <a [routerLink]="
                  !childrenSubItem.type ? null : [childrenSubItem.path]
                " *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>{{
                uiState.appLang === "ar"
                ? childrenSubItem.titleAr
                : childrenSubItem.title
                }}
                <span class="badge badge-{{ childrenSubItem.badgeType }} pull-right"
                  *ngIf="childrenSubItem.badgeType">{{ childrenSubItem.badgeValue }}</span>
              </a>

              <!-- External Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>{{
                uiState.appLang === "ar"
                ? childrenSubItem.titleAr
                : childrenSubItem.title
                }}
                <span class="badge badge-{{ childrenSubItem.badgeType }} pull-right"
                  *ngIf="childrenSubItem.badgeType">{{ childrenSubItem.badgeValue }}</span>
              </a>

              <!-- External Tab Link -->
              <a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                *ngIf="childrenSubItem.type === 'extTabLink'">
                <fa-icon icon="chevron-right" size="xs" style="margin-right: 8px"></fa-icon>
                {{
                uiState.appLang === "ar"
                ? childrenSubItem.titleAr
                : childrenSubItem.title
                }}
                <span class="badge badge-{{ childrenSubItem.badgeType }} pull-right"
                  *ngIf="childrenSubItem.badgeType">{{ childrenSubItem.badgeValue }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>