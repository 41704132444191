import { BrowserModule } from "@angular/platform-browser";
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AnalyticsModule } from "./analytics/analytics.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { AgGridModule } from "ag-grid-angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { JwtInterceptor } from "./shared/helpers/interceptors/JwtInterceptor";
import { ErrorInterceptor } from "./shared/helpers/interceptors/ErrorInterceptor";
import { TenantInterceptor } from "./shared/helpers/interceptors/TenantInterceptor";
import { LanguageInterceptor } from "./shared/helpers/interceptors/LanguageInterceptor";
import { SecureAppInterceptor } from "./shared/helpers/interceptors/secureAppInterceptor";
import {
  HttpCacheInterceptorModule,
  useHttpCacheLocalStorage,
} from "@ngneat/cashew";
import { InsuranceService } from "./shared/services/insurance/insurance.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeAr from "@angular/common/locales/ar";
import * as arContent from "../assets/i18n/ar.json";
import * as enContent from "../assets/i18n/en.json";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { LoadingModule } from "./shared/pages/loading/loading.module";
import { PrintablePagesModule } from "./shared/printable-pages.module";

/* -------------------------------------------------------------------------- */
/*                                 Translation                                */
/* -------------------------------------------------------------------------- */
//#region

registerLocaleData(localeAr, "ar");
registerLocaleData(localeEn, "en");

const TRANSLATIONS = { ar: arContent, en: enContent };

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export class TranslateUniversalLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }
}

//#endregion

/* -------------------------------------------------------------------------- */
/*                                    Misc                                    */
/* -------------------------------------------------------------------------- */
//#region

@NgModule({
  declarations: [AppComponent],

  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AnalyticsModule,
    AuthenticationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AgGridModule,
    PrintablePagesModule,
    HttpCacheInterceptorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LoadingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],

  exports: [],

  providers: [
    useHttpCacheLocalStorage,
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecureAppInterceptor, multi: true },
    {
      provide: ErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    InsuranceService.injector = injector;
  }
}

//#endregion
