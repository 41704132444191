import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
@Injectable()
export class SecureAppInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
         request = request.clone({
            setHeaders: {
                "Content-Security-Policy": "script-src 'self'",
                "X-XSS-Protection": "1; mode=block",
                "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
                "X-Content-Type-Options": "nosniff",
                "X-Frame-Options": "DENY",
                "Set-Cookie": "XSRF-TOKEN=NDKDdfdsfkldsfNd3SZAJfwLsTl5WUgOkE; Path=/; Secure;HttpOnly"
            }
        });
        // Handle the request and move into next interceptors if available        return handler.handle(request);
        return handler.handle(request)
    }
}