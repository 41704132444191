import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";


export const ContactsColumnsDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "Name",
      field: "employerName",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Address",
      field: "employerAddress",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Phone Number",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberEnRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Email",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Notes",
      field: "notes",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
];

export const ContactsColumnsDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "الإسم",
      field: "employerName",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "العنوان",
      field: "employerAddress",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "رقم الهاتف",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "البريد الإلكتروني",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "ملاحظات",
      field: "notes",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
];
