import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Filters } from '../../models/apiLogger/Filters';
import { environment } from 'src/environments/environment.prod';
import { ApiRoutes } from '../../models/app/ApiRoutes';

@Injectable({
  providedIn: "root",
})
export class ApiLoggerService {

  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {}

  getApiLoggerData() {

    return this.http.post(this.apiUrl + ApiRoutes.logger.historylog, {} );
  }

  getApiLoggerSearchByStatusCode(filtersby:Filters) {
  
    return this.http.post(this.apiUrl + ApiRoutes.logger.historylog, filtersby )
  }
}
