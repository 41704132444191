import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { AppLanguage } from "../../models/app/AppLanguage";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  appLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(
    AppLanguage.ENGLISH
  );

  constructor(public translate: TranslateService) {
    this.setupLanguages();
  }

  /* -------------------------------------------------------------------------- */
  /*                               Initialization                               */
  /* -------------------------------------------------------------------------- */
  //#region

  private setupLanguages(): void {
    // Set app languages
    this.translate.addLangs(["en", "ar"]);
    this.translate.setDefaultLang("en");

    // Retreive current language from local storage
    const appLang = localStorage.getItem(LocallyStoredItemsKeys.AppLanguage);

    // Set the app language as the current language
    if (appLang) this.setLanguage(appLang);
    // Set default language if it doesn't exist
    else document.body.setAttribute("dir", "ltr");
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                Miscellaneous                               */
  /* -------------------------------------------------------------------------- */
  //#region

  public setLanguage(language: AppLanguage | string): void {
    switch (language) {
      case AppLanguage.ENGLISH:
        this.setEnLang();
        break;

      case AppLanguage.ARABIC:
        this.setArLang();
        break;

      default:
        this.setEnLang();
        break;
    }
  }

  private setEnLang(): void {
    document.body.setAttribute("dir", "ltr");
    document.title = "Oasis Autolease Dashboard";
    this.translate.use("en");
    this.setAppLang(AppLanguage.ENGLISH);
  }

  private setArLang(): void {
    document.body.setAttribute("dir", "rtl");
    this.translate.use("ar");
    document.title = "منصة أويسيس لتأمين المركبات المؤجرة تمويلياً";
    this.setAppLang(AppLanguage.ARABIC);
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                              Setters & Getters                             */
  /* -------------------------------------------------------------------------- */
  //#region

  public setAppLang(language: AppLanguage | string) {
    this.appLanguage.next(language);

    // Store current language on local storage
    localStorage.setItem(LocallyStoredItemsKeys.AppLanguage, language);
  }

  public getAppLang(): BehaviorSubject<string> {
    return this.appLanguage;
  }

  //#endregion
}
