<div class="notifications-bar-container" [ngClass]="{'closed': (!uiState.isBarVisible), 'open': (uiState.isBarVisible)}">

   <div class="header flex-container">
      <a (click)="toggleBar()" href="javascript:void(0)" class="close-btn flex-centered">&times;</a>
   </div>

   <div class="content pb-3">

      <!-- ----------------------------------------------------------------------- -->
      <!--                               App Version                               -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <div class="flex-container">
         <div class="app-version flex-centered flex-column">
            <h2 class="flex-centered">V</h2>
            <h6 class="flex-centered">{{ uiState.appVersion }}</h6>
         </div>
      </div>

      <!-- #endregion -->


      <!-- ----------------------------------------------------------------------- -->
      <!--                              Updates List                               -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <div class="updates-list mt-4">
         <app-updates-list></app-updates-list>
      </div>

      <!-- #endregion -->


      <!-- ----------------------------------------------------------------------- -->
      <!--                                Settings                                 -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <div class="settings mt-4">

         <h6 class="title mb-2">{{ 'MISC.Settings' | translate }}</h6>

         <!-- ----------------------------------------------------------------------- -->
         <!--                            Dark Theme Switch                            -->
         <!-- ----------------------------------------------------------------------- -->
         <!-- #region -->

         <div class="flex-container theme-switch">
            <span class="flex-centered" id="title">{{ 'MISC.Themes.Dark' | translate }}</span>
            <label class="switch small flex-centered" id="toggler">
               <input type="checkbox" [checked]="uiState.darkTheme.isActivated" (change)="onDarkThemeToggle()" class="small">
               <span class="slider round small"></span>
            </label>
         </div>
 
         <!-- #endregion -->


         <!-- ----------------------------------------------------------------------- -->
         <!--                         Internet Speed Checker                          -->
         <!-- ----------------------------------------------------------------------- -->
         <!-- #region -->

         <div class="flex-container theme-switch mt-2">
            <span class="flex-centered" id="title">
               {{ 'MISC.ConnectionSpeedChecks' | translate }}
               <fa-icon icon="question-circle" placement="top" [ngbTooltip]="('MESSAGES.ConnecionChecksDescription' | translate)"></fa-icon>
            </span>
            <label class="switch small flex-centered" id="toggler">
               <input type="checkbox" [checked]="uiState.connectionChecks.isActivated" (change)="onConnectionChecksToggle()" class="small">
               <span class="slider round small"></span>
            </label>
         </div>

         <!-- #enderegion -->


      </div>

      <!-- #endregion -->

   </div>

</div>
