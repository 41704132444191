import { Injectable } from "@angular/core";
import { ColDef } from "ag-grid-community";
import zipcelx, {
  ZipCelXCell,
  ZipCelXConfig,
  ZipCelXDataSet,
  ZipCelXRow,
} from "zipcelx";
import { ExportedBatchDataTypes } from "../../models/app/ExportedBatchDataTypes";
import { LanguageService } from "./language.service";

@Injectable({
  providedIn: "root",
})
export class SpreadsheetService {
  constructor(private langService: LanguageService) {}

  exportBatchData(
    type: ExportedBatchDataTypes,
    data: any[],
    columnsDefs: ColDef[]
  ): void {
    let dataSet: ZipCelXDataSet = [];
    let headers: ZipCelXRow = columnsDefs.map(
      (c) => ({ value: c.headerName, type: "string" } as ZipCelXCell)
    );
    headers.shift(); // Remove the '#' column header

    switch (type) {
      case ExportedBatchDataTypes.Clients:
        dataSet = this.constructClientsDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.Quotations:
        dataSet = this.constructQuotationsDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.DraftPolicies:
        dataSet = this.constructDraftsDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.Policies:
        dataSet = this.constructPoliciesDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.ShadowAccounts:
        dataSet = this.constructShadowAccountDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.CancelledPolicies:
        dataSet = this.constructCancelledPoliciesDataset(data, columnsDefs);
        break;
      case ExportedBatchDataTypes.SmsLogger:
        dataSet = this.constructSMSDataset(data, columnsDefs);
        break;
    }

    const config: ZipCelXConfig = {
      filename: ExportedBatchDataTypes[type],
      sheet: { data: [headers] },
    };

    config.sheet.data = config.sheet.data.concat(dataSet);

    zipcelx(config);
  }

  constructQuotationsDataset(
    data: any[],
    columnsDefs: ColDef[]
  ): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry: any) => {
      let dataRow: ZipCelXRow = [];

      TableColumns: for (let c of columnsDefs) {
        DataEntries: for (let key of Object.keys(entry)) {
          if (c.field === key) {
            // For null or blank values
            if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          } else if (c.field === "fullNameAr" || c.field === "fullName") {
            // Construct full name
            let fullName = `${entry.insuredFirstName} ${entry.insuredMiddleName} ${entry.insuredLastName}`;
            let fullNameAr = `${entry.insuredFirstNameAr} ${entry.insuredMiddleNameAr} ${entry.insuredLastNameAr}`;

            dataRow.push({
              value: isAr ? fullNameAr : fullName,
              type: "string",
            });
            continue TableColumns;
          } else if (c.field === "isRenewal") {
            // return Renewal or New Policy in the sheet
            if (entry.isRenewal == true) {
              dataRow.push({
                value: isAr ? "تجديد" : "Renewal",
                type: "string",
              });
            } else {
              dataRow.push({
                value: isAr ? "وثيقة جديدة" : "New Policy",
                type: "string",
              });
            }
            continue TableColumns;
          }
        }
      }

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructPoliciesDataset(data: any[], columnsDefs: ColDef[]): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry) => {
      let dataRow: ZipCelXRow = [];

      // Add the rest of the cells
      columnsDefs.forEach((c) => {
        Object.keys(entry).forEach((key) => {
          if (c.field === key) {
            // Add Najm Status value (which requires logic)
            if (key === "isNajmUpdated")
              dataRow.push({
                value: entry[key]
                  ? isAr
                    ? "تم التحديث"
                    : "Updated"
                  : isAr
                  ? "قيد الإنتظار"
                  : "Pending",
                type: "string",
              });
            else if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          }
        });
      });

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructCancelledPoliciesDataset(
    data: any[],
    columnsDefs: ColDef[]
  ): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry) => {
      let dataRow: ZipCelXRow = [];

      // Add the rest of the cells
      columnsDefs.forEach((c) => {
        Object.keys(entry).forEach((key) => {
          if (c.field === key) {
            // Add Najm Status value (which requires logic)
            if (key === "isNajmUpdated")
              dataRow.push({
                value: entry[key]
                  ? isAr
                    ? "تم التحديث"
                    : "Updated"
                  : isAr
                  ? "قيد الإنتظار"
                  : "Pending",
                type: "string",
              });
            else if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          }
        });
      });

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructDraftsDataset(data: any[], columnsDefs: ColDef[]): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry: any) => {
      let dataRow: ZipCelXRow = [];

      TableColumns: for (let c of columnsDefs) {
        DataEntries: for (let key of Object.keys(entry)) {
          if (c.field === key) {
            // For null or blank values
            if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          } else if (
            c.field === "insuredFullNameAr" ||
            c.field === "insuredFullName"
          ) {
            // Construct full name
            let fullName = `${entry.insuredFirstName} ${entry.insuredMiddleName} ${entry.insuredLastName}`;
            let fullNameAr = `${entry.insuredFirstNameAr} ${entry.insuredMiddleNameAr} ${entry.insuredLastNameAr}`;

            dataRow.push({
              value: isAr ? fullNameAr : fullName,
              type: "string",
            });
            continue TableColumns;
          }
        }
      }

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructClientsDataset(data: any[], columnsDefs: ColDef[]): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry: any) => {
      let dataRow: ZipCelXRow = [];

      TableColumns: for (let c of columnsDefs) {
        DataEntries: for (let key of Object.keys(entry)) {
          if (c.field === key) {
            // For null or blank values
            if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Set Identity type
            else if (key === "identityTypeId")
              dataRow.push({
                value:
                  entry[key] == 1
                    ? isAr
                      ? "غير سعودية"
                      : "Non-Saudi"
                    : isAr
                    ? "سعودية"
                    : "Saudi",
                type: "string",
              });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          } else if (c.field === "fullNameAr" || c.field === "fullName") {
            // Construct full name
            let fullName = `${entry.firstName} ${entry.middleName} ${entry.lastName}`;
            let fullNameAr = `${entry.firstNameAr} ${entry.middleNameAr} ${entry.lastNameAr}`;

            dataRow.push({
              value: isAr ? fullNameAr : fullName,
              type: "string",
            });
            continue TableColumns;
          }
        }
      }

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructSMSDataset(data: any[], columnsDefs: ColDef[]): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry: any) => {
      let dataRow: ZipCelXRow = [];

      TableColumns: for (let c of columnsDefs) {
        DataEntries: for (let key of Object.keys(entry)) {
          if (c.field === key) {
            // For null or blank values
            if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          }
        }
      }

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }

  constructShadowAccountDataset(
    data: any[],
    columnsDefs: ColDef[]
  ): ZipCelXDataSet {
    const isAr = this.langService.getAppLang().value === "ar";
    let dataSet: ZipCelXDataSet = [];

    data.forEach((entry: any) => {
      let dataRow: ZipCelXRow = [];

      TableColumns: for (let c of columnsDefs) {
        DataEntries: for (let key of Object.keys(entry)) {
          if (c.field === key) {
            // For null or blank values
            if (!entry[key]) dataRow.push({ value: "-", type: "string" });
            // Add the rest of the values
            else dataRow.push({ value: entry[key], type: "string" });
          } else if (c.field === "clientNameAr" || c.field === "clientNameEn") {
            // Construct full name
            let fullName = entry.clientNameEn;
            let fullNameAr = entry.clientNameAr;

            dataRow.push({
              value: isAr ? fullNameAr : fullName,
              type: "string",
            });
            continue TableColumns;
          }
        }
      }

      // Add the row to the data set
      dataSet.push(dataRow);
    });

    return dataSet;
  }
}
