export const AppRoutes = {
  App: {
    Error: {
      full: "error",
      main: "error",
      sub: "",
    },
  },

  Authentication: {
    full: "authentication",
    main: "authentication",
    sub: "",
    login: {
      full: "login",
      main: "login",
      sub: "",
    },
  },

  home: {
    full: "home",
    main: "home",
    sub: "",
  },

  Clients: {
    full: "clients",
    main: "clients",
    sub: "",
    details: {
      full: "clients/",
      main: ":id",
    },
    new: {
      full: "clients/new",
      main: "new",
      sub: "",
    },
    edit: {
      full: "clients/edit/",
      main: "edit/:id",
      sub: ":id",
    },
  },

  Quotations: {
    full: "quotations",
    main: "quotations",
    sub: "",
    session: {
      full: "quotations/:id",
      main: ":id",
      sub: "",
    },
  },

  Insurance: {
    full: "insurance",
    main: "insurance",
    sub: "",
    form: {
      full: "insurance/form",
      main: "form",
      sub: "",
    },
    quotes: {
      full: "insurance/quotes",
      main: "quotes",
      sub: "",
    },
    draftPolicyIssue: {
      full: "insurance/draft-policy-issue/:id",
      main: "draft-policy-issue/:id",
      sub: ":id",
    },
    policyIssue: {
      full: "insurance/policy-issue/:id",
      main: "policy-issue/:id",
      sub: "policy-issue",
    },
  },

  DraftPolicies: {
    full: "draft-policies",
    main: "draft-policies",
    sub: "",
    details: {
      full: "draft-policies/",
      main: ":id",
      sub: "",
    },
  },

  Policies: {
    full: "policies",
    main: "policies",
    sub: "",
    details: {
      full: "policies/:id",
      main: ":id",
      sub: "",
    },
    DriversRequest: {
      full: "policies/:id/drivers-request",
      main: ":id/drivers-request",
      sub: "",
    },
  },

  PendingCancellationPolicies: {
    full: "policy-requests",
    main: "policy-requests",
    sub: "",
    details: {
      // full: "cancelled-policies/:id",
      pending: "policy-requests/pending",
      cancelled: "policy-requests/cancelled",
      main: ":id",
      sub: "",
    },
  },

  CancelledPolicies: {
    full: "cancelled-policies",
    main: "cancelled-policies",
    sub: "",
    details: {},
  },

  Analytics: {
    full: "analytics/logger",
    main: "analytics",
    sub: "logger",
    apiLogger: {
      full: "analytics/logger/api",
      main: "logger/api",
      sub: "api",
    },
    smsLogger: {
      full: "analytics/logger/sms",
      main: "logger/sms",
      sub: "sms",
    },
  },

  Users: {
    full: "users",
    main: "users",
    sub: "",
    details: {
      full: "users/",
      main: ":id",
    },
  },

  InsuranceAccounts: {
    full: "insurance-accounts",
    main: "insurance-accounts",
    sub: "",
  },

  Reports: {
    full: "reports",
    main: "reports",
    sub: "",
    policies: {
      full: "reports/policies",
      main: "policies",
    },
    finance: {
      full: "reports/finance",
      main: "finance",
    },
    yaqeen: {
      full: "reports/yaqeen",
      main: "yaqeen",
    },
  },
};
