import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { ApiLoggerComponent } from "./api-logger/api-logger.component";
import { AnalyticsRoutingModule } from "./analytics-routing.module";
import { SmsLoggerComponent } from "./sms-logger/sms-logger.component";
import { AgGridModule } from "ag-grid-angular";
import { TranslationModule } from "../shared/translation.module";
import { AnalyticsIconsModule } from "./analytics-icons.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { DaterangepickerModule } from "../shared/components/daterangepicker/daterangepicker.module";

@NgModule({
  declarations: [ApiLoggerComponent, SmsLoggerComponent],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    AgGridModule,
    NgxJsonViewerModule,
    AnalyticsRoutingModule,
    TranslationModule,
    DaterangepickerModule,
    AnalyticsIconsModule,
  ],
})
export class AnalyticsModule {}
