import { Injectable } from '@angular/core';
import { ConfigDB } from '../../shared/config/config';
import { LanguageService } from '../../shared/services/app/language.service';
import { AppLanguage } from '../../shared/models/app/AppLanguage';


@Injectable({
   providedIn: 'root'
})

export class CustomizerService {

   public data = ConfigDB.enData;


   constructor(
      private langService: LanguageService
   ) {
      this.initLayout();
      this.watchLanguageChange();
   }


   private initLayout(): void {

      document.body.className = this.data.color.mix_layout;
      document.body.setAttribute("main-theme-layout", this.data.settings.layout_type);
      document.getElementsByTagName('html')[0].setAttribute('dir', this.data.settings.layout_type);

      var color = this.data.color.color;
      var layoutVersion = this.data.color.layout_version;

      if (color) {
         this.createStyle(color);
         if (layoutVersion) document.body.className = layoutVersion;
      }

   }


   private watchLanguageChange(): void {
      this.langService.getAppLang().subscribe(lang => {
         switch (lang) {
            
            case AppLanguage.ARABIC: 
               this.data = ConfigDB.arData; 
               this.initLayout(); 
            break;
            
            case AppLanguage.ENGLISH: 
               this.data = ConfigDB.enData; 
               this.initLayout(); 
            break;
            
            default: 
               this.data = ConfigDB.enData;
               this.initLayout();  
            break;

         }
      });
   }


   // Create style sheet append in head
   createStyle(color) {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = window.location.origin + "assets/css/" + color + ".css";
      head.appendChild(link);
   }

}
