export enum LocallyStoredItemsKeys {
  /* App */
  JWT = "app:jwt",
  AppLanguage = "app:language",
  ActiveErrorType = "app:errorType",
  AppVersion = "app:version",
  DarkTheme = "app:darkTheme",
  ConnectionChecks = "app:connectionChecks",

  /* Insurance */
  InsuranceCurrentStepNumber = "insurance:currentStepNumber",
  InsuranceStepsData = "insurance:stepsData",
  UserQuoteRequest = "insurance:quoteRequest",
  UserQuoteResponse = "insurance:quoteResponse",

  /* Quotes */
  Quotes = "quote:quotesList",
  SelectedQuote = "quote:selectedQuote",
  AlteredQuotes = "quote:alteredQuotesList",
  AlteredProducts = "quote:alteredQuotesList",
  PreviewQuoteResponse = "quote:previewQuoteResponse",
  PreviewQuoteRequest = "quote:previewQuoteRequest",

  /* User */
  VerificationUserId = "verification:userId",
  VerificationPhoneNumber = "verification:phone",
  VerificationUserEmail = "verification:email",
  ProfileData = "user:profileInfo",

  /* Checkout */
  CheckoutReturnState = "checkout:returnStateData",

  /* Policy */
  AddDriverData = "policy:addDriverData",
}
