import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import AppUtils from "src/app/shared/helpers/utilities/AppUtils";
import {
  policyShareInfo,
  Message,
  policyShareRequest,
} from "src/app/shared/models/policies/policyShare";
import { LanguageService } from "src/app/shared/services/app/language.service";
import { PoliciesService } from "src/app/shared/services/policies/policies.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-policy-share",
  templateUrl: "./policy-share.component.html",
  styleUrls: ["./policy-share.component.scss"],
})
export class PolicyShareComponent implements OnInit {
  @Input() shareInfo: policyShareInfo;

  constructor(
    private languageService: LanguageService,
    private modalService: NgbModal,
    private policiesService: PoliciesService
  ) {}

  vehicleIdType;

  state = {
    termSearchFn: AppUtils.searchItemTerm,
    messages: [
      {
        // message with download link
        id: 1,
        title: "Renewed Policy",
        titleAr: "تجديد التأمين",
        body: null,
        bodyAr: null,
      },
      {
        // visit nearest branch
        id: 2,
        title: "Office Follow Up",
        titleAr: "زيارة فرع",
        body: null,
        bodyAr: null,
      },
    ] as Message[],
    form: {
      email: null,
      phone: null,
      message: {} as Message,
    },
    shareSource: "sms",
  };
  uiState = {
    isAr: this.languageService.getAppLang().value == "ar" ? true : false,
    isSMS: true,
    isEmail: false,
    isLoading: false,
    success: false,
  };

  ngOnInit(): void {
    this.getContants();
  }

  ngOnChanges() {
    this.getContants();
    this.initMessage();
  }

  initMessage() {
    // Construct vehicle id type for Arabic and English
    //    this.vehicleIdType = `${
    //       this.shareInfo.vehicleIdTypeId == 2 && this.uiState.isAr ? `البطاقة الجمركية: ${this.shareInfo.vehicleId}` :
    //       this.shareInfo.vehicleIdTypeId == 2 && !this.uiState.isAr ? `Custom Card: ${this.shareInfo.vehicleId}` :
    //       this.shareInfo.vehicleIdTypeId == 1 && this.uiState.isAr ? `الرقم التسلسلي: ${this.shareInfo.vehicleId}` :
    //       `Sequence Number: ${this.shareInfo.vehicleId}`
    //   }`;

    // Arabic only
    this.vehicleIdType = `${
      this.shareInfo.vehicleIdTypeId == 2
        ? `البطاقة الجمركية: ${this.shareInfo.vehicleId}`
        : `الرقم التسلسلي: ${this.shareInfo.vehicleId}`
    }`;

    // Arabic Messages
    this.state.messages[0].bodyAr = `عزيزنا / عميل دار الإئتمان
      نود إشعاركم بأنه تم تجديد وثيقة التأمين للمركبة (${this.vehicleIdType}) , مرفق لكم وثيقة التأمين بالرابط أدناه `;
    this.state.messages[1].bodyAr = `في حال رغبتكم بإضافة التغطيات الإضافية ( توسعة جغرافية – إضافة السائق الإضافي) لوثيقة التأمين الخاصة بالمركبة (${this.vehicleIdType}) , نرجو مراجعة أقرب فرع لشركة المأمون أو التواصل على الرقم المجاني : 8002442228`;
    // English Messages
    this.state.messages[0].body = `Dear Customer,
       would like to inform you that your insurance policy for the vehicle (${this.vehicleIdType}) has been renewed, the insurance policy is attached below`;
    this.state.messages[1].body = `If you would like to add the additional coverages (Extended Geographical – Additional Driver) to your insurance policy for the vehicle (${this.vehicleIdType}), please visit the nearest branch Al Mamoon company to check the policy, or dial the free number: 8002442228`;
  }

  getContants() {
    this.state.form.phone = this.shareInfo.phoneNumber;
    this.state.form.email = this.shareInfo.email;
  }

  shareSMS() {
    this.uiState.isSMS = true;
    this.uiState.isEmail = false;
    this.state.shareSource = "sms";
  }

  shareEmail() {
    this.uiState.isEmail = true;
    this.uiState.isSMS = false;
    this.state.shareSource = "email";
  }

  setMessage(message: any) {
    this.state.form.message = message;
    this.state.form.message.body = message.bodyAr;
  }

  constructMessageRequest() {
    let request: policyShareRequest = {
      policyId: this.shareInfo.policyId,
      shareSource: this.state.shareSource,
      policyFilePath: this.shareInfo.policyFilePath,
      insuredContact: this.uiState.isEmail
        ? this.state.form.email
        : this.state.form.phone,
      // Add {url} to message body for API use
      message: this.state.form.message.body + " {url}",
    };
    return request;
  }

  sendMessage() {
    this.uiState.isLoading = true;
    this.policiesService
      .sendSMSOrEmail(this.constructMessageRequest())
      .subscribe(
        (res) => {
          this.uiState.isLoading = false;
          this.uiState.success = true;
          this.modalService.dismissAll();
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.uiState.isAr
              ? "تم إسال الرسالة بنجاح"
              : "Message sent successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        },
        (err) => {
          this.uiState.isLoading = false;
          this.uiState.success = false;
          this.modalService.dismissAll();
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.uiState.isAr ? "خطأ" : "Error",
            text: this.uiState.isAr
              ? "حدث خطأ أثناء إرسال الرسالة"
              : "An error occurred while sending the message",
          });
        }
      );
  }
}
