import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Menu, NavService } from "../../../services/nav.service";
import * as feather from "feather-icons";
import { CustomizerService } from "../../../services/customizer.service";
import { LanguageService } from "src/app/shared/services/app/language.service";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { TenantService } from "src/app/shared/services/app/tenant.service";
import { NavigationEnd, Router } from "@angular/router";
import { UserRoles } from "src/app/shared/models/app/UserRoles";

@Component({
  selector: "app-content-layout",
  templateUrl: "./content-layout.component.html",
  styleUrls: ["./content-layout.component.scss"],
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {
  //#region States

  state = {};
  uiState = {
    pdfPrint: {
      isPrinting: true,
    },
    appLang: "",
    userRole: "",
  };
  public menuItems: Menu[];
  //#endregion

  constructor(
    public navServices: NavService,
    public customizer: CustomizerService,
    private langService: LanguageService,
    private authService: AuthService,
    private router: Router,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.initNavItems();
    this.uiState.appLang = this.langService.getAppLang().value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  removeFromNavItems(originalItems, removedItems) {
    return originalItems.filter((value) => !removedItems.includes(value.title));
  }
  initNavItems(): void {
    // Get nav items
    let navItems = this.navServices.items.value;
    // Get the authenticated user role
    const userRole = this.authService.getUserRole();
    this.uiState.userRole = userRole;
    // Get current tenant
    const tenant = this.tenantService.getCurrentTenant().value;
    let removeItem = [];
    switch (userRole) {
      case UserRoles.User:
        // Remove this item from  user role
        removeItem = [
          "USERS",
          "REPORTS",
          "INSURANCE ACCOUNTS",
          "SMS",
          "CANCELLED POLICIES",
        ];
        const userItems = this.removeFromNavItems(navItems, removeItem);
        navItems = userItems;
        break;
      case UserRoles.Accountant:
        // Remove this item from  Accountant role
        removeItem = [
          "USERS",
          "REPORTS",
          "INSURANCE ACCOUNTS",
          "SMS",
          "CANCELLED POLICIES",
        ];
        const accountantItems = this.removeFromNavItems(navItems, removeItem);
        navItems = accountantItems;
        break;
      case UserRoles.CustomerServices:
        // Remove this item from  CustomerServices role
        removeItem = [
          "USERS",
          "REPORTS",
          "DRAFT POLICIES",
          "QUOTATIONS",
          "CLIENTS",
          "DASHBOARD",
          "SMS",
          "CANCELLATION",
          "CANCELLED POLICIES",
        ];
        const customerServicesItems = this.removeFromNavItems(
          navItems,
          removeItem
        );
        navItems = customerServicesItems;
        break;
      case UserRoles.Supervisor:
        // Remove this item from  Supervisor role
        removeItem = ["USERS"];
        const supervisorItems = this.removeFromNavItems(navItems, removeItem);
        navItems = supervisorItems;
        break;
    }
    //  switch (tenant) {
    //    case "taajeer":
    //      // Remove the users menu
    //      navItems = navItems.filter((item) => item.title !== "REPORTS");
    //      break;
    //    case "aljabr":
    //      // Remove the users menu
    //      navItems = navItems.filter((item) => item.title !== "REPORTS");
    //      break;
    //  }

    // Set the menu items
    this.menuItems = navItems;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuItems.filter((items) => {
          if (items.path === event.url) {
            this.setNavActive(items);
          }
          if (!items.children) return false;
          items.children.filter((subItems) => {
            if (subItems.path === event.url) this.setNavActive(subItems);
            if (!subItems.children) return false;
            subItems.children.filter((subSubItems) => {
              if (subSubItems.path === event.url)
                this.setNavActive(subSubItems);
            });
          });
        });
      }
    });
  }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }
}
