import { ClientForm } from "../clients/ClientForm";
import { VehicleForm } from "../vehicle/VehicleForm";
import { ClientFilters } from "../clients/ClientFilters";
import { AdditionalDriverForm } from "../insurance/AdditionalDriverForm";
import { DriverLicense } from "../../payloads/requests/quotation/DriverLicense";
import { InsuranceRequestDetailsForm } from "../insurance/InsuranceRequestDetailsForm";
import { UserForm } from "../users/UserForm";
import { PolicyIssueForm } from "../insurance/PolicyIssueForm";
import { ShadowAccountFilter } from "../Reports/ShadowAccounts";
import { QuotationsFilters } from "../quotation/QuotationsFilters";
import { DraftPoliciesFilters } from "../draft-policies/DraftPoliciesFilters";
import { PoliciesFilters } from "../policies/PoliciesFilters";
import { CancelledPoliciesFilters } from "../cancelled-policies/cancelled-policies-filters";

export enum ManagedForms {
  LoginForm = "LoginForm",
  ClientForm = "ClientForm",
  ClientFiltersForm = "ClientFiltersForm",
  LicenseForm = "LicenseForm",
  InsuredInsuranceForm = "InsuredInsuranceForm",
  VehicleInsuranceForm = "VehicleInsuranceForm",
  InsuranceRequestDetailsForm = "InsuranceRequestDetailsForm",
  AdditionalDriverInsuranceForm = "AdditionalDriverInsuranceForm",
  InsurancePolicyIssueForm = "InsurancePolicyIssueForm",
  UserForm = "UserForm",
  ShadowAccountFilter = "ShadowAccountFilter",
  QuotationsFilters = "QuotationsFilters",
  DraftPoliciesFilters = "DraftPoliciesFilters",
  PoliciesFilters = "PoliciesFilters",
  CancelledPoliciesFilters = "CancelledPoliciesFilters",
  PendingCancellationFilters = "PendingCancellationFilters",
}

export interface ManagedFormsTypes {
  ClientForm: ClientForm;
  ClientFiltersForm: ClientFilters;
  LicenseForm: DriverLicense;
  InsuredInsuranceForm: ClientForm;
  VehicleInsuranceForm: VehicleForm;
  InsuranceRequestDetailsForm: InsuranceRequestDetailsForm;
  AdditionalDriverInsuranceForm: AdditionalDriverForm;
  InsurancePolicyIssueForm: PolicyIssueForm;
  UserForm: UserForm;
  ShadowAccountFilter: ShadowAccountFilter;
  QuotationsFilters: QuotationsFilters;
  DraftPoliciesFilters: DraftPoliciesFilters;
  PoliciesFilters: PoliciesFilters;
  CancelledPoliciesFilters: CancelledPoliciesFilters;
}
