import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";
import { GridColumn } from "./GridColumn";
import AppUtils from "../../helpers/utilities/AppUtils";

/* -------------------------------------------------------------------------- */
/*                               Default Columns                              */
/* -------------------------------------------------------------------------- */
//#region

export const PoliciesColumnsDefsEn: GridColumn[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "Status",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeEn(e),
    field: "status",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "Identity Number",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeEn(e),
    field: "identityNumber",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "Date of Birth",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeEn(e),
    field: "birthDate",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "Age",
    field: "age",
    minWidth: 80,
  },
  {
    headerName: "Gender",
    field: "genderName",
    minWidth: 100,
  },
  {
    headerName: "Identity Type",
    field: "identityTypeName",
    minWidth: 120,
  },
  {
    headerName: "Nationality",
    field: "nationalityName",
    minWidth: 100,
  },
  {
    headerName: "Quotation Reference",
    field: "quotationRequestRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 155,
  },
  {
    headerName: "Policy Reference",
    field: "policyMotorRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 140,
  },
  {
    headerName: "Lessee Name",
    field: "clientName",
    minWidth: 220,
  },
  {
    headerName: "Product Type",
    field: "productTypeName",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Insurance Company",
    field: "insuranceCompanyName",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 170,
  },
  {
    headerName: "Policy No.",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 170,
  },
  {
    headerName: "Policy Issue Date",
    field: "policyIssueDate",
    cellRenderer: DateRenderer.dateFormatterEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Policy Effective Date",
    field: "policyEffectiveDate",
    cellRenderer: DateRenderer.dateFormatterEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Policy Expiry Date",
    field: "policyExpiryDate",
    cellRenderer: DateRenderer.dateFormatterEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Expiring In",
    field: "expiryingIn",
    sortable: false,
    filter: false,
    minWidth: 170,
    cellRenderer: (e) => CellRenderers.expiryTimeEnRenderer(e),
  },
  {
    headerName: "Vehicle Type",
    field: "vehicleIdTypeName",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle ID",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle Repair Method",
    field: "repairMethod",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle Plate Number",
    field: "vehiclePlateNumber",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle",
    field: "vehicleSummary",
    cellRenderer: "ImageCell",
    tooltip: () => "Click to display vehicle details",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
  {
    headerName: "Vehicle Color",
    field: "vehicleMajorColorNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Chassis Number",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Region",
    field: "city",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle Sum Insured",
    field: "vehicleSumInsured",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Deductible Amount",
    field: "deductibleAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Total Additional Benefits",
    field: "totalAdditioanlBenefits",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },

  //   start
  {
    headerName: "Base Premium",
    field: "premBeforeDiscountExclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Total Discounts",
    field: "totalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Premium After Discounts",
    field: "premAfterDiscountExclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Total VAT After Discounts",
    field: "totalVATAmountAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Total Premium After Discounts",
    field: "premAfterDiscountInclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "Total VAT Before Discounts",
    field: "totalVATAmountBeforeDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "Total Premium Before Discounts",
    field: "premBeforDiscountInclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },

  //   end
  //   {
  //     headerName: "Total Premium",
  //     field: "totalPaidAmount",
  //     cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
  //     sortable: false,
  //     filter: false,
  //     minWidth: 200,
  //   },
  {
    headerName: "Najm Status",
    field: "isNajmUpdated",
    cellRenderer: (e) => CellRenderers.najmStatusEnRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Najm Update Date",
    field: "najmUpdatedDate",
    cellRenderer: (e) => CellRenderers.nullableDateEnRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Najm Reference",
    field: "najmVehicleReferenceId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Request Type",
    field: "isRenewal",
    minWidth: 170,
  },
  {
    headerName: "Is Migrated",
    field: "isMigrated",
    cellRenderer: (e) => CellRenderers.isMigratedRendererEn(e),
    sortable: false,
    filter: false,
    minWidth: 100,
  },
  {
    headerName: "Submitted On",
    field: "createdDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    cellRenderer: DateRenderer.specificDateFormatterEn,
  },
  {
    headerName: "Submitted By",
    field: "createdBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
];

export const PoliciesColumnsDefsAr: GridColumn[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "الحالة",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeAr(e),
    field: "statusAr",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "رقم الهوية",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeAr(e),
    field: "identityNumber",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "تاريخ الميلاد",
    cellStyle: (e) => StatusBadgeRenderer.policyStatusBadgeAr(e),
    field: "birthDate",
    minWidth: 140,
    sortable: false,
    filter: false,
  },
  {
    headerName: "العمر",
    field: "age",
    minWidth: 100,
  },
  {
    headerName: "النوع",
    field: "genderNameAr",
    minWidth: 100,
  },
  {
    headerName: "نوع الهوية",
    field: "identityTypeNameAr",
    minWidth: 140,
  },
  {
    headerName: "الجنسية",
    field: "nationalityNameAr",
    minWidth: 140,
  },
  {
    headerName: "الرقم المرجعي للتسعيرة",
    field: "quotationRequestRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "الرقم المرجعي للوثيقة",
    field: "policyMotorRefId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "أسم المستأجر",
    field: "clientNameAr",
    minWidth: 220,
  },
  {
    headerName: "نوع المنتج",
    field: "productTypeNameAr",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "شركة التأمين",
    field: "insuranceCompanyNameAr",
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 220,
  },
  {
    headerName: "رقم الوثيقة",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    tooltip: (e) => e.value,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "تاريخ إصدار الوثيقة",
    field: "policyIssueDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "تاريخ بداية الوثيقة",
    field: "policyEffectiveDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "تاريخ نهاية الوثيقة",
    field: "policyExpiryDate",
    cellRenderer: DateRenderer.dateFormatterAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "تنتهي بعد",
    field: "expiryingIn",
    sortable: false,
    filter: false,
    minWidth: 150,
    cellRenderer: (e) => CellRenderers.expiryTimeArRenderer(e),
  },
  {
    headerName: "نوع المركبة",
    field: "vehicleIdTypeNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "الرقم التسلسلي",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "طريقة الصيانة",
    field: "repairMethodAr",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "رقم اللوحة",
    field: "vehiclePlateNumber",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "المركبة",
    field: "vehicleSummary",
    cellRenderer: "ImageCell",
    tooltip: () => "إضغط لعرض تفاصيل المركبة",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
  {
    headerName: "لون المركبة",
    field: "vehicleMajorColorNameAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "رقم الهيكل",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "المنطقة",
    field: "city",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "القيمة التقديرية للمركبة",
    field: "vehicleSumInsured",
    cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "قيمة التحمل",
    field: "deductibleAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "إجمالي الفوائد الإضافية",
    field: "totalAdditioanlBenefits",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  //   start
  {
    headerName: "القسط الأساسى",
    field: "premBeforeDiscountExclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "إجمالى الخصم",
    field: "totalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "القسط بعد الخصومات",
    field: "premAfterDiscountExclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "إجمالي ضريبة القيمة المضافة بعد الخصومات",
    field: "totalVATAmountAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "إجمالي الأقساط بعد الخصومات",
    field: "premAfterDiscountInclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "إجمالي ضريبة القيمة المضافة قبل الخصومات",
    field: "totalVATAmountBeforeDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  {
    headerName: "إجمالي القسط قبل الخصومات",
    field: "premBeforDiscountInclVAT",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
  },
  //   {
  //     headerName: "القيمة المدفوعة",
  //     field: "totalPaidAmount",
  //     cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
  //     sortable: false,
  //     filter: false,
  //     minWidth: 180,
  //   },
  {
    headerName: "حالة نجم",
    field: "isNajmUpdated",
    cellRenderer: (e) => CellRenderers.najmStatusArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "تاريخ تجديث نجم",
    field: "najmUpdatedDate",
    cellRenderer: (e) => CellRenderers.nullableDateEnRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "رقم نجم التعريفي",
    field: "najmVehicleReferenceId",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "نوع الطلب",
    field: "isRenewal",
    minWidth: 170,
  },
  {
    headerName: "مٌرّحَلة",
    field: "isMigrated",
    cellRenderer: (e) => CellRenderers.isMigratedRendererAr(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "تاريخ الإنشاء",
    field: "createdDate",
    sortable: false,
    filter: false,
    minWidth: 200,
    cellRenderer: DateRenderer.specificDateFormatterAr,
  },
  {
    headerName: "أنشئ بواسطة",
    field: "createdBy",
    sortable: false,
    filter: false,
    minWidth: 230,
  },
];

//#endregion

/* -------------------------------------------------------------------------- */
/*                           Concord Default Columns                          */
/* -------------------------------------------------------------------------- */
//#region

export const ConcordPoliciesColsEn: GridColumn[] = AppUtils.injectAt(
  PoliciesColumnsDefsEn.length - 2,
  PoliciesColumnsDefsEn.concat(),
  [
    {
      headerName: "Lessor",
      field: "financialEntityName",
      minWidth: 170,
    },
  ]
);

export const ConcordPoliciesColsAr: GridColumn[] = AppUtils.injectAt(
  PoliciesColumnsDefsAr.length - 2,
  PoliciesColumnsDefsAr.concat(),
  [
    {
      headerName: "المؤجر",
      field: "financialEntityNameAr",
      minWidth: 170,
    },
  ]
);

//#endregion

/* -------------------------------------------------------------------------- */
/*              Concord Privileged (Accountants & Admins) Columns             */
/* -------------------------------------------------------------------------- */
//#region

export const ConcordPrivilegedPoliciesColsEn: GridColumn[] = AppUtils.injectAt(
  19,
  ConcordPoliciesColsEn.concat(),
  [
    {
      headerName: "Admin Fees",
      field: "adminFee",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "Net Premium",
      field: "netPremium",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "VAT Percentage",
      field: "vatPerc",
      cellRenderer: (e) => CellRenderers.vatPercentageValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "Total VAT Amount",
      field: "totalVATAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "Comission Percentage",
      field: "compCommissionPerc",
      cellRenderer: (e) => CellRenderers.vatPercentageValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "Total Commission",
      field: "totalCompCommission",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "Total Commission VAT Amount",
      field: "totalCompCommissionVATAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 220,
    },
  ]
);

export const ConcordPrivilegedPoliciesColsAr: GridColumn[] = AppUtils.injectAt(
  19,
  ConcordPoliciesColsAr.concat(),
  [
    {
      headerName: "مصاريف إدارية",
      field: "adminFee",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "صافي المبلغ",
      field: "netPremium",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "ضريبة القيمة المضافة (نسبة)",
      field: "vatPerc",
      cellRenderer: (e) => CellRenderers.vatPercentageValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "ضريبة القيمة المضافة",
      field: "totalVATAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "العمولة",
      field: "compCommissionPerc",
      cellRenderer: (e) => CellRenderers.vatPercentageValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "إجمالي العمولة",
      field: "totalCompCommission",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
    {
      headerName: "ضريبة القيمة المضافة للعمولة",
      field: "totalCompCommissionVATAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      minWidth: 200,
    },
  ]
);

//#endregion
