import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { AuthService } from "../../services/auth/auth.service";
import { LettersColors } from "../../../shared/models/app/LettersColors";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@ngneat/reactive-forms";
import { Validators } from "@angular/forms";
import { UserService } from "../../services/users/user.service";
import { PasswordResetReq } from "../../payloads/requests/users/PasswordResetReq";
import { ApiResponse } from "../../payloads/response/ApiResponse";
import { LanguageService } from "../../services/app/language.service";
import { AppLanguage } from "../../models/app/AppLanguage";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";
import { UpdateService } from "../../services/update.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  //#region

  @ViewChild("passwordResetModal") passwordResetModal;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  /* Data State */
  state = {
    user: {
      nameLetters: "",
      nameLettersColor: new Map(),
    },
    tenant: {
      name: "",
      nameAr: "",
      branch: "",
      branchAr: "",
    },
  };

  /* UI State */
  uiState = {
    isPasswordVisible: false,
    appLang: "En",
    alert: {
      error: {
        message: undefined as string,
        validationErrors: [] as string[],
        isVisible: false,
      },
      success: {
        message: undefined as string,
        isVisible: false,
      },
    },
    passwordReset: {
      step: ("ResetIssue" as "ResetIssue") || "ResetRequest",
      isLoading: false,
      isSubmitting: false,
    },
    app: {
      isUpdateAvailable: false,
    },
  };

  passwordResetFormGroup: FormGroup;

  // TODO: Add these to the UI State
  public menuItems: Menu[];
  public items: Menu[];
  public openNav: boolean = false;
  public right_sidebar: boolean = false;
  public text: string;
  public screenWidth: any;
  public screenHeight: any;

  //#endregion

  constructor(
    public navService: NavService,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private langService: LanguageService,
    private updateService: UpdateService
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                               Initialization                               */
  /* -------------------------------------------------------------------------- */
  //#region

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 992) this.navService.collapseSidebar = true;
    else this.navService.collapseSidebar = true;
    this.navService.items.subscribe((menuItems) => (this.items = menuItems));
    this.initUserInfo();
    this.initForms();
    this.watchLangChange();
    this.watchForUpdate();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.navService.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 992) this.navService.collapseSidebar = true;
  }
  /**
   * Gets and initializes the user info when user is authenticated
   *
   * @private
   * @memberof HeaderComponent
   */
  private initUserInfo(): void {
    this.authService.getIsLoggedIn().subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        // Get the access token from storage
        const accessToken = this.authService.getDecodedToken();
        // Set the user's name letters and color
        this.state.user.nameLetters = accessToken.sub
          .substring(0, 2)
          .toUpperCase();
        LettersColors.AlphaColors.forEach((item) =>
          this.state.user.nameLettersColor.set(item.letter, item.color)
        );

        // Set the user's tenant name
        this.state.tenant.name = accessToken.tenantName;
        this.state.tenant.nameAr = accessToken.tenantNameAr;

        // Set the user's tenant branch
        this.state.tenant.branch = accessToken.BranchName;
        this.state.tenant.branchAr = accessToken.BranchNameAr;
      }
    });
  }

  private initForms(): void {
    // Construct password reset request form
    this.passwordResetFormGroup = this.formBuilder.group({
      emailCtrl: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
        ]),
      ],
      codeCtrl: [null],
      newPasswordCtrl: [null],
    });
  }

  get passwordResetForm() {
    return this.passwordResetFormGroup.controls;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                  Listeners                                 */
  /* -------------------------------------------------------------------------- */
  //#region

  private watchLangChange(): void {
    this.langService
      .getAppLang()
      .subscribe((lang) => (this.uiState.appLang = lang));
  }

  private watchForUpdate(): void {
    this.updateService.getIsUpdateAvailable().subscribe((event) => {
      if (event.hasUpdate) this.uiState.app.isUpdateAvailable = true;
      else this.uiState.app.isUpdateAvailable = false;
    });
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                               User Interface                               */
  /* -------------------------------------------------------------------------- */
  //#region

  openPasswordResetModal(): void {
    this.modalService.open(this.passwordResetModal, {
      centered: true,
      size: "xl",
    });
  }

  openMobileNav() {
    this.openNav = !this.openNav;
    this.navService.isOpenMobile = this.openNav;
  }

  collapseSidebar() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
  }

  setAppLanguage(lang: "ar" | "en") {
    localStorage.setItem(LocallyStoredItemsKeys.AppLanguage, lang);
    location.reload();
  }

  switchAppLanguage() {
    if (this.uiState.appLang === AppLanguage.ARABIC)
      localStorage.setItem(
        LocallyStoredItemsKeys.AppLanguage,
        AppLanguage.ENGLISH
      );
    else
      localStorage.setItem(
        LocallyStoredItemsKeys.AppLanguage,
        AppLanguage.ARABIC
      );
    location.reload();
  }

  applyUpdates(): void {
    document.location.reload();
  }

  logout() {
    this.authService.logout();
  }

  private displayAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.uiState.alert.error.message = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.uiState.alert.error.validationErrors = errorsArr;
        } else {
          this.uiState.alert.error.validationErrors = null;
        }

        // Display alert
        this.uiState.alert.error.isVisible = true;

        break;

      case "SuccessAlert":
        // Set the success message
        this.uiState.alert.success.message = apiResponse.responseMessage;

        // Display alert
        this.uiState.alert.success.isVisible = true;

        break;

      default:
        break;
    }
  }

  toggleNotificationsBar(): void {
    this.navService.isNotificationBarOpen.next(
      !this.navService.isNotificationBarOpen.value
    );
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                API / Submit                                */
  /* -------------------------------------------------------------------------- */
  //#region

  issuePasswordReset(): void {
    // Set submission flag
    this.uiState.passwordReset.isLoading = true;
    this.uiState.passwordReset.isSubmitting = true;

    // Validate form
    if (!this.passwordResetFormGroup.valid) {
      this.uiState.passwordReset.isLoading = false;
      return;
    }

    // Construct password reset issue request
    let passwordResetIssueReq = {
      email: this.passwordResetForm.emailCtrl.value,
    };

    // Send the password reset issue request
    this.userService.issuePasswordReset(passwordResetIssueReq).subscribe(
      (res) => {
        // Move to the next step where user enters new password
        this.uiState.passwordReset.step = "ResetRequest";

        // Hide error alert if visible
        this.uiState.alert.error.isVisible = false;

        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;
      },
      (err) => {
        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  resetPassword(): void {
    // Set submission flag
    this.uiState.passwordReset.isLoading = true;
    this.uiState.passwordReset.isSubmitting = true;

    // Add verification code and new password requiry validators
    this.passwordResetForm.codeCtrl.setValidators([Validators.required]);
    this.passwordResetForm.newPasswordCtrl.setValidators([Validators.required]);

    // Validate form
    if (!this.passwordResetFormGroup.valid) {
      this.uiState.passwordReset.isLoading = false;
      return;
    }

    // Construct password reset issue request
    let passwordResetReq: PasswordResetReq = {
      email: this.passwordResetForm.emailCtrl.value,
      code: this.passwordResetForm.codeCtrl.value,
      newPassword: this.passwordResetForm.newPasswordCtrl.value,
    };

    // Send the password reset issue request
    this.userService.resetPassword(passwordResetReq).subscribe(
      (res) => {
        // Move to the previous step where user enters email
        this.uiState.passwordReset.step = "ResetIssue";

        // Hide error alert if visible
        this.uiState.alert.error.isVisible = false;

        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;
      },
      (err) => {
        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  //#endregion
}
