<div [ngClass]="{'invoice-container': uiState.pagePrint.isPrinting}">
  <div class="app-container">
    <app-notifications-bar></app-notifications-bar>
    <router-outlet></router-outlet>
    <app-toasts></app-toasts>
  </div>
  <div *ngIf="uiState.pagePrint.isPrinting" class="invoice-wrapper flex-container">
    <div class="invoice">
      <app-comparison-sheet></app-comparison-sheet>
    </div>
  </div>
</div>