import {
  QuotationsColumnDefsEn,
  QuotationsConcordColsAr,
  QuotationsConcordColsEn,
} from "./QuotationsCols";
import { QuotationsColumnDefsAr } from "./QuotationsCols";
import { ClientsColumnDefsAr, ClientsColumnDefsEn } from "./ClientsCols";
import {
  AddressesColumnsDefsAr,
  AddressesColumnsDefsEn,
} from "./AddressesCols";
import { ContactsColumnsDefsAr, ContactsColumnsDefsEn } from "./ContactsCols";
import { LicensesColumnsDefsAr, LicensesColumnsDefsEn } from "./LicensesCols";
import {
  ConcordPoliciesColsAr,
  ConcordPoliciesColsEn,
  ConcordPrivilegedPoliciesColsAr,
  ConcordPrivilegedPoliciesColsEn,
  PoliciesColumnsDefsAr,
  PoliciesColumnsDefsEn,
} from "./PoliciesCols";
import {
  DraftPoliciesColumnsDefsAr,
  DraftPoliciesColumnsDefsEn,
} from "./DraftPoliciesCols";
import { VehiclesColumnsDefsAr, VehiclesColumnsDefsEn } from "./VehiclesCols";
import { UsersColumnsDefsAr, UsersColumnsDefsEn } from "./UsersCols";
import { YaqeenColumnsDefsAr, YaqeenColumnsDefsEn } from "./YaqeenReportCols";
import {
  InsuranceAccountColumnDefsAr,
  InsuranceAccountColumnDefsEn,
  shadowAccountColumnDefsAr,
  shadowAccountColumnDefsEn,
} from "./InsuranceAccountCols";
import { SmsLoggerColsAr, SmsLoggerColsEn } from "./SmsLoggerCols";
import {
  CancelledPoliciesColumnsDefsAr,
  CancelledPoliciesColumnsDefsEn,
  ConcordCancelledPoliciesColsAr,
  ConcordCancelledPoliciesColsEn,
} from "./CancelledPoliciesCols";
import {
  PendingCancellationColumnsDefsAr,
  PendingCancellationColumnsDefsEn,
  PendingCancellationPoliciesColsAr,
  PendingCancellationPoliciesColsEn,
  PendingCancellationPrivilegedPoliciesColsAr,
  PendingCancellationPrivilegedPoliciesColsEn,
} from "./CancellationCols";

export default class TablesColsDefs {
  public static QuotationsTableEn = QuotationsColumnDefsEn;
  public static QuotationsTableAr = QuotationsColumnDefsAr;
  public static QuotationsConcordTableEn = QuotationsConcordColsEn;
  public static QuotationsConcordTableAr = QuotationsConcordColsAr;

  public static PoliciesTableEn = PoliciesColumnsDefsEn;
  public static PoliciesTableAr = PoliciesColumnsDefsAr;
  public static ConcordPoliciesTableEn = ConcordPoliciesColsEn;
  public static ConcordPoliciesTableAr = ConcordPoliciesColsAr;
  public static ConcordPrivilegedPoliciesTableEn = ConcordPrivilegedPoliciesColsEn;
  public static ConcordPrivilegedPoliciesTableAr = ConcordPrivilegedPoliciesColsAr;

  public static CancelledPoliciesTableEn = CancelledPoliciesColumnsDefsEn;
  public static CancelledPoliciesTableAr = CancelledPoliciesColumnsDefsAr;
  public static ConcordCancelledPoliciesTableEn = ConcordCancelledPoliciesColsEn;
  public static ConcordCancelledPoliciesTableAr = ConcordCancelledPoliciesColsAr;

  public static PendingCancellationTableEn = PendingCancellationColumnsDefsEn;
  public static PendingCancellationTableAr = PendingCancellationColumnsDefsAr;
  public static PendingCancellationPoliciesTableEn = PendingCancellationPoliciesColsEn;
  public static PendingCancellationPoliciesTableAr = PendingCancellationPoliciesColsAr;
  public static PendingCancellationPrivilegedPoliciesTableEn = PendingCancellationPrivilegedPoliciesColsEn;
  public static PendingCancellationPrivilegedPoliciesTableAr = PendingCancellationPrivilegedPoliciesColsAr;

  public static DraftPoliciesTableEn = DraftPoliciesColumnsDefsEn;
  public static DraftPoliciesTableAr = DraftPoliciesColumnsDefsAr;

  public static ClientsTableEn = ClientsColumnDefsEn;
  public static ClientsTableAr = ClientsColumnDefsAr;

  public static InsuranceAccountTableEn = InsuranceAccountColumnDefsEn;
  public static InsuranceAccountTableAr = InsuranceAccountColumnDefsAr;

  public static ShadowAccountTableEn = shadowAccountColumnDefsEn;
  public static ShadoweAccountTableAr = shadowAccountColumnDefsAr;

  public static AddressesTableEn = AddressesColumnsDefsEn;
  public static AddressesTableAr = AddressesColumnsDefsAr;

  public static ContactsTableEn = ContactsColumnsDefsEn;
  public static ContactsTableAr = ContactsColumnsDefsAr;

  public static LicensesTableEn = LicensesColumnsDefsEn;
  public static LicensesTableAr = LicensesColumnsDefsAr;

  public static VehiclesTableEn = VehiclesColumnsDefsEn;
  public static VehiclesTableAr = VehiclesColumnsDefsAr;

  public static UsersTableEn = UsersColumnsDefsEn;
  public static UsersTableAr = UsersColumnsDefsAr;

  public static YaqeenReportEn = YaqeenColumnsDefsEn;
  public static YaqeenReportAr = YaqeenColumnsDefsAr;

  public static SmsLoggerTableEN = SmsLoggerColsEn;
  public static SmsLoggerTableAr = SmsLoggerColsAr;
}
