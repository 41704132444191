import { Component, Input, OnInit } from '@angular/core';
import { LoadingPageTypes } from '../../models/app/LoadingPageTypes';


@Component({
   selector: 'app-loading',
   templateUrl: './loading.component.html',
   styleUrls: ['./loading.component.scss']
})


export class LoadingComponent implements OnInit {

   //#region 

   @Input() message: string;

   //#endregion


   constructor(
   ) { }


   /* -------------------------------------------------------------------------- */
   /*                               Initialization                               */
   /* -------------------------------------------------------------------------- */
   //#region 

   ngOnInit() { 
      window.scroll({top: 0});
   }

   //#endregion

}
