<ngb-toast 

   *ngFor="let toast of toastService.toasts" 
   [header]="toast.headertext" 
   [class]="toast.classname"
   [autohide]="toast.autohide || true" 
   [delay]="toast.delay || 5000" 
   (hide)="toastService.remove(toast)"
   style="position: fixed; bottom: 2em; right: 2em; z-index: 1000; min-width: 250px;">

   <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
   </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>

</ngb-toast>
