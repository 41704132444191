<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <span *ngIf="uiState.appLang === 'ar'">
        &copy; أويسيس {{ uiState.currentYear }}
        {{ "FOOTER.Copyright" | translate }}
      </span>
      <span *ngIf="uiState.appLang === 'en'">
        &copy; Oasis {{ uiState.currentYear }}
        {{ "FOOTER.Copyright" | translate }}
      </span>
    </div>
    <div class="app-version col-md-6">
      <p class="pull-right mb-0" style="color: #898989">
        {{ uiState.appLang === "ar" ? "الإصدار" : "Version" }}
        {{ uiState.appVersion }}
        {{ "FOOTER.OwnerText" | translate }}
        <a href="https://oasisoft.net/" target="_blank">
          {{ "FOOTER.OwnerWebsite" | translate }}</a>
      </p>
    </div>
  </div>
</div>