import { Component, OnInit } from '@angular/core';
import * as updatesList from '../../../../assets/json/latest-updates.json';
import { LanguageService } from '../../services/app/language.service';
const appVersion = require('../../../../../package.json').version;


@Component({
   selector: 'app-updates-list',
   templateUrl: './updates-list.component.html',
   styleUrls: ['./updates-list.component.scss']
})


export class UpdatesListComponent implements OnInit {

   //#region States

   state = {
      updates: {
         list: [] as { TitleEn: string, TitleAr: string, Date: string }[],
      }
   };

   uiState = {
      appLang: '',
      appVersion: '',
      currentDate: new Date(),
   };

   //#endregion


   constructor(
      private langService: LanguageService
   ) { }


   ngOnInit() {
      this.readUpdatesList();
      this.uiState.appVersion = appVersion;
      this.uiState.appLang = this.langService.getAppLang().value;
   }


   /* -------------------------------------------------------------------------- */
   /*                               Initialization                               */
   /* -------------------------------------------------------------------------- */
   //#region 

   private readUpdatesList(): void {
      this.state.updates.list = updatesList.default.updates;
   }

   //#endregion

}
