import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from "./loading.component";
import { LoadingRoutingModule } from './loading-routing.module';


@NgModule({

   declarations: [
      LoadingComponent
   ],

   imports: [
      CommonModule,
      LoadingRoutingModule,
   ],

   exports: [
      LoadingComponent
   ]

})


export class LoadingModule { }
