import { StatusBadgeRenderer } from "./StatusBadgeRenderer";


export const YaqeenColumnsDefsEn = [
   {
      headerName: "Service",
      field: "service",
      minWidth: 130,
      cellStyle: StatusBadgeRenderer.quotationStatusBadgeEn
   },
   {
      headerName: "Requests",
      field: "requests",
      minWidth: 130,
   },
   {
      headerName: "Success",
      field: "success",
      minWidth: 120,
   },
   {
      headerName: "Failed",
      field: "failed",
      minWidth: 220,
   },
];

export const YaqeenColumnsDefsAr = [
   {
      headerName: "الخدمة",
      field: "service",
      minWidth: 130,
      cellStyle: StatusBadgeRenderer.quotationStatusBadgeEn
   },
   {
      headerName: "الطلبات",
      field: "requests",
      minWidth: 130,
   },
   {
      headerName: "تمت بنجاح",
      field: "success",
      minWidth: 120,
   },
   {
      headerName: "لم تتم",
      field: "failed",
      minWidth: 220,
   },
];



