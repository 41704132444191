import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faChartBar,
  faFunnelDollar,
  faFileAlt,
  faFileInvoiceDollar,
  faTachometerAlt,
  faIdBadge,
  faUserShield,
  faChartLine,
  faDollarSign,
  faFileExcel,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";

@NgModule({
  declarations: [],

  imports: [CommonModule, FontAwesomeModule],

  exports: [FontAwesomeModule],
})
export class SidebarIconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUserShield,
      faIdBadge,
      faTachometerAlt,
      faChartBar,
      faFunnelDollar,
      faFileInvoiceDollar,
      faFileAlt,
      faChartLine,
      faDollarSign,
      faFileExcel,
      faFileSignature
    );
  }
}
