export class StatusBadgeRenderer {
  public static quotationStatusBadgeEn(e): any {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "Status") {
      switch (e.value) {
        case "Quoted":
          statusBadge["background-color"] = "#c1ca40";
          return statusBadge;

        case "Draft Policy Issued":
          statusBadge["background-color"] = "#ffad42";
          return statusBadge;

        case "Policy Issued":
          statusBadge["background-color"] = "#4dc66e";
          return statusBadge;

        case "Expird":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;

        case "Request Failed":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
      }
    }
  }

  public static quotationStatusBadgeAr(e): any {
    let statusBadge = {
      width: "120px",
      height: "22px",
      "margin-left": "30px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "الحالة") {
      switch (e.value) {
        case "تم التسعير":
          statusBadge["background-color"] = "#c1ca40";
          return statusBadge;

        case "وثيقة مسودة":
          statusBadge["background-color"] = "#ffad42";
          return statusBadge;

        case "وثيقة تأمين":
          statusBadge["background-color"] = "#4dc66e";
          return statusBadge;

        case "غير سارية المفعول":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;

        case "غير مكتمل ":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
      }
    }
  }

  public static policyStatusBadgeEn(e): any {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "Status") {
      switch (e.value) {
        case "Active":
          statusBadge["background-color"] = "#4dc66e";
          return statusBadge;
        case "Expired":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
        case "Pending Cancellation":
          statusBadge["background-color"] = "#ff9f40";
          return statusBadge;
        case "Cancelled":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
        case "Almost Expired":
          statusBadge["background-color"] = "#ffad42";
          return statusBadge;
      }
    }
  }

  public static policyStatusBadgeAr(e): any {
    let statusBadge = {
      width: "120px",
      "margin-left": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "الحالة") {
      switch (e.value) {
        case "سارية المفعول":
          statusBadge["background-color"] = "#4dc66e";
          return statusBadge;
        case "غير سارية المفعول":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
        case "في انتظار الإلغاء":
          statusBadge["background-color"] = "#ff9f40";
          return statusBadge;
        case "ملغي":
          statusBadge["background-color"] = "#ff3e3e";
          return statusBadge;
        case "قاربت علي الانتهاء":
          statusBadge["background-color"] = "#ffad42";
          return statusBadge;
      }
    }
  }

  public static clientVehcleStatusBadgeEn(e): any {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName === "Status") {
      if (e.value) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#6c757d";
        return statusBadge;
      }
    }
  }

  public static clientVehcleStatusBadgeAr(e): any {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName === "الحالة") {
      if (e.value) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#6c757d";
        return statusBadge;
      }
    }
  }

  public static userStatusBadgeEn(e) {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "Status") {
      if (e.data?.isActive) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#ff3e3e";
        return statusBadge;
      }
    }
  }

  public static userStatusBadgeAr(e) {
    let statusBadge = {
      width: "120px",
      "margin-left": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "الحالة") {
      if (e.data?.isActive) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#ff3e3e";
        return statusBadge;
      }
    }
  }

  public static smsStatusBadgeEn(e) {
    let statusBadge = {
      width: "120px",
      "margin-right": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "Status") {
      if (e.data?.status) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#ff3e3e";
        return statusBadge;
      }
    }
  }

  public static smsStatusBadgeAr(e) {
    let statusBadge = {
      width: "120px",
      "margin-left": "30px",
      height: "22px",
      display: "inline-block",
      padding: " 0.25em 0.4em",
      "margin-top": "10px",
      "font-size": "75%",
      "font-weight": "700",
      "line-height": "1.5",
      "text-align": "center",
      "white-space": "nowrap",
      "vertical-align": "baseline",
      "border-radius": "10px",
      color: "#fff",
      "background-color": "#007bff",
    };

    if (e.colDef.headerName == "الحالة") {
      if (e.data?.status) {
        statusBadge["background-color"] = "#4dc66e";
        return statusBadge;
      } else {
        statusBadge["background-color"] = "#ff3e3e";
        return statusBadge;
      }
    }
  }
}
