import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  LoginReq,
  VerifyReq,
} from "../../shared/payloads/requests/auth/LoginReq";
import { AuthService } from "../../shared/services/auth/auth.service";
import { ApiResponse } from "src/app/shared/models/app/ApiResponse";
import { UserService } from "src/app/shared/services/users/user.service";
import { PasswordResetReq } from "src/app/shared/payloads/requests/users/PasswordResetReq";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { TenantService } from "src/app/shared/services/app/tenant.service";
import { MasterTableDataService } from "src/app/shared/services/master-table-data/master-table-data.service";
import { environment } from "src/environments/environment";
import { take } from "rxjs/operators";
import { LocallyStoredItemsKeys } from "src/app/shared/models/app/LocallyStoredItemsKeys";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/shared/models/app/AppRoutes";
import { AppService } from "src/app/shared/services/app/app.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  //#region

  state = {};

  uiState = {
    appVersion: environment.appVersion,
    appLang: "",
    isLoading: false,
    isPasswordVisible: false,
    visibleTab: new BehaviorSubject("Login") as BehaviorSubject<string>,
    tenantLogoSrc: `../../../assets/images/logos/${this.tenantService.getTenantLogoFileName()}`,
    alert: {
      error: {
        message: undefined as string,
        validationErrors: [] as string[],
        isVisible: false,
      },
      success: {
        message: undefined as string,
        isVisible: false,
      },
    },
    login: {
      isLoading: false,
      isSubmitting: false,
      isLoadingOTP: false,
    },
    passwordReset: {
      step: ("ResetIssue" as "ResetIssue") || "ResetRequest",
      isLoading: false,
      isSubmitting: false,
    },
  };
  isLoginVisible = true;
  isOtpVisible = false;
  loginFormGroup: FormGroup;
  passwordResetFormGroup: FormGroup;

  public timerInterval: any;
  isExpired: boolean;
  display: any;
  countDown!: Subscription;
  counter = 180;
  tick = 1000;
  isVerifyAccountLoading: boolean;
  isResending: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  successMessage: string;
  otp: string = "";
  inOtp: boolean = false;
  isOtpSent: boolean = false;
  loginRes: any;
  /*Verification Account Alert */
  verifyAccountErrorMessage;
  verifyAccountSuccessMessage;
  isErrorAlertVisible;
  isSuccessAlertVisible;
  validationErrors: string[];
  //#endregion

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private tenantService: TenantService,
    private appService: AppService,
    private router: Router
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                               Initialization                               */
  /* -------------------------------------------------------------------------- */
  //#region

  ngOnInit(): void {
    this.initForms();
    this.watchTabSelectionChange();
  }

  initForms(): void {
    // Construct login form
    this.loginFormGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
        ]),
      ],
      password: [null, Validators.required],
    });

    // Construct password reset request form
    this.passwordResetFormGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
        ]),
      ],
      codeCtrl: [null],
      newpassword: [null],
    });
  }

  get loginForm() {
    return this.loginFormGroup.controls;
  }
  get passwordResetForm() {
    return this.passwordResetFormGroup.controls;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                  Listeners                                 */
  /* -------------------------------------------------------------------------- */
  //#region

  watchTabSelectionChange(): void {
    this.uiState.visibleTab.subscribe((selectedTab) => {
      // Conceal alerts from previous tab
      this.uiState.alert.error.isVisible = false;
      this.uiState.alert.success.isVisible = false;
    });
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                               User Interface                               */
  /* -------------------------------------------------------------------------- */
  //#region

  private displayAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.uiState.alert.error.message = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.uiState.alert.error.validationErrors = errorsArr;
        } else {
          this.uiState.alert.error.validationErrors = null;
        }

        // Display alert
        this.uiState.alert.error.isVisible = true;

        setTimeout(() => (this.uiState.alert.error.isVisible = false), 7000);

        break;

      case "SuccessAlert":
        // Set the success message
        this.uiState.alert.success.message = apiResponse.responseMessage;

        // Display alert
        this.uiState.alert.success.isVisible = true;

        setTimeout(() => (this.uiState.alert.success.isVisible = false), 7000);

        break;

      default:
        break;
    }
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                API / Submit                                */
  /* -------------------------------------------------------------------------- */
  //#region

  // start timer counter
  countDwon() {
    if (this.countDown) this.countDown.unsubscribe();
    this.counter = 180;
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        // console.log(this.counter);
        if (this.counter < 1) {
          this.countDown.unsubscribe();
          // this.isOtpSent = true;
        }
      });
  }
  // display timer UI
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  closeActiveModal() {
    this.uiState.visibleTab.next("Login");
    this.uiState.login.isLoading = false;
    clearInterval(this.timerInterval);
  }

  onOtpChange(e: any) {
    this.otp = e;
  }

  login(): void {
    // Set submission state to true (used for form validation)
    this.uiState.login.isSubmitting = true;

    // Display loader
    this.uiState.login.isLoading = true;
    // Validate form
    if (this.loginFormGroup.invalid) {
      this.uiState.login.isLoading = false;
      return;
    }

    // Construct login data
    let loginReq: LoginReq = {
      username: this.loginForm.email.value,
      password: this.loginForm.password.value,
    };

    // Send login request
    this.authService.login(loginReq).subscribe(
      (res) => {
        this.loginRes = res.tokenData;
        this.uiState.login.isLoading = false;
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isSubmitting = false;
        if (res.isSuccess) {
          this.displayAlert("SuccessAlert", res);
          if (!res.tokenData.is2FAEnabled) {
            localStorage.setItem(
              LocallyStoredItemsKeys.JWT,
              res.tokenData.token
            );
            this.authService.setIsLoggedIn(true);
            // Navigate to home
            this.router.navigate([AppRoutes.home.full]);
          } else this.uiState.visibleTab.next("OTP");
        }
        if (!res.isSuccess) this.displayAlert("ErrorAlert", res);
      },
      (err) => {
        // Stop the loader
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isLoading = false;
        this.uiState.login.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );

    // start timer
    this.countDwon();
  }

  // verift OTP to login
  verifyOTP() {
    this.uiState.login.isLoadingOTP = true;

    // Construct verify data
    let verifyReq: VerifyReq = {
      userId: this.loginRes.userId,
      phoneNumber: this.loginRes.phoneNumber,
      code: this.otp,
    };

    // Send login request
    this.authService.verificationCode(verifyReq).subscribe(
      (res) => {
        this.uiState.login.isLoading = false;
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isSubmitting = false;
        this.appService.startWatching();
        this.appService.startWatching();
        if (!res.isSuccess) this.displayAlert("ErrorAlert", res);
      },
      (err) => {
        // Stop the loader
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isLoading = false;
        this.uiState.login.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  //resend OTP
  resendOTP() {
    // Construct login data
    let loginReq: LoginReq = {
      username: this.loginForm.email.value,
      password: this.loginForm.password.value,
    };

    // Send login request
    this.authService.login(loginReq).subscribe(
      (res) => {
        this.loginRes = res;
        this.uiState.login.isLoading = false;
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isSubmitting = false;
        if (res.isSuccess) this.countDwon();

        if (!res.isSuccess) this.displayAlert("ErrorAlert", res);
      },
      (err) => {
        // Stop the loader
        this.uiState.login.isLoadingOTP = false;
        this.uiState.login.isLoading = false;
        this.uiState.login.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  issuePasswordReset(): void {
    // Set submission flag
    this.uiState.passwordReset.isLoading = true;
    this.uiState.passwordReset.isSubmitting = true;

    // Validate form
    if (!this.passwordResetFormGroup.valid) {
      this.uiState.passwordReset.isLoading = false;
      return;
    }

    // Construct password reset issue request
    let passwordResetIssueReq = {
      email: this.passwordResetForm.email.value,
    };

    // Send the password reset issue request
    this.userService.issuePasswordReset(passwordResetIssueReq).subscribe(
      (res) => {
        // Move to the next step where user enters new password
        this.uiState.passwordReset.step = "ResetRequest";

        // Hide error alert if visible
        this.uiState.alert.error.isVisible = false;

        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;
      },
      (err) => {
        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  resetPassword(): void {
    // Set submission flag
    this.uiState.passwordReset.isLoading = true;
    this.uiState.passwordReset.isSubmitting = true;

    // Add verification code and new password requiry validators
    this.passwordResetForm.codeCtrl.setValidators([Validators.required]);
    this.passwordResetForm.newpassword.setValidators([Validators.required]);

    // Validate form
    if (!this.passwordResetFormGroup.valid) {
      this.uiState.passwordReset.isLoading = false;
      return;
    }

    // Construct password reset issue request
    let passwordResetReq: PasswordResetReq = {
      email: this.passwordResetForm.email.value,
      code: this.passwordResetForm.codeCtrl.value,
      newPassword: this.passwordResetForm.newpassword.value,
    };

    // Send the password reset issue request
    this.userService.resetPassword(passwordResetReq).subscribe(
      (res: ApiResponse) => {
        // Move to login form
        this.uiState.visibleTab.next("Login");

        if (res.isSuccess) this.displayAlert("SuccessAlert", res);

        // Hide error alert if visible
        this.uiState.alert.error.isVisible = false;

        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;
      },
      (err) => {
        // Conceal loader
        this.uiState.passwordReset.isLoading = false;
        this.uiState.passwordReset.isSubmitting = false;

        // Display error alert
        this.displayAlert("ErrorAlert", err.error);
      }
    );
  }

  //#endregion
}
