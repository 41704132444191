import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronRight, faQuestionCircle, faSync } from '@fortawesome/free-solid-svg-icons';


@NgModule({

   declarations: [],

   imports: [
      CommonModule,
      FontAwesomeModule
   ],

   exports: [
      FontAwesomeModule
   ]

})


export class SharedIconsModule {
   constructor(library: FaIconLibrary) {
      library.addIcons(
         faChevronRight,
         faSync,
         faQuestionCircle
      );
   }
}
