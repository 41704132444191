import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LocallyStoredItemsKeys } from '../../models/app/LocallyStoredItemsKeys';
import { AppService } from '../../services/app/app.service';
import { NavService } from '../../services/nav.service';
const appVersion = require('../../../../../package.json').version;


@Component({
   selector: 'app-notifications-bar',
   templateUrl: './notifications-bar.component.html',
   styleUrls: ['./notifications-bar.component.scss']
})


export class NotificationsBarComponent implements OnInit, AfterViewInit {

   //#region 

   state = {};

   uiState = {
      isBarVisible: false,
      appVersion: '',
      updates: {
         isVisible: false,
      },
      darkTheme: {
         isActivated: false,
      },
      connectionChecks: {
         isActivated: false,
         isLoading: false,
      }
   };

   //#endregion

   constructor(
      private navService: NavService,
      private appService: AppService
   ) { }


   ngOnInit(): void {
      this.uiState.appVersion = appVersion;
      this.initSavedSettingsState();
   }


   ngAfterViewInit(): void {
      this.watchBarToggle();
   }


   /* -------------------------------------------------------------------------- */
   /*                               Initialization                               */
   /* -------------------------------------------------------------------------- */
   //#region 

   private initSavedSettingsState(): void {

      try { this.uiState.darkTheme.isActivated = JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.DarkTheme)).isDarkThemeActivated; }
      catch (ex) { this.uiState.darkTheme.isActivated = false; }
      
      try { this.uiState.connectionChecks.isActivated = JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.ConnectionChecks)).isConnectionCheckActivated; }
      catch (ex) { this.uiState.connectionChecks.isActivated = false; }

   }

   //#endregion


   /* -------------------------------------------------------------------------- */
   /*                                  Listeners                                 */
   /* -------------------------------------------------------------------------- */
   //#region 

   private watchBarToggle(): void {
      this.navService.isNotificationBarOpen.subscribe(isOpen => {
         this.uiState.isBarVisible = isOpen;
      });
   }

   //#endregion


   /* -------------------------------------------------------------------------- */
   /*                               User Interface                               */
   /* -------------------------------------------------------------------------- */
   //#region 

   toggleBar(): void {
      this.navService.isNotificationBarOpen.next(!this.navService.isNotificationBarOpen.value);
   }


   onDarkThemeToggle(): void {

      if (this.uiState.darkTheme.isActivated) {
         var body = document.body;
         body.classList.remove("dark-theme");
         localStorage.setItem(LocallyStoredItemsKeys.DarkTheme, JSON.stringify({ isDarkThemeActivated: false }));
      } else {
         var body = document.body;
         body.classList.add("dark-theme");
         localStorage.setItem(LocallyStoredItemsKeys.DarkTheme, JSON.stringify({ isDarkThemeActivated: true }));
      }

      this.uiState.darkTheme.isActivated = !this.uiState.darkTheme.isActivated;

   }


   onConnectionChecksToggle(): void {

      // Toggle the activation state
      this.uiState.connectionChecks.isActivated = !this.uiState.connectionChecks.isActivated;
      
      let isActivated = this.uiState.connectionChecks.isActivated;
      
      // Update stored preference according to activation state
      if (isActivated) localStorage.setItem(LocallyStoredItemsKeys.ConnectionChecks, JSON.stringify({ isConnectionCheckActivated: true }));
      else localStorage.setItem(LocallyStoredItemsKeys.ConnectionChecks, JSON.stringify({ isConnectionCheckActivated: false }))
      
      // Set the activation state subject
      this.appService.setConnectionChecksActivity(isActivated);

   }

   //#endregion

}
