import { Injectable, Injector } from "@angular/core";
import { LoginReq } from "../../payloads/requests/auth/LoginReq";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ApiRoutes } from "../../models/app/ApiRoutes";
import { tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AccessToken } from "../../models/app/AccessToken";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";
import {
  LoginResponse,
  VerifyResponse,
} from "../../payloads/response/LoginResponse";
import { AppRoutes } from "../../models/app/AppRoutes";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { UserRoles } from "../../models/app/UserRoles";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  isLoggedIn = new BehaviorSubject<boolean>(this.isTokenAvailable());
  jwtHelper = new JwtHelperService();
  static injector: Injector; // Allows the service to be injected into static classes (utils)

  constructor(private http: HttpClient, private router: Router) {}

  /**
   * Sends the login request, then stores the access token and proceeds
   *
   * @param {LoginReq} loginReq The user login request data
   * @return {*}  {Observable<LoginResponse>}
   * @memberof AuthService
   */
  login(loginReq: LoginReq): Observable<LoginResponse> {
    return this.http
      .post(this.apiUrl + ApiRoutes.Auth.Login, loginReq)
      .pipe(tap((res: LoginResponse) => {}));
  }

  /* verfication */
  verificationCode(code: any) {
    return this.http.post(this.apiUrl + ApiRoutes.Auth.Otp, code).pipe(
      tap((res: VerifyResponse) => {
        if (res.isSuccess) {
          localStorage.setItem(LocallyStoredItemsKeys.JWT, res.accessToken);
          this.setIsLoggedIn(true);
          // Navigate to home
          this.router.navigate([AppRoutes.home.full]);
        }
      })
    );
  }
  /**
   * Clears the access token, then logs out
   *
   * @return {*}  {Promise<any>}
   * @memberof AuthService
   */
  async logout(): Promise<any> {
    // Clear JWT from localstorage
    await localStorage.removeItem(LocallyStoredItemsKeys.JWT);

    // Update logged in status
    this.setIsLoggedIn(false);

    // Navigate user back to login page
    await this.router.navigate([AppRoutes.Authentication.login.full]);
  }

  private isTokenAvailable(): boolean {
    return !!localStorage.getItem(LocallyStoredItemsKeys.JWT);
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn.next(isLoggedIn);
  }

  getIsLoggedIn(): BehaviorSubject<boolean> {
    return this.isLoggedIn;
  }

  public getDecodedToken(): AccessToken {
    return this.jwtHelper.decodeToken(
      localStorage.getItem(LocallyStoredItemsKeys.JWT)
    );
  }

  public getUserRole(): UserRoles {
    return this.getDecodedToken()[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ] as UserRoles;
  }
}
