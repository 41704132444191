<div class="form-group">

   <label *ngIf="!isReportsPage()" class="form-label">{{ 'FILTERS.Duration' | translate }}</label>

   <div style="display: flex;">

      <input type="text"
      class="form-control"
      ngxDaterangepickerMd
      [(ngModel)]="state.selectedDateRange"
      (change)="onDateSelect($event)"
      [showCustomRangeLabel]="true"
      [alwaysShowCalendars]="true"
      [ranges]="isReportsPage() ? quarterRanges : ranges"
      [linkedCalendars]="true"
      [autoApply]="true"
      [closeOnAutoApply]="true"
      [locale]="{ format: 'DD-MMM-YYYY', displayFormat:'DD-MMM-YYYY'} "
      [placeholder]="('FILTERS.Duration' | translate)"
      readonly/>

      <button *ngIf="state.isClearButtonVisible" (click)="onDateClear()" class="btn primary icon-btn ipt-btn" style="transform: scaleX(0.9); margin-inline-start: -7px;">
         <fa-icon icon="times"></fa-icon>
      </button>

   </div>

</div>
