import { Injectable } from "@angular/core";
import { AppScripts } from './../../models/app/ScriptStore';

declare var document: any;


@Injectable({
   providedIn: 'root'
})


export class ScriptService {


   private scripts: any = {};


   constructor() {
      AppScripts.forEach(script => {
         this.scripts[script.name] = {
            loaded: false,
            src: script.src
         };
      });
   }


   public load(...scripts: string[]): Promise<any> {
      var promises: any[] = [];
      scripts.forEach((script) => promises.push(this.loadScript(script)));
      return Promise.all(promises);
   }


   public loadScript(name: string): Promise<any> {
      return new Promise((resolve, reject) => {
         // Resolve if already loaded
         if (this.scripts[name].loaded) {
            resolve({ script: name, loaded: true, status: 'Already Loaded' });
         }
         else {
            // Load script
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            if (script.readyState) {  // IE
               script.onreadystatechange = () => {
                  if (script.readyState === "loaded" || script.readyState === "complete") {
                     script.onreadystatechange = null;
                     this.scripts[name].loaded = true;
                     resolve({ script: name, loaded: true, status: 'Loaded' });
                  }
               };
            } else {  // Others
               script.onload = () => {
                  this.scripts[name].loaded = true;
                  resolve({ script: name, loaded: true, status: 'Loaded' });
               };
            }
            script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
         }
      });
   }

}
