export enum PremiumBreakdownsTypeIds {
   BasicPremium = 1,
   AdditionalAgeContribution = 2,
   AdminFees = 3,
   AdditionalLoading = 4,
   VAT = 5,
   TotalPremiumWithoutVAT = 6,
   AnnualPremiumBeforeNCD = 21,
   AnnualPremiumAfterNCD = 22 
}
