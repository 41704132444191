export default class DateColumns {

   public static dateColsEn = [
      'Submitted On', 'Policy Effective Date',
      'Issue Date', 'Expiry Date',
      'Insured Birth Date', 'Policy Issue Date',
      'Policy Expiry Date', 'Najm Update Date'
   ];

   public static dateColsAr = [
      'تاريخ الإنشاء', 'تاريخ الميلاد',
      'تاريخ بداية الوثيقة', 'تاريخ الإصدار',
      'تاريخ نهاية الوثيقة', 'تاريخ إصدار الوثيقة',
      'تاريخ الإنتهاء', 'تاريخ تحديثها في نجم'
   ];

}


export class DateRenderer {

   public static dateFormatterEn(e): any {
      if (DateColumns.dateColsEn.includes(e.colDef.headerName) && e.value) {

         const date = new Date(Date.parse(e.value));
         let d = e.value.split('-')[0];
         let m = new Date(e.value.split('-')[1]).toLocaleDateString("en-US", { month: 'short' });
         let y = new Date(e.value.split('-')[2]).toLocaleDateString("en-US", { year: 'numeric' });
         return `${d}-${m}-${y}`;

      }
   }

   public static dateFormatterOfTypeDateEn(e): any {
    if (DateColumns.dateColsEn.includes(e.colDef.headerName) && e.value) {

       const date = new Date(Date.parse(e.value));
       let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
       let m = date.toLocaleDateString("en-US", { month: 'short' });
       let y = date.toLocaleDateString("en-US", { year: 'numeric' });
       return `${d}-${m}-${y}`;

    }
 }


   public static dateFormatterAr(e): any {
      if (DateColumns.dateColsAr.includes(e.colDef.headerName) && e.value) {

         const date = new Date(Date.parse(e.value));
         let d = e.value.split('-')[0];
         let m = new Date(e.value.split('-')[1]).toLocaleDateString("ar-EG", { month: 'short' });
         let y = new Date(e.value.split('-')[2]).toLocaleDateString("en-US", { year: 'numeric' });
         return `${d}-${m}-${y}`;

      } else {
         return '-';
      }
   }

   public static dateFormatterOfTypeDateAr(e): any {
      if (DateColumns.dateColsAr.includes(e.colDef.headerName) && e.value) {
  
         const date = new Date(Date.parse(e.value));
         let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
         let m = date.toLocaleDateString("ar-EG", { month: 'short' });
         let y = date.toLocaleDateString("en-US", { year: 'numeric' });
         return `${d}-${m}-${y}`;
  
      } else {
         return '-';
      }
   }


   public static specificDateFormatterEn(e): any {
      if (DateColumns.dateColsEn.includes(e.colDef.headerName) && e.value) {

         const date = new Date(Date.parse(e.value));
         let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
         let m = date.toLocaleDateString("en-US", { month: 'short' });
         let y = date.toLocaleDateString("en-US", { year: 'numeric' });
         let h = date.getHours();
         let ms = date.getMinutes();
         let t = date.toLocaleTimeString().split(" ")[1];
         return `${d}-${m}-${y} ${h}:${ms} ${t}`;

      }
   }


   public static specificDateFormatterAr(e): any {
      if (DateColumns.dateColsAr.includes(e.colDef.headerName) && e.value) {

         const date = new Date(Date.parse(e.value));
         let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
         let m = date.toLocaleDateString("ar-EG", { month: 'short' });
         let y = date.toLocaleDateString("en-US", { year: 'numeric' });
         let h = date.getHours();
         let ms = date.getMinutes();
         let t = date.toLocaleTimeString("ar-EG").split(" ")[1];
         return `${d}-${m}-${y} ${h}:${ms} ${t}`;

      }
   }


   public static hijriDateFormatterAr(e): any {
      if (DateColumns.dateColsAr.includes(e.colDef.headerName) && e.value) {

         const date = new Date(Date.parse(e.value));
         let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
         let m = date.toLocaleDateString("ar-SA", { month: 'short' });
         let y = date.toLocaleDateString("en-US", { year: 'numeric' });
         return `${d}-${m}-${y}`;

      }
   }


   public static stringDateFormatterEn(unformattedDate: string) {
      const date = new Date(Date.parse(unformattedDate));
      let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
      let m = date.toLocaleDateString("en-US", { month: 'short' });
      let y = date.toLocaleDateString("en-US", { year: 'numeric' });
      return `${d}-${m}-${y}`;
   }


   public static stringDateFormatterAr(unformattedDate: string) {
      const date = new Date(Date.parse(unformattedDate));
      let d = (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate());
      let m = date.toLocaleDateString("ar-EG", { month: 'short' });
      let y = date.toLocaleDateString("en-US", { year: 'numeric' });
      return `${d}-${m}-${y}`;
   }

}
