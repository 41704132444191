import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationIconsModule } from "./authentication-icons.module";
import { TranslationModule } from "../shared/translation.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOtpInputModule } from "ng-otp-input";

@NgModule({
  declarations: [LoginComponent],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    AuthenticationIconsModule,
    TranslationModule,
    NgOtpInputModule,
  ],
})
export class AuthenticationModule {}
