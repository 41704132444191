<div dir="ltr" class="updates-list flex-column" style="text-align: left;">
   
   <h6 class="title mb-2" style="font-weight: 600;">
      {{ uiState.appLang === 'ar' ? 'أخر التحديثات' : 'Latest Updates' }}
   </h6>

   <div *ngFor="let update of state.updates.list" class="update-item flex-centered flex-column ml-0 mr-0">
      <span style="font-size: 14px;">{{ uiState.appLang === 'ar' ? update.TitleAr : update.TitleEn }}</span>
      <small class="mt-2" style="float: right;">{{ update.Date }}</small>
   </div>

</div>
