import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import { Vehicle } from "../../models/vehicle/Vehicle";
import { VehicleForm } from "../../models/vehicle/VehicleForm";

export default class AppUtils {
  /* -------------------------------------------------------------------------- */
  /*                                Date and Time                               */
  /* -------------------------------------------------------------------------- */
  //#region

  public static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public static formatDate(dateStruct: NgbDateStruct) {
    if (dateStruct.month.toString().length < 2) {
      dateStruct.month = 0 + dateStruct.month;
    }

    if (dateStruct.day.toString().length < 2) {
      dateStruct.day = 0 + dateStruct.day;
    }

    return [dateStruct.day, dateStruct.month, dateStruct.year].join("-");
  }

  public static addDays(date: Date, numOfDays: number) {
    date = new Date(new Date().getTime() + 86400000 * numOfDays);
    return date;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                  Scrolling                                 */
  /* -------------------------------------------------------------------------- */
  //#region

  public static disableScrolling() {
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
  }

  public static enableScrolling() {
    window.onscroll = function () {};
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                              String Extensions                             */
  /* -------------------------------------------------------------------------- */
  //#region

  public static strToNum(stringNumber: string): number {
    try {
      if (stringNumber) {
        return parseInt(stringNumber);
      } else return null;
    } catch (e) {
      return null;
    }
  }

  public static capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static getCommaSeparatedString(array: any): string {
    if (Array.isArray(array)) return array.join(",");
    else return null;
  }

  public static getUnmaskedNum(maskedPhoneNumber: string): string {
    return maskedPhoneNumber.replace(/[\+966  *]/g, "");
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                Miscellaneous                               */
  /* -------------------------------------------------------------------------- */
  //#region

  public static searchItemTerm(term: string, item: any): boolean {
    // Convert the input term to lowercase
    term = term.toLocaleLowerCase();
    // Return search result
    return (
      (item.name && item.name.toLocaleLowerCase().indexOf(term) > -1) ||
      (item.nameAr && item.nameAr.toLocaleLowerCase().indexOf(term) > -1)
    );
  }

  /**
   * Checks if the vehicle implements Vehicle or VehicleForm interfaces
   *
   * @param {(Vehicle | VehicleForm)} vehicle
   * @returns {boolean}
   * @memberof InsuredInfoComponent
   */
  public static isVehicleFormObject(vehicle: Vehicle | VehicleForm): boolean {
    return !(
      (<Vehicle>vehicle).vehicleMakerNameAr !== undefined ||
      (<Vehicle>vehicle).vehicleModelNameAr !== undefined ||
      (<Vehicle>vehicle).vehicleMakerLogo !== undefined ||
      (<Vehicle>vehicle).vehicleMajorColorName !== undefined
    );
  }

  /**
   * Generates a random UUID (sort of)
   *
   * @static
   * @returns The UUID string
   * @memberof AppUtils
   */
  public static UUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public static injectAt(idx, arr1, arr2): any[] {
    for (let i = arr1.length - 1; i >= idx; i--)
      arr1[i + arr2.length] = arr1[i];
    for (let i = 0; i < arr2.length; i++) arr1[i + idx] = arr2[i];
    return arr1;
  }

  public static deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static saveFile(
    fileResponse: any,
    mimeType: "application/pdf" | "application/zip"
  ): void {
    let fileName = fileResponse.headers.get("Content-FileName");

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      let blob = new Blob([fileResponse.body], { type: mimeType });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      let file = new File([fileResponse.body], fileName, { type: mimeType });
      let exportUrl = URL.createObjectURL(file);
      var win = window.open(exportUrl, "_blank");
      win.focus();
    }
  }

  //#endregion
}
