import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";


export const LicensesColumnsDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "License No.",
      field: "licenseNo",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Number of Years",
      field: "numberOfYears",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Origin",
      field: "isSaudiLicense",
      cellRenderer: (e) => CellRenderers.saudiLicenseCheckEnRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Expiry Date",
      field: "licenseExpiryDate",
      //cellRenderer: (e) => DateRenderer.hijriDateFormatterAr(e),
      sortable: false,
      filter: false,
      minWidth: 200,
   },
];

export const LicensesColumnsDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "رقم الرخصة",
      field: "licenseNo",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "عدد السنوات",
      field: "numberOfYears",
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "المصدر",
      field: "isSaudiLicense",
      cellRenderer: (e) => CellRenderers.saudiLicenseCheckArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "تاريخ الإنتهاء",
      field: "licenseExpiryDate",
      //cellRenderer: (e) => DateRenderer.hijriDateFormatterAr(e),
      sortable: false,
      filter: false,
      minWidth: 200,
   },
];
