import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
   selector: 'app-image-cell-renderer',
   templateUrl: './image-cell-renderer.component.html',
   styleUrls: ['./image-cell-renderer.component.scss']
})


export class ImageCellRendererComponent {

   private params: any;
   image;
   Vehicle;

   state = {
      logosUrl: environment.logosUrl
   };

   agInit(params: any): void {

      this.params = params;
      this.image = this.params?.data?.vehicleMakerLogo;

      if (!this.params?.data?.vehicleMakerNameAr) {
         this.Vehicle = this.params?.data?.vehicleMakerName + ' ' + this.params?.data?.vehicleModelName + ' ' + this.params?.data?.vehicleModelYear
      }
      else {
         this.Vehicle = this.params?.data?.vehicleMakerNameAr + ' ' + this.params?.data?.vehicleModelNameAr + ' ' + this.params?.data?.vehicleModelYear
      }

   }
   
}
