      <div class="modal-body">
         <div style="width: 100%;" class="btn-group btn-group-toggle" ngbRadioGroup
            name="radioBasic">
            <label style="width: 50%;" [class.active]="!(uiState.isEmail)" ngbButtonLabel class="btn-outline-primary">
               <input (click)="shareSMS()" ngbButton [(ngModel)]="uiState.isEmail"  type="radio" [value]="false">
               <fa-icon icon="sms"></fa-icon>
               {{ uiState.isAr ? 'رسالة نصية' : 'SMS' }}
            </label>
            <label style="width: 50%;" [class.active]="(uiState.isEmail)" ngbButtonLabel class="btn-outline-primary">
               <input (click)="shareEmail()" [(ngModel)]="uiState.isEmail"  ngbButton type="radio" [value]="true">
               <fa-icon icon="envelope"></fa-icon>
               {{ uiState.isAr ? 'البريد الإلكتروني' : 'E-mail' }}
            </label>
         </div>
         <div *ngIf="uiState.isEmail || uiState.isSMS">
         <div class="row share-form mt-3">
            <div *ngIf="uiState.isEmail" class="form-group col-6">
               <input 
               type="email" 
               class="form-control" 
               class="form-control"
               placeholder="{{ 'LABELS.Generic.Email' | translate }}"
               [(ngModel)]="state.form.email">
            </div>
            <div *ngIf="!(uiState.isEmail)" class="form-group col-6">
               <input 
               type="text" 
               style="text-align: left; direction: ltr;"
               class="form-control"
               maxlength="16"
               prefix="+966 "
               mask="000 000 000"
               class="form-control"
               placeholder="{{ 'LABELS.Generic.Phone' | translate }}"
               [(ngModel)]="state.form.phone">
            </div>
            <ng-select
                  [items]="state.messages"
                  class="col-6"
                  [allowClear]="true"

                  [placeholder]="'MISC.NotificationType' | translate"                  
                  (change)="setMessage($event)"
                  >
                  <ng-template class="form-control" ng-label-tmp let-item="item">
                     <span>{{ uiState.isAr ? item.titleAr : item.title }}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                     <span>{{ uiState.isAr ? item.titleAr : item.title }}</span>
                  </ng-template>
               </ng-select>
            </div>
            <div>
               <textarea
               [(ngModel)]="state.form.message.body"
               style="direction: rtl; height: 150px;"
               class="form-control"
               placeholder="{{ 'MISC.NotificationType' | translate }}"
               ></textarea>
            </div>
            
            </div>   
            <div class="mt-3 centered">
               <button [disabled]="(state.shareSource == 'email' && state.form.email == null) || (uiState.isLoading) || (state.shareSource == 'sms' && state.form.phone == null) || (state.form.message == null)" (click)="sendMessage()" class="btn btn-primary">
                  <span *ngIf="uiState.isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{ 'MISC.Send' | translate }}
               </button>
            </div>        
      </div>