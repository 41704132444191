<!-------------------------------------------- Filters ------------------------------------------------->
<div class="row d-flex justify-content-end p-2">
  <div class="col-lg-6">
    <div class="custom-card ">
      <div class="row d-flex justify-content-end">
        <div class="col-lg-2 icon-size" data-toggle="tooltip" data-placement="top" title="Advance Search">
          <i class="icon icon-search" (click)="toggleAccordionFilters()"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div [ngStyle]="{'max-height': (isAccordionToggledfilter) ? '220px' : '0px'}"
  style="transition: all .7s ease-in-out; overflow: hidden;">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="form-group">
          <div class="row">
            <label>Status Code</label>
            <div class="form-group col-sm-3">
              <select class="form-control" id="ccmonth" [(ngModel)]="filtersby.statusCode" required>
                <option [ngValue]=200>Success</option>
                <option [ngValue]=202>Accepted but not complete</option>
                <option [ngValue]=404>Not found</option>
                <option [ngValue]=400>Bad request</option>
              </select>
            </div>

            <label>Method</label>
            <div class="form-group col-sm-3">
              <select class="form-control" id="ccmonth" [(ngModel)]="filtersby.method" required>
                <option value="Post">Post</option>
                <option value="Get">Get</option>
              </select>

            </div>
            <div class="form-group col-sm-3">
              <button type="submit" (click)="filterQuery()" class="btn btn-format">
                <i class="fa fa-search"></i>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clients-container">


  <div *ngFor="let apiLog of apidata">
    <div class="accordion-box space">
      <!-- -------------------------- Accordion Header --------------------------- -->
      <div class="header">
        <!-- Accordion Title -->
        <div class="title flex-container">
          <div (click)="toggleAccordion(apiLog)">
            <span class="badge badge-pill" [ngClass]="{
                    'badge-success': apiLog.statusCode == '200',
                    'badge-warning': apiLog.statusCode == '202' ||  apiLog.statusCode == '700' ||  apiLog.statusCode == '701' ||  apiLog.statusCode == '702' ||  apiLog.statusCode == '721' ||  apiLog.statusCode == '722' ||  apiLog.statusCode == '723',
                    'badge-danger': apiLog.statusCode == '400' || apiLog.statusCode == '401' || apiLog.statusCode == '500',
                    'badge-info': apiLog.statusCode == '404' || apiLog.statusCode =='711' || apiLog.statusCode =='712'                        
                  }">
              {{ apiLog.statusCode }}
            </span>
            <span>{{ apiLog.method }} {{ apiLog.schema }} {{ apiLog.path }} {{ apiLog.host }}</span>
          </div>
        </div>
      </div>

      <!-- --------------------------- Accordion Body ---------------------------- -->
      <div class="content" [ngStyle]="{'max-height': (apiLog.isAccordionToggled) ? '2000px' : '0px'}">
        <div class="content-container payment-container ">
          <p>{{ apiLog.queryString }}</p>
          <p><b>Request Body</b></p>
          <div class="Schema">
          <ngx-json-viewer  [json]="apiLog?.requestBodyObj"></ngx-json-viewer> 
        </div>
          <p class="mt-3"><b>ResponseBody</b></p>
          <div class="Schema">
          <ngx-json-viewer [json]="apiLog?.responseBodyObj"></ngx-json-viewer> 
          </div>

          <p class="mt-3">Created By : {{ apiLog.createdBy }}</p>
          <p>Create Date : {{ apiLog.createdDate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="clients-footer container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-12">
        <select class="form-control" (change)="pageChanged(filtersby.pageSize, false)" [(ngModel)]="filtersby.pageSize">
          <option [ngValue]=5> {{ 'MISC.Tools.PageSelector.5PerPage' | translate }}</option>
          <option [ngValue]=10>{{ 'MISC.Tools.PageSelector.10PerPage' | translate }}</option>
          <option [ngValue]=20>{{ 'MISC.Tools.PageSelector.20PerPage' | translate }}</option>
          <option [ngValue]=30>{{ 'MISC.Tools.PageSelector.30PerPage' | translate }}</option>
          <option [ngValue]=40>{{ 'MISC.Tools.PageSelector.40PerPage' | translate }}</option>
          <option [ngValue]=50>{{ 'MISC.Tools.PageSelector.50PerPage' | translate }}</option>
          <option [ngValue]=100>{{ 'MISC.Tools.PageSelector.100PerPage' | translate }}</option>
        </select>
      </div>

      <div class="col-lg-10 col-md-2 col-sm-12  d-flex justify-content-end">
        <ngb-pagination [collectionSize]="totalRecord" [maxSize]="10" [pageSize]="filtersby.pageSize"
          [page]="filtersby.pageNumber" [boundaryLinks]="true" (pageChange)="pageChanged($event, true)">
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>

<div class="page-loader-wrapper loader-wrapper" *ngIf="isPageLoading">
  <div class="loader bg-white">
    <div class="whirly-loader page-loader"></div>
  </div>
</div>
