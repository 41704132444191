import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { ApiRoutes } from "../../models/app/ApiRoutes";
import { SmsLoggerFilters } from "../../models/smsLogger/smsLoggerFilters";
import { Observable } from "rxjs";
import { delayedRetry } from "../../helpers/utilities/DelayedRetry.rx";

@Injectable({
  providedIn: "root",
})
export class SmsLoggerService {
  private apiUrl = environment.apiUrl;

  constructor(public http: HttpClient) {}

  getSmsLoggerByFilters(filtersby: SmsLoggerFilters): Observable<any> {
    return this.http.post(this.apiUrl + ApiRoutes.logger.smslog, filtersby, {
      observe: "response",
    });

    /* let filters = {};
  Object.keys(filtersby).forEach(filterType => filters[filterType] = filtersby[filterType]);
 const httpParams = new HttpParams({ fromObject: filters });
  return this.http.get("http://localhost:3000/SMSLog/",{ params: httpParams }); */
  }

  getSmsData(filtersby: SmsLoggerFilters): Observable<any> {
    return this.http.post(this.apiUrl + ApiRoutes.logger.smslog, filtersby, {
      observe: "response",
    });
    // return this.http.get("http://localhost:3000/SMSLog");
  }
}
