<div class="page printable-page">
   <table class="comparison-table table">

      <!-- Products Headers (company + price + buy) -->
      <thead>
         <tr>
            <th id="transparent-header"></th>
            <th *ngFor="let product of state.comparisonSheetData?.comparisonProducts" id="company-header">
               <div class="company-header-container flex-column">
                  <span id="ic-name">{{ (uiState.appLang==='en') ? product.insuranceCarrier.name : product.insuranceCarrier.nameAr }}</span>
                  <div>
                     <span class="comparison-price primary-col">{{ product.selectedDeductible.totalPaidAmount | number:'1.2-2' }}</span>
                     <small class="currency-txt">{{ 'MISC.SAR' | translate }}</small>
                  </div>
               </div>
            </th>
         </tr>
      </thead>
   
      <!-- Products benefits -->
      <tbody>
         <tr *ngFor="let benefit of state.comparisonSheetData?.comparisonProductsBenefits; let i = index">
   
            <th class="comparison-benefit-title">
               {{ (uiState.appLang==='en') ? benefit.name : benefit.nameAr }}
            </th>
   
            <td *ngFor="let product of state.comparisonSheetData?.comparisonProducts;">
               <div *ngFor="let productBenefit of product.benefits; let j = index">
                  <div *ngIf="productBenefit.name === benefit.name">
   
                     <!-- Ticked check Icon for free benefits (without checkbox)  -->
                     <div *ngIf="(productBenefit.benefitTypeId === 2)">
                        <i class="comparison-check primary-col">&#10004;</i>
                     </div>
   
                     <!-- Checkbox for paid benefits -->
                     <div *ngIf="!(productBenefit.benefitTypeId === 2)">
                        <div class="benefit-cbx">
                           <input 
                           [checked]="productBenefit.benefitTypeId == 2 || productBenefit.benefitTypeId == 3 || productBenefit.isChecked"
                           [disabled]="productBenefit.benefitTypeId == 2 || productBenefit.benefitTypeId == 3" 
                           class="checkbox"
                           type="checkbox"
                           [id]="product.name + productBenefit.name + product.selectedDeductible.totalPaidAmount + i + j"
                           style="display: none;">
                           <label [for]="product.name + productBenefit.name + product.selectedDeductible.totalPaidAmount + i + j" class="noselect">
                              <span *ngIf="productBenefit.benefitTypeId !== 2" class="secondary-lime">
                                 {{ productBenefit.benefitAmount | number:'1.2-2' }}
                              </span>
                              <small *ngIf="productBenefit.benefitTypeId !== 2" class="currency-txt">{{ 'MISC.SAR' | translate }}</small>
                           </label>
                        </div>
                     </div>
   
                  </div>
               </div>
            </td>
   
         </tr>
      </tbody>
   
   </table>
</div>
