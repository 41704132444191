import { Routes } from "@angular/router";
import { AppRoutes } from "../models/app/AppRoutes";
import { AuthGuard } from "src/app/shared/helpers/guards/auth.guard";

export const content: Routes = [
  {
    path: AppRoutes.home.main,
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: AppRoutes.Clients.main,
    loadChildren: () =>
      import("../../clients/clients.module").then((m) => m.ClientsModule),
  },
  {
    path: AppRoutes.Quotations.main,
    loadChildren: () =>
      import("../../quotations/quotations.module").then(
        (m) => m.QuotationsModule
      ),
  },
  {
    path: AppRoutes.Insurance.main,
    loadChildren: () =>
      import("../../insurance/insurance.module").then((m) => m.InsuranceModule),
  },
  {
    path: AppRoutes.DraftPolicies.main,
    loadChildren: () =>
      import("../../draft-policies/draft-policies.module").then(
        (m) => m.DraftPoliciesModule
      ),
  },
  {
    path: AppRoutes.Policies.main,
    loadChildren: () =>
      import("../../policies/policies.module").then((m) => m.PoliciesModule),
  },
  {
    path: AppRoutes.PendingCancellationPolicies.details.pending,
    loadChildren: () =>
      import("../../pending-cancellation/pending-cancellation.module").then(
        (m) => m.PendingCancellationModule
      ),
  },
  {
    path: AppRoutes.CancelledPolicies.main,
    loadChildren: () =>
      import("../../cancelled-policies/cancelled-policies.module").then(
        (m) => m.CancelledPoliciesModule
      ),
  },
  {
    path: AppRoutes.InsuranceAccounts.main,
    loadChildren: () =>
      import("../../insurance-accounts/insurance-account.module").then(
        (m) => m.InsuranceAccountModule
      ),
  },
  {
    path: AppRoutes.Analytics.main,
    loadChildren: () =>
      import("../../analytics/analytics.module").then((m) => m.AnalyticsModule),
  },
  {
    path: AppRoutes.Users.main,
    loadChildren: () =>
      import("../../users/users.module").then((m) => m.UsersModule),
  },
  {
    path: AppRoutes.Reports.main,
    loadChildren: () =>
      import("../../reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: AppRoutes.App.Error.main,
    loadChildren: () =>
      import("../pages/error/error.module").then((m) => m.ErrorModule),
  },
];
