import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { InsuranceStepsData } from "../../models/insurance/InsuranceStepsData";
import { LocallyStoredItemsKeys } from "../../models/app/LocallyStoredItemsKeys";
import { Client } from "../../models/clients/Client";
import { Vehicle } from "../../models/vehicle/Vehicle";
import { VehicleForm } from "../../models/vehicle/VehicleForm";
import { AdditionalDriverForm } from "../../models/insurance/AdditionalDriverForm";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ApiRoutes } from "../../models/app/ApiRoutes";
import { QuotationRequest } from "../../payloads/requests/quotation/QuotationRequest";
import { NgFormsManager } from "@ngneat/forms-manager";
import {
  ManagedForms,
  ManagedFormsTypes,
} from "../../models/app/ManagedFormsTypes";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { Driver } from "../../payloads/requests/quotation/Driver";
import AppUtils from "../../helpers/utilities/AppUtils";
import { QuotesListResponse } from "../../payloads/response/quotes/QuotesListResponse";
import { tap } from "rxjs/operators";
import { QuoteService } from "../quote/quote.service";
import { InsuranceRequestDetailsForm } from "../../models/insurance/InsuranceRequestDetailsForm";
import { QuotationVehicle } from "../../payloads/requests/quotation/QuotationVehicle";
import { Quote } from "../../models/quote/Quote";
import { VehicleSummary } from "../../models/vehicle/VehicleSummary";
import { InsuranceOfferPreview } from "../../models/insurance/InsuranceOfferPreview";
import { QuotationSessionData } from "../../models/insurance/QuotationSessionData";
import { Policy } from "../../models/insurance/PolicyInquiryRes";
import { DriverLicense } from "../../payloads/requests/quotation/DriverLicense";
import { RequoteRequest } from "../../payloads/requests/quotation/RequoteRequest";

@Injectable({
  providedIn: "root",
})
export class InsuranceService {
  //#region Vars

  apiUrl = environment.apiUrl;

  static injector: Injector; // Allows the service to be injected into static classes (utils)

  insuranceStepsData: BehaviorSubject<InsuranceStepsData> = new BehaviorSubject(
    {
      insuranceStepNumber: 1,
      client: {} as Client,
      vehicle: {} as Vehicle | VehicleForm,
      vehicleSummary: {} as VehicleSummary,
      policy: {} as Policy,
      policyNumber: "",
      requestDetails: {} as InsuranceRequestDetailsForm,
      additionalDrivers: [] as AdditionalDriverForm[],
      driverLicenses: [] as DriverLicense[],
      drivingPercentages: [],
      insurancePreview: {} as InsuranceOfferPreview,
      selectedQuote: {} as Quote,
      clientQuotationId: undefined as string,
    } as InsuranceStepsData
  );

  quotationSessionData: BehaviorSubject<QuotationSessionData> = new BehaviorSubject(
    {
      clientId: undefined,
      clientVehicleId: undefined,
    }
  );

  isSkippingWizardDraft: BehaviorSubject<boolean> = new BehaviorSubject(false); // Indicates whether the user is going to skip the draft step of the insurance wizard

  //#endregion

  constructor(
    private http: HttpClient,
    private formsManager: NgFormsManager<ManagedFormsTypes>,
    private parserFormatter: NgbDateParserFormatter,
    private quoteService: QuoteService
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                                API Requests                                */
  /* -------------------------------------------------------------------------- */
  //#region

  /**
   * Fetches the quotes list by sending the insurance quotation request
   *
   * @param {InsuranceStepsData} insuranceStepsData
   * @returns {Observable<QuotesListResponse>}
   * @memberof InsuranceService
   */
  getQuotes(
    insuranceStepsData: InsuranceStepsData
  ): Observable<QuotesListResponse> {
    return this.http
      .post<QuotesListResponse>(
        this.apiUrl + ApiRoutes.insurance.getQuotesAggr,
        this.constructQuotationRequest(insuranceStepsData)
      )
      .pipe(
        tap((res: QuotesListResponse) => {
          if (res.isSuccess) {
            // Save the response on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.UserQuoteResponse,
              JSON.stringify(res)
            );
            // Store the retreived quotes on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.Quotes,
              JSON.stringify(res.quotes)
            );
            this.quoteService.setQuotes(res.quotes);
          }
        })
      );
  }

  renewPolicy(info): Observable<QuotesListResponse> {
    return this.http
      .post<QuotesListResponse>(
        this.apiUrl + ApiRoutes.insurance.getQuotesAggr,
        info
      )
      .pipe(
        tap((res: QuotesListResponse) => {
          if (res.isSuccess) {
            // Save the response on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.UserQuoteResponse,
              JSON.stringify(res)
            );
            // Store the retreived quotes on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.Quotes,
              JSON.stringify(res.quotes)
            );
            this.quoteService.setQuotes(res.quotes);
          }
        })
      );
  }

  // regenerate Quotes
  regenerateQuotes(request: RequoteRequest): Observable<QuotesListResponse> {
    return this.http
      .post<QuotesListResponse>(
        this.apiUrl + ApiRoutes.insurance.regenerateQuotes,
        request
      )
      .pipe(
        tap((res: QuotesListResponse) => {
          if (res.isSuccess) {
            // Save the response on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.UserQuoteResponse,
              JSON.stringify(res)
            );
            // Store the retreived quotes on local storage
            localStorage.setItem(
              LocallyStoredItemsKeys.Quotes,
              JSON.stringify(res.quotes)
            );
            this.quoteService.setQuotes(res.quotes);
          }
        })
      );
  }

  renewalInquiry(clientId, clientVehicleId) {
    return this.http.post(this.apiUrl + ApiRoutes.insurance.renewalInquiry, {
      clientId,
      clientVehicleId,
    });
  }

  /**
   * Sends a request to fetch the quotes list using the stored insurance steps data
   *
   * @returns {Observable<QuotesListResponse>}
   * @memberof InsuranceService
   */
  refetchQuotes(): Observable<QuotesListResponse> {
    return this.http.post<QuotesListResponse>(
      this.apiUrl + ApiRoutes.insurance.getQuotesAggr,
      null
    );
  }

  /**
   * Constructs and conditions the insurance quotation request from the insurance steps data
   *
   * @param {InsuranceStepsData} insuranceStepsData The insurance steps data that were acquired through the insurance steps
   * @returns {QuotationRequest}
   * @memberof InsuranceService
   */

  constructQuotationRequest(
    insuranceStepsData: InsuranceStepsData
  ): QuotationRequest {
    // Get main driver form
    const requestDetails = this.formsManager.getControl(
      ManagedForms.InsuranceRequestDetailsForm
    ).value;
    let quotationRequest: QuotationRequest = {} as QuotationRequest;

    // set additional drivers if exist on renewal
    let additionalDrivers = this.getInsuranceStepsData().subscribe(
      (insuranceData) => {
        insuranceData.policy.drivers;
      }
    );

    // Construct quotation request
    quotationRequest.clientId = insuranceStepsData.client.id;
    if (!(typeof quotationRequest.policyEffectiveDate === "string"))
      quotationRequest.policyEffectiveDate = this.parserFormatter.format(
        requestDetails.policyEffectiveDate
      );
    quotationRequest.drivers = this.constructDrivers(
      insuranceStepsData.additionalDrivers
    );
    quotationRequest.action = "New";
    quotationRequest.newVehicleInfo = AppUtils.isVehicleFormObject(
      insuranceStepsData.vehicle
    )
      ? this.constructVehicleData(insuranceStepsData)
      : null;
    quotationRequest.selectedVehicleId = (<Vehicle>(
      insuranceStepsData.vehicle
    )).id;
    quotationRequest.vehicleRepairMethodId =
      requestDetails.vehicleRepairMethodId;
    quotationRequest.insuranceCompanyId = requestDetails.insuranceCompanyId;
    if (requestDetails.drivingRegionId)
      quotationRequest.drivingRegionId = parseInt(
        requestDetails.drivingRegionId.toString()
      );
    quotationRequest.isRenewal = requestDetails.isRenewal;
    quotationRequest.policyNumber =
      Object.keys(insuranceStepsData.policy).length === 0
        ? insuranceStepsData.policyNumber
        : insuranceStepsData.policy.policyNumber;
    quotationRequest.phoneNumber = requestDetails.phoneNumber;
    quotationRequest.email = requestDetails.email;
    quotationRequest.vehicleSumInsured = parseInt(
      requestDetails.vehicleSumInsured.toString()
    );

    quotationRequest.currentLeasingYear = requestDetails.currentLeasingYear;

    return quotationRequest;
  }

  /**
   * Constructs the quotation request drivers list from the additional drivers
   *
   * @param {AdditionalDriverForm[]} additionalDrivers
   * @returns {Driver[]}
   * @memberof InsuranceService
   */
  constructDrivers(additionalDrivers: AdditionalDriverForm[]): Driver[] {
    let drivers: Driver[] = [];

    // Add additional drivers to list
    additionalDrivers.forEach((additionalDriver) => {
      // Delete unneeded fields
      delete additionalDriver.driverPercentageId;

      // Convert AdditionalDriver to Driver
      let driver: Driver = {} as Driver;

      // Copy params of AdditionalDriver to Driver
      Object.keys(additionalDriver).forEach((additionalDriverParam) => {
        driver[additionalDriverParam] = additionalDriver[additionalDriverParam];
      });

      // Set the driver's type as additional
      driver.typeId = 2;

      // Add the driver to the list
      drivers.push(driver);
    });

    // Reconstruct drivers fields and data
    drivers.forEach((driver) => {
      // Reconstruct the driver medical conditions
      if (Array.isArray(driver.medicalConditions)) {
        driver.medicalConditions = driver.medicalConditions.join(",");
      }

      // Reconstruct the driver violations
      if (Array.isArray(driver.driverViolations)) {
        driver.driverViolations = driver.driverViolations.join(",");
      }

      // Remove unneeded variables
      driver.driverLicenses.forEach((license) => {
        delete license.countryStr;
        delete license.licenseTypeStr;
      });
    });

    return drivers;
  }

  /**
   * Constructs the quotation request's vehicle data
   *
   * @param {InsuranceStepsData} insuranceStepsData
   * @returns {*}
   * @memberof InsuranceService
   */
  constructVehicleData(
    insuranceStepsData: InsuranceStepsData
  ): QuotationVehicle {
    let vehicle: QuotationVehicle = {} as QuotationVehicle;

    if (AppUtils.isVehicleFormObject(insuranceStepsData.vehicle)) {
      // Cast the vehicle into VehicleForm
      const vehicleForm = <VehicleForm>insuranceStepsData.vehicle;

      vehicle = {
        isVehicleOwnerTransfer: false,
        vehicleIdTypeId: vehicleForm.vehicleIdTypeId,
        vehicleId: vehicleForm.vehicleId,
        vehiclePlateNumber: vehicleForm.vehiclePlateNumber,
        vehiclePlateFirstLetterId: vehicleForm.vehiclePlateFirstLetterId,
        vehiclePlateSecondLetterId: vehicleForm.vehiclePlateSecondLetterId,
        vehiclePlateThirdLetterId: vehicleForm.vehiclePlateThirdLetterId,
        vehicleChassisNumber: vehicleForm.vehicleChassisNumber,
        vehicleOwnerName: vehicleForm.vehicleOwnerName,
        vehicleOwnerIdentityNumber: vehicleForm.vehicleOwnerIdentityNumber,
        vehiclePlateTypeId: vehicleForm.vehiclePlateTypeId,
        vehicleModelYear: vehicleForm.vehicleModelYear,
        vehicleMakerId: vehicleForm.vehicleMakerId,
        vehicleModelId: vehicleForm.vehicleModelId,
        vehicleMajorColorId: vehicleForm.vehicleMajorColorId,
        vehicleBodyTypeId: vehicleForm.vehicleBodyTypeId,
        vehicleRegistrationCityId: vehicleForm.vehicleRegistrationCityId,
        vehicleRegistrationExpiryDate: !(
          typeof vehicleForm.vehicleRegistrationExpiryDate === "string"
        )
          ? this.parserFormatter.format(
              vehicleForm.vehicleRegistrationExpiryDate
            ) || null
          : null,
        vehicleCylinders: vehicleForm.vehicleCylinders,
        vehicleWeight: vehicleForm.vehicleWeight,
        vehicleCapacity: vehicleForm.vehicleCapacity,
        vehicleSumInsured: vehicleForm.vehicleSumInsured,
        vehicleEngineNo: vehicleForm.vehicleEngineNo,
        vehicleEngineCapacity: vehicleForm.vehicleEngineCapacity
          ? vehicleForm.vehicleEngineCapacity.toString()
          : null,
        vehicleEngineSizeId: vehicleForm.vehicleEngineSizeId,
        vehicleUseId: vehicleForm.vehicleUseId,
        vehicleCurrentMileage: vehicleForm.vehicleCurrentMileage,
        vehicleTransmissionTypeId: vehicleForm.vehicleTransmissionTypeId,
        vehicleMileageExpectedAnnualId:
          vehicleForm.vehicleMileageExpectedAnnualId,
        vehicleAxleWeightId: vehicleForm.vehicleAxleWeightId,
        vehicleParkingLocationId: vehicleForm.vehicleParkingLocationId,
        isVehicleModified: vehicleForm.isVehicleModified,
        vehicleModificationDetails: vehicleForm.vehicleModificationDetails,
        vehicleSpecifications: vehicleForm.vehicleSpecifications,
        leasingContractNoOfYears: vehicleForm.leasingContractNoOfYears,
      };
    } else {
      // Cast the vehicle into Vehicle
      const vehicleData = <Vehicle>insuranceStepsData.vehicle;

      vehicle = {
        isVehicleOwnerTransfer: false,
        vehicleIdTypeId: null,
        vehicleId: vehicleData.vehicleId,
        vehiclePlateNumber: vehicleData.vehiclePlateNumber,
        vehiclePlateFirstLetterId: null,
        vehiclePlateSecondLetterId: null,
        vehiclePlateThirdLetterId: null,
        vehicleChassisNumber: vehicleData.vehicleChassisNumber,
        vehicleOwnerName: vehicleData.vehicleOwnerName,
        vehicleOwnerIdentityNumber: vehicleData.vehicleOwnerIdentityNumber,
        vehiclePlateTypeId: null,
        vehicleModelYear: vehicleData.vehicleModelYear,
        vehicleMakerId: null,
        vehicleModelId: null,
        vehicleMajorColorId: null,
        vehicleBodyTypeId: null,
        vehicleRegistrationCityId: null,
        vehicleRegistrationExpiryDate:
          vehicleData.vehicleRegistrationExpiryDate,
        vehicleCylinders: vehicleData.vehicleCylinders,
        vehicleWeight: vehicleData.vehicleWeight,
        vehicleCapacity: vehicleData.vehicleCapacity,
        vehicleSumInsured: vehicleData.vehicleSumInsured,
        vehicleEngineNo: null,
        vehicleEngineCapacity: null,
        vehicleEngineSizeId: null,
        vehicleUseId: null,
        vehicleCurrentMileage: null,
        vehicleTransmissionTypeId: null,
        vehicleMileageExpectedAnnualId: null,
        vehicleAxleWeightId: null,
        vehicleParkingLocationId: null,
        isVehicleModified: vehicleData.isVehicleModified,
        vehicleModificationDetails: vehicleData.vehicleModificationDetails,
        vehicleSpecifications: vehicleData.vehicleSpecifications,
        leasingContractNoOfYears: vehicleData.leasingContractNoOfYears,
      };
    }

    // Reconstruct the vehicle specifications as a string of IDs
    if (Array.isArray(vehicle.vehicleSpecifications)) {
      vehicle.vehicleSpecifications = vehicle.vehicleSpecifications
        .map((spec) => spec.id)
        .join(",");
    }

    return vehicle;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                             Getters and Setters                            */
  /* -------------------------------------------------------------------------- */
  //#region

  getInsuranceStepsData(): BehaviorSubject<InsuranceStepsData> {
    return this.insuranceStepsData;
  }

  setInsuranceStepsData(insuranceStepsData: InsuranceStepsData): void {
    this.insuranceStepsData.next(insuranceStepsData);
  }

  storeInsuranceStepsData(insuranceStepsData: InsuranceStepsData): void {
    localStorage.setItem(
      LocallyStoredItemsKeys.InsuranceStepsData,
      JSON.stringify(insuranceStepsData)
    );
  }

  storeExistingInsuranceStepsData(): void {
    localStorage.setItem(
      LocallyStoredItemsKeys.InsuranceStepsData,
      JSON.stringify(this.insuranceStepsData.value)
    );
  }

  getStoredInsuranceStepsData(): InsuranceStepsData {
    return JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.InsuranceStepsData)
    );
  }

  clearInsuranceStepsData(): void {
    // Clear from local storage
    localStorage.removeItem(LocallyStoredItemsKeys.InsuranceStepsData);
    // Clear from memory
    this.setInsuranceStepsData({
      insuranceStepNumber: 1,
      client: {} as Client,
      vehicle: {} as Vehicle | VehicleForm,
      vehicleSummary: {} as VehicleSummary,
      requestDetails: {} as InsuranceRequestDetailsForm,
      additionalDrivers: [] as AdditionalDriverForm[],
      drivingPercentages: [],
      insurancePreview: {} as InsuranceOfferPreview,
      selectedQuote: {} as Quote,
      policy: {} as Policy,
    } as InsuranceStepsData);
  }

  setDriverLicenses(licenses: DriverLicense[]) {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.driverLicenses = licenses;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setStepNumber(stepNumber: number) {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.insuranceStepNumber = stepNumber;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setSessionClientId(clientId: string): void {
    let currentSessionData = this.quotationSessionData.value;
    currentSessionData.clientId = clientId;
    this.quotationSessionData.next(currentSessionData);
  }

  setSessionVehicleId(clientVehicleId: string): void {
    let currentSessionData = this.quotationSessionData.value;
    currentSessionData.clientVehicleId = clientVehicleId;
    this.quotationSessionData.next(currentSessionData);
  }

  getQuotationSessionData(): BehaviorSubject<QuotationSessionData> {
    return this.quotationSessionData;
  }

  setIsSkippingWizardDraft(isSkipping: boolean): void {
    this.isSkippingWizardDraft.next(isSkipping);
  }

  getIsSkippingWizardDraft(): BehaviorSubject<boolean> {
    return this.isSkippingWizardDraft;
  }

  setSelectedClient(client: Client): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.client = client;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setSelectedVehicle(vehicle: Vehicle | VehicleForm): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.vehicle = vehicle;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setSelectedPolicy(policy: Policy): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.policy = policy;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  addPolicyNumber(policyNumber: string): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.policyNumber = policyNumber;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  clearSelectedVehicle(): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.vehicle = {} as Vehicle | VehicleForm;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setSelectedVehicleSummary(vehicleSummary: VehicleSummary): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.vehicleSummary = vehicleSummary;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setDrivingPercentages(drivingPercentages: any[]): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.drivingPercentages = drivingPercentages;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  addAdditionalDriver(driver: AdditionalDriverForm): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.additionalDrivers.push(driver);
    this.insuranceStepsData.next(insuranceStepsData);
  }

  removeAdditionalDriver(driverToRemove: AdditionalDriverForm): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.additionalDrivers = insuranceStepsData.additionalDrivers.filter(
      (driver) => driver !== driverToRemove
    );
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setInsuranceOfferPreviewData(insurancePreview: InsuranceOfferPreview): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.insurancePreview = insurancePreview;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  setSelectedQuote(quote: Quote): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.selectedQuote = quote;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  storeSelectedQuote(quote: Quote): void {
    localStorage.setItem(
      LocallyStoredItemsKeys.SelectedQuote,
      JSON.stringify(quote)
    );
  }

  getStoredSelectedQuote(): Quote {
    return JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.SelectedQuote)
    );
  }

  setClientQuoteId(clientQuoteId: string): void {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.clientQuotationId = clientQuoteId;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  incrementStepNumber() {
    let insuranceStepsData = this.insuranceStepsData.value;
    console.log("step number: " + insuranceStepsData.insuranceStepNumber);

    insuranceStepsData.insuranceStepNumber++;
    console.log(
      "incrementStepNumber: ",
      insuranceStepsData.insuranceStepNumber
    );

    this.insuranceStepsData.next(insuranceStepsData);
  }

  decrementStepNumber() {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.insuranceStepNumber--;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  resetStepNumber() {
    let insuranceStepsData = this.insuranceStepsData.value;
    insuranceStepsData.insuranceStepNumber = 1;
    this.insuranceStepsData.next(insuranceStepsData);
  }

  //#endregion
}
