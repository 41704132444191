/* eslint-disable @typescript-eslint/naming-convention */
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CellRenderers } from "./CellRenderers";
import { DateRenderer } from "./DateRenderer";
import { GridColumn } from "./GridColumn";

export const InsuranceAccountColumnDefsEn: GridColumn[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "Leasing Year",
    field: "leasingYear",
    cellRenderer: (e) => CellRenderers.leasingYearValueArRenderEn(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Policy Number",
    field: "policyNumber",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Policy Issue Date",
    field: "policyIssueDate",
    cellRenderer: DateRenderer.dateFormatterOfTypeDateEn,
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Vehicle",
    field: "vehicleSummaryAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "vehicle Registration Type",
    field: "vehicleIdTypeEn",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: " Chassis No.",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "Policy Number",
    field: "policyNumber",
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "Actual Premium Before Discounts (Incl. VAT)",
    field: "actualPremiumBFDIncVat",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    minWidth: 290,
  },
  {
    headerName: "Premium Amount After Discounts (Incl. VAT)",
    field: "premuimAmountAFDIncVat",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: true,
    minWidth: 290,
  },

  {
    headerName: "Total Discounts",
    field: "policyDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: true,
    minWidth: 270,
  },
  {
    headerName: "Balance",
    field: "balance",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    minWidth: 140,
  },
];

export const InsuranceAccountColumnDefsAr = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "السنة التمويلية",
    field: "leasingYear",
    cellRenderer: (e) => CellRenderers.leasingYearValueRendererAr(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "رقم الوثيقة",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "تاريخ إصدار الوثيقة",
    field: "policyIssueDate",
    cellRenderer: DateRenderer.dateFormatterOfTypeDateAr,
    sortable: false,
    filter: false,
    minWidth: 190,
  },
  {
    headerName: "المركبة",
    field: "vehicleSummaryAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: " نوع تسجيل المركبة",
    field: "vehicleIdTypeAr",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
  },
  {
    headerName: "رقم الهيكل",
    field: "vehicleChassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "رقم الوثيقة",
    field: "policyNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "القسط الاساسي قبل الخصم (شامل الضريبة)",
    field: "actualPremiumBFDIncVat",
    cellRenderer: (e) => CellRenderers.moneyValueArRenderer(e),
    sortable: false,
    minWidth: 300,
  },
  {
    headerName: "القسط الاساسي بعد الخصم (شامل الضريبة)",
    field: "premuimAmountAFDIncVat",
    cellRenderer: (e) => CellRenderers.moneyValueArRenderer(e),
    sortable: false,
    minWidth: 300,
  },
  {
    headerName: "إجمالي الخصومات",
    field: "policyDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: true,
    minWidth: 270,
  },
  {
    headerName: "الرصيد",
    field: "balance",
    cellRenderer: (e) => CellRenderers.moneyValueArRenderer(e),
    sortable: false,
    minWidth: 140,
  },
];

/* Shadow Accounts for all users */
export const shadowAccountColumnDefsEn: ColDef[] = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "Identity Number",
    field: "identityNumber",
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "Leesee Name",
    field: "clientNameEn",
    sortable: false,
    filter: false,
    minWidth: 250,
    resizable: true,
  },
  {
    headerName: "Vehicle ID",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 190,
    resizable: true,
  },
  {
    headerName: "Vehicle",
    field: "vehicleMakeModel",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
    resizable: true,
  },
  {
    headerName: "Chassis Number",
    field: "chassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  //   {
  //     headerName: "Policy Number",
  //     field: "policyNumber",
  //     cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  //     sortable: false,
  //     filter: false,
  //     minWidth: 200,
  //     resizable: true,
  //   },
  {
    headerName: "Leasing Contract Period",
    field: "leasingContractNoOfYears",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(1st) Policy Number",
    field: "policyNumberFirstYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(1st) Prem.Befor Discounts",
    field: "firstYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(1st) Prem.After Discounts",
    field: "firstYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(1st) Total Discounts",
    field: "firstYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "(2nd) Policy Number",
    field: "policyNumberSecondYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(2nd) Prem.Befor Discounts",
    field: "secondYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(2nd) Prem.After Discounts",
    field: "secondYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(2nd) Total Discounts",
    field: "secondYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(3rd) Policy Number",
    field: "policyNumberThirdYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(3rd) Prem.Befor Discounts",
    field: "thirdYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(3rd) Prem.After Discounts",
    field: "thirdYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(3rd) Total Discounts",
    field: "thirdYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(4th) Policy Number",
    field: "policyNumberForthYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(4th) Prem.Befor Discounts",
    field: "forthYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(4th) Prem.After Discounts",
    field: "forthYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(4th) Total Discounts",
    field: "forthYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(5th) Policy Number",
    field: "policyNumberFifthYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(5th) Prem.Befor Discounts",
    field: "fifthYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(5th) Prem.After Discounts",
    field: "fifthYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "(5th) Total Discounts",
    field: "fifthYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "Total Balance",
    field: "totalBalace",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
];

export const shadowAccountColumnDefsAr = [
  {
    headerName: "#",
    valueGetter: (e) => CellRenderers.indexCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 70,
  },
  {
    headerName: "رقم الهوية",
    field: "identityNumber",
    // cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "اسم العميل",
    field: "clientNameAr",
    sortable: false,
    filter: false,
    minWidth: 250,
    resizable: true,
  },
  {
    headerName: "رقم المركبة",
    field: "vehicleId",
    sortable: false,
    filter: false,
    minWidth: 190,
    resizable: true,
  },
  {
    headerName: "المركبة",
    field: "vehicleMakeModel",
    // cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 250,
    resizable: true,
  },
  {
    headerName: "رقم الهيكل",
    field: "chassisNumber",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  //   {
  //     headerName: "رقم الوثيقة",
  //     field: "policyNumber",
  //     cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  //     sortable: false,
  //     filter: false,
  //     minWidth: 150,
  //     resizable: true,
  //   },
  {
    headerName: "مدة عقد التأجير التمويلى",
    field: "leasingContractNoOfYears",
    // cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
    resizable: true,
  },
  {
    headerName: "رقم الوثيقة للسنة الاولى",
    field: "policyNumberFirstYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الأولى قبل الخصم",
    field: "firstYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الأولى بعد الخصم",
    field: "firstYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي خصومات قسط السنة الأولى",
    field: "firstYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "رقم الوثيقة للسنة الثانية",
    field: "policyNumberSecondYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الثانية قبل الخصم",
    field: "secondYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الثانية بعد الخصم",
    field: "secondYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي خصومات قسط السنة الثانية",
    field: "secondYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "رقم الوثيقة للسنة الثالثة",
    field: "policyNumberThirdYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الثالثة قبل الخصم",
    field: "thirdYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الثالثة بعد الخصم",
    field: "thirdYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي خصومات قسط السنة الثالثة",
    field: "thirdYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "رقم الوثيقة للسنة الرابعة",
    field: "policyNumberForthYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الرابعة قبل الخصم",
    field: "forthYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الرابعة بعد الخصم",
    field: "forthYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي خصومات قسط السنة الرابعة",
    field: "forthYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "رقم الوثيقة للسنة الخامسة",
    field: "policyNumberFifthYear",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الخامسة قبل الخصم",
    field: "fifthYearPremiumBeforDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "قسط السنة الخامسة بعد الخصم",
    field: "fifthYearPremiumAfterDiscount",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي خصومات قسط السنة الخامسة",
    field: "fifthYearTotalDiscounts",
    cellRenderer: (e) => CellRenderers.moneyValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: "إجمالي الرصيد",
    field: "totalBalace",
    cellRenderer: (e) => CellRenderers.moneyValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 150,
    resizable: true,
  },
];
