import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiLoggerService } from "../../shared/services/api-logger/api-logger.service";
import { ApiRequest } from "../../shared/models/apiLogger/ApiRequest"
import { Filters } from "../../shared/models/apiLogger/Filters"


@Component({
   selector: "app-api-logger",
   templateUrl: "./api-logger.component.html",
   styleUrls: ["./api-logger.component.scss"],
})


export class ApiLoggerComponent implements OnInit {
   /* Interface */
   apidata: ApiRequest[] = [];
   filtersby: Filters = {
      pageNumber: 1,
      pageSize: 50
   };

   /* modelFooter: NgbDateStruct; */
   isAccordionToggledfilter: boolean = false;
   isPageLoading: boolean = false;
   totalRecord: number;

   constructor(public dataService: ApiLoggerService, private route: Router) { }

   ngOnInit(): void {
      this.getApiLogger();
   }

   toggleAccordionFilters(): void {
      if (!this.isAccordionToggledfilter) this.isAccordionToggledfilter = false

      this.isAccordionToggledfilter = !this.isAccordionToggledfilter;
   }

   toggleAccordion(user: ApiRequest): void {
      if (!user.isAccordionToggled) user.isAccordionToggled = false

      user.isAccordionToggled = !user.isAccordionToggled;
   }
   filtersFormGroup: FormGroup;
   filters = new FormControl();

   public filterQuery() {
      this.dataService
         .getApiLoggerSearchByStatusCode(this.filtersby)
         .subscribe((apiloggerResponse: any) => {
            this.apidata = apiloggerResponse.data;
         });
   }

   public beforeChange(e) { }

   pageChanged(selectedPage, isPageNumberChanged: boolean) {
      isPageNumberChanged ? this.filtersby.pageNumber = selectedPage :
         this.filtersby.pageSize = selectedPage;
      this.filterQuery();
   }

   getApiLogger() {

      this.isPageLoading = true;
      this.dataService.getApiLoggerData().subscribe((apiloggerResponse: any) => {
         this.apidata = apiloggerResponse.data;
         this.totalRecord = apiloggerResponse.totalRecord;
         this.isPageLoading = false;
         this.apidata.forEach(apiLog => {
            apiLog.requestBodyObj = apiLog.requestBody ? JSON.parse(apiLog.requestBody) : {};
            apiLog.responseBodyObj = apiLog.responseBody ? JSON.parse(apiLog.responseBody) : {};
         });
      }), (err) => {
         console.log(err);
      }, () => {

         console.log(this.apidata);
      }
   }


   cleanString(str) {
      str = str.replace('"[', '[');
      str = str.replace(']"', ']');
      return str;
   }
}
