import { CellRenderers } from "./CellRenderers";
import { DateRenderer } from "./DateRenderer";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";

export const SmsLoggerColsEn = [
  {
    headerName: "#",
    valueGetter: (e) => {
      return e.node.rowIndex + 1;
    },
    width: 70,
  },
  {
    headerName: "Status",
    field: "status",
    cellRenderer: (e) => CellRenderers.isSmsSendRendererEn(e),
    width: 120,
    cellStyle: StatusBadgeRenderer.smsStatusBadgeEn,
  },
  {
    headerName: "SMS To",
    field: "smsTo",
    width: 150,
  },
  {
    headerName: "SMS Message",
    field: "smsMessage",
    width: 600,
  },
  {
    headerName: "Sending Date",
    field: "createdDate",
    width: 200,
  },
];

export const SmsLoggerColsAr = [
  {
    headerName: "#",
    valueGetter: (e) => {
      return e.node.rowIndex + 1;
    },
    width: 70,
  },
  {
    headerName: "الحالة",
    field: "status",
    cellRenderer: (e) => CellRenderers.isSmsSendRendererAr(e),
    width: 120,
    cellStyle: StatusBadgeRenderer.smsStatusBadgeAr,
  },
  {
    headerName: "المرسل إليه",
    field: "smsTo",
    width: 150,
  },
  {
    headerName: "محتوى الرسالة",
    field: "smsMessage",
    width: 600,
  },
  {
    headerName: "تاريخ الإرسال",
    field: "createdDate",
    width: 200,
  },
];
