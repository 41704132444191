import AppUtils from "../../helpers/utilities/AppUtils";
import { CellRenderers } from "./CellRenderers";
import { DateRenderer } from "./DateRenderer";
import { GridColumn } from "./GridColumn";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";

export const QuotationsColumnDefsEn = [
  {
    headerName: "#",
    valueGetter: (e) => {
      return e.node.rowIndex + 1;
    },
    minWidth: 70,
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 130,
    cellStyle: StatusBadgeRenderer.quotationStatusBadgeEn,
  },
  {
    headerName: "Reference No.",
    field: "quoteReferenceId",
    minWidth: 130,
  },
  {
    headerName: "Lessee ID",
    field: "insuredIdentityNumber",
    minWidth: 120,
  },
  {
    headerName: "Lessee Name",
    field: "fullName",
    minWidth: 220,
  },
  {
    headerName: "Birth Date",
    field: "insuredBirthDate",
    minWidth: 110,
  },
  {
    headerName: "NCD Eligibility",
    field: "ncdFreeYearName",
    cellRenderer: (e) => {
      if (!e.value) {
        return "-";
      } else return e.value;
    },
    minWidth: 200,
  },
  {
    headerName: "Vehicle",
    field: "vehicleSummary",
    cellRenderer: "ImageCell",
    tooltip: () => {
      return "Click to display vehicle details";
    },
    minWidth: 230,
  },
  {
    headerName: "Vehicle Color",
    field: "vehicleMajorColorNameAr",
    minWidth: 120,
  },
  {
    headerName: "Vehicle Sum Insured",
    field: "vehicleSumInsured",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    minWidth: 170,
  },
  {
    headerName: "Repair Method",
    field: "vehicleRepairMethodName",
    minWidth: 170,
  },
  {
    headerName: "Vehicle Chassis Number",
    field: "vehicleChassisNumber",
    minWidth: 200,
  },
  {
    headerName: "Deductible Amount",
    field: "deductibleAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 200,
  },
  {
    headerName: "AlEtihad Premium",
    field: "alEtihadPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "Wataniya Premium",
    field: "wataniyaPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "Acig Premium",
    field: "acigPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "GGI Premium",
    field: "ggiPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "Alrajhi Premium",
    field: "alrajhiPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "Buruj Premium",
    field: "burujPermium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "GIG Premium",
    field: "gigPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "Request Type",
    field: "isRenewal",
    cellRenderer: (e) => CellRenderers.isRenewalRendererEn(e),
    valueGetter: (params) => (params.data.isRenewal ? "Renewal" : "New Policy"),
    minWidth: 170,
  },
  {
    headerName: "Submitted On",
    field: "createdDate",
    cellRenderer: DateRenderer.specificDateFormatterEn,
    minWidth: 200,
  },
  {
    headerName: "Submitted By",
    field: "createdBy",
    minWidth: 230,
  },
];

export const QuotationsColumnDefsAr = [
  {
    headerName: "#",
    valueGetter: (e) => {
      return e.node.rowIndex + 1;
    },
    minWidth: 70,
  },
  {
    headerName: "الحالة",
    field: "statusAr",
    minWidth: 130,
    cellStyle: StatusBadgeRenderer.quotationStatusBadgeAr,
  },
  {
    headerName: "الرقم المرجعي",
    field: "quoteReferenceId",
    minWidth: 130,
  },
  {
    headerName: "رقم الهوية",
    field: "insuredIdentityNumber",
    minWidth: 120,
  },
  {
    headerName: "أسم المستأجر",
    field: "fullNameAr",
    tooltip: (e) => {
      return e.value;
    },
    minWidth: 200,
  },
  {
    headerName: "تاريخ الميلاد",
    field: "insuredBirthDate",
    cellRenderer: (e) => {
      if (e.value && e.value[0] == "1") {
        return DateRenderer.hijriDateFormatterAr(e);
      } else {
        return DateRenderer.dateFormatterAr(e);
      }
    },
    minWidth: 160,
  },
  {
    headerName: "أحقية خصم عدم وجود مطالبة",
    field: "ncdFreeYearName",
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
    minWidth: 210,
  },
  {
    headerName: "المركبة",
    field: "vehicleSummary",
    cellRenderer: "ImageCell",
    tooltip: () => {
      return "أضغط لعرض تفاصيل المركبة";
    },
    minWidth: 230,
  },
  {
    headerName: "لون المركبة",
    field: "vehicleMajorColorNameAr",
    minWidth: 120,
  },
  {
    headerName: "القيمة التقديرية للمركبة",
    field: "vehicleSumInsured",
    cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
    minWidth: 200,
  },
  {
    headerName: "رقم الهيكل",
    field: "vehicleChassisNumber",
    minWidth: 200,
  },
  {
    headerName: "طريقة الصيانة",
    field: "vehicleRepairMethodNameAr",
    minWidth: 170,
  },
  {
    headerName: "قيمة التحمل",
    field: "deductibleAmount",
    cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
    sortable: false,
    filter: false,
    minWidth: 180,
  },
  {
    headerName: "تسعيرة الاتحاد",
    field: "alEtihadPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة الوطنية",
    field: "wataniyaPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة اسيج",
    field: "acigPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة الخليجية العامة",
    field: "ggiPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة الراجحي",
    field: "alrajhiPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة بروج",
    field: "burujPermium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "تسعيرة GIG",
    field: "gigPremium",
    minWidth: 200,
    cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
  },
  {
    headerName: "نوع الطلب",
    field: "isRenewal",
    cellRenderer: (e) => CellRenderers.isRenewalRendererAr(e),
    valueGetter: (params) => (params.data.isRenewal ? "تجديد" : "وثيقة جديدة"),
    minWidth: 170,
  },
  {
    headerName: "تاريخ الإنشاء",
    field: "createdDate",
    cellRenderer: DateRenderer.specificDateFormatterAr,
    minWidth: 200,
  },
  {
    headerName: "أنشئ بواسطة",
    field: "createdBy",
    minWidth: 230,
  },
];

export const QuotationsConcordColsEn: GridColumn[] = AppUtils.injectAt(
  QuotationsColumnDefsEn.length - 2,
  QuotationsColumnDefsEn.concat(),
  [
    {
      headerName: "Lessor",
      field: "financialEntityName",
      minWidth: 170,
    },
  ]
);

export const QuotationsConcordColsAr: GridColumn[] = AppUtils.injectAt(
  QuotationsColumnDefsAr.length - 2,
  QuotationsColumnDefsAr.concat(),
  [
    {
      headerName: "المؤجر",
      field: "financialEntityNameAr",
      minWidth: 170,
    },
  ]
);
