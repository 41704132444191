<div class="login-page-container flex-container" style="direction: ltr">
  <div class="auth-box flex-centered">
    <!-- ----------------------------------------------------------------------- -->
    <!--                                  Menu                                   -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->

    <div class="menu-container">
      <!-- Brand Logo -->
      <div class="logo flex-column">
        <img src="../../../assets/images/favicon.ico" alt="Al Mamoon" class="flex-centered" />
        <span class="flex-centered">
          {{ "AUTHENTICATION.BrandName" | translate }}
        </span>
      </div>

      <!-- Login -->
      <div (click)="uiState.visibleTab.next('Login')" class="menu-item login flex-column"
        [class.active]="uiState.visibleTab.value === 'Login'">
        <fa-icon icon="user" size="md" class="flex-centered"></fa-icon>
        <span class="flex-centered">
          {{ "AUTHENTICATION.SideMenu.Login.MenuTitle" | translate }}
        </span>
      </div>

      <!-- Password Reset -->
      <div (click)="uiState.visibleTab.next('PasswordReset')" class="menu-item forgot-pass flex-column"
        [class.active]="uiState.visibleTab.value === 'PasswordReset'">
        <fa-icon icon="key" size="md" class="flex-centered"></fa-icon>
        <span class="flex-centered">{{
          "AUTHENTICATION.SideMenu.PasswordReset.MenuTitle" | translate
          }}</span>
      </div>

      <!-- Website -->
      <a href="https://oasisoft.net/" target="_blank" class="menu-item website-link flex-column">
        <fa-icon icon="globe" size="md" class="flex-centered"></fa-icon>
        <span class="flex-centered">{{
          "AUTHENTICATION.SideMenu.Website.MenuTitle" | translate
          }}</span>
      </a>

      <!-- About -->
      <div (click)="uiState.visibleTab.next('About')" class="menu-item about flex-column"
        [class.active]="uiState.visibleTab.value === 'About'">
        <fa-icon icon="info-circle" size="md" class="flex-centered"></fa-icon>
        <span class="flex-centered">{{
          "AUTHENTICATION.SideMenu.About.MenuTitle" | translate
          }}</span>
      </div>
    </div>

    <!-- #endregion -->

    <!-- ----------------------------------------------------------------------- -->
    <!--                                  Text                                   -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->

    <div class="text-container flex-container">
      <div class="text-wrapper flex-centered flex-column">
        <div class="head flex-container mb-4">
          <div class="flex-container flex-centered tenant-logo ml-0 mr-0">
            <img src="../../../assets/images/favicon.ico" class="flex-centered" />
          </div>
          <div class="flex-column flex-centered ml-4 mr-4">
            <h1 class="flex-centered">
              {{ "AUTHENTICATION.Title" | translate }}
            </h1>
            <p class="flex-centered">
              {{ "AUTHENTICATION.Subtitle" | translate }}
            </p>
          </div>
        </div>
        <div>
          <span>{{ "AUTHENTICATION.Details" | translate }}</span>
        </div>
        <!-- <div style="margin-top: 8em; color: #b4c1c2">
          <span>
            {{ uiState.appLang === "ar" ? "الإصدار" : "Version" }}
            {{ uiState.appVersion }}
            {{ "FOOTER.OwnerText" | translate }}
            <a style="color: #a4d0e0" href="https://oasisoft.net/" target="_blank">
              {{ "FOOTER.OwnerWebsite" | translate }}</a>
          </span>
        </div> -->
      </div>
    </div>

    <!-- #endregion -->

    <!-- ----------------------------------------------------------------------- -->
    <!--                                 Content                                 -->
    <!-- ----------------------------------------------------------------------- -->
    <!-- #region -->

    <div class="content-container flex-container">
      <!-- ----------------------------------------------------------------------- -->
      <!--                               Login Form                                -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <form *ngIf="uiState.visibleTab.value === 'Login'" [formGroup]="loginFormGroup" class="flex-centered flex-column">
        <!-- Title -->
        <span class="title">
          <span class="highlight">
            {{ "AUTHENTICATION.SideMenu.Login.MenuTitle" | translate }}
          </span>
          {{ "AUTHENTICATION.SideMenu.Login.Title" | translate }}
        </span>

        <!-- Error(s) Alert -->
        <div *ngIf="uiState.alert.error.isVisible" dir="ltr" class="alert" style="
            width: 100%;
            margin-bottom: -20px;
            font-size: medium;
            font-weight: bold;
            margin-top: 15px;
            text-align: start;
          ">
          <span><button style="
                border: none;
                margin-right: 5px;
                border-radius: 15px;
                height: 30px;
                width: 30px;
              " (click)="
                uiState.alert.error.isVisible = !uiState.alert.error.isVisible
              ">
              x
            </button></span>
          <small style="color: aliceblue">{{
            uiState.alert.error.message
            }}</small>
          <small *ngIf="uiState.alert.error.validationErrors">
            <ul class="errors-list">
              <li *ngFor="let error of uiState.alert.error.validationErrors">
                {{ error }}
              </li>
            </ul>
          </small>
        </div>

        <!-- Success Alert -->
        <ngb-alert *ngIf="uiState.alert.success.isVisible" type="success" (close)="
            uiState.alert.success.isVisible = !uiState.alert.success.isVisible
          ">
          <small>{{ uiState.alert.success.message }}</small>
        </ngb-alert>

        <!-- Form Inputs -->
        <div class="inputs">
          <!-- #region Email  -->

          <!-- Input -->
          <div class="input-group flex-container">
            <i class="input-icon fa fa-user flex-centered"></i>

            <input formControlName="email" type="email" [placeholder]="'LABELS.Generic.Email' | translate" />
          </div>

          <!-- Validation Errors -->
          <div class="validation" *ngIf="uiState.login.isSubmitting && loginForm.email.errors">
            <small class="v-message" *ngIf="loginForm.email.errors.required">
              {{ "VALIDATION.RequiredField" | translate }}
            </small>
            <small class="v-message" *ngIf="loginForm.email.errors.pattern">
              {{ "VALIDATION.InvalidEmail" | translate }}
            </small>
          </div>

          <!-- API Errors -->
          <div class="validation" *ngIf="
              uiState.login.isSubmitting && loginFormGroup.errors?.Username
            ">
            <small class="v-message" *ngFor="let description of loginFormGroup.errors.descriptions">
              <span *ngIf="description.Username">
                {{ description.Username }}
              </span>
            </small>
          </div>

          <!-- #endregion -->

          <!-- #region Password -->

          <!-- Input -->
          <div class="input-group flex-container" style="width: 100%">
            <i class="input-icon fa fa-key flex-centered"></i>

            <!-- Input -->
            <input formControlName="password" [placeholder]="'LABELS.Generic.Password' | translate"
              [type]="uiState.isPasswordVisible ? 'text' : 'password'" class="verification-code-ipt" />

            <!-- Eye Icon -->
            <i (click)="uiState.isPasswordVisible = !uiState.isPasswordVisible" class="password-eye bouncy" [ngClass]="
                uiState.isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
              "></i>
          </div>

          <!-- Validation Errors -->
          <div class="validation" *ngIf="uiState.login.isSubmitting && loginForm.password.errors">
            <small class="v-message" *ngIf="loginForm.password.errors.required">
              {{ "VALIDATION.RequiredField" | translate }}
            </small>
          </div>

          <!-- API Errors -->
          <div class="validation" *ngIf="
              uiState.login.isSubmitting && loginFormGroup.errors?.Password
            ">
            <small class="v-message" *ngFor="let description of loginFormGroup.errors.descriptions">
              <span *ngIf="description.Password">
                {{ description.Password }}
              </span>
            </small>
          </div>

          <!-- #endregion -->
        </div>

        <!-- Submit Button -->
        <button (click)="login()" class="submit-btn bouncy flex-centered">
          <span *ngIf="uiState.login.isLoading" class="icon spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <fa-icon *ngIf="!uiState.login.isLoading" icon="sign-in-alt" size="md"></fa-icon>
        </button>

        <!-- Form Links -->
        <span class="link">
          {{ "AUTHENTICATION.SideMenu.Login.ForgotPassword" | translate }}
          <a (click)="uiState.visibleTab.next('PasswordReset')" href="javascrpt:void(0)">
            {{ "AUTHENTICATION.SideMenu.Login.ResetIt" | translate }}
          </a>
        </span>
      </form>

      <!-- #endregion -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                           Password Reset Form                           -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <form *ngIf="uiState.visibleTab.value === 'PasswordReset'" [formGroup]="passwordResetFormGroup"
        class="flex-centered flex-column">
        <!-- Title -->
        <span class="title">
          <span class="highlight">
            {{ "AUTHENTICATION.SideMenu.PasswordReset.Reset" | translate }}
          </span>
          {{ "AUTHENTICATION.SideMenu.PasswordReset.Title" | translate }}
        </span>

        <!-- Error(s) Alert -->
        <ngb-alert *ngIf="uiState.alert.error.isVisible" type="danger" (close)="
            uiState.alert.error.isVisible = !uiState.alert.error.isVisible
          " style="width: 100%; margin-bottom: -20px; margin-top: 15px">
          <small>{{ uiState.alert.error.message }}</small>
          <small *ngIf="uiState.alert.error.validationErrors">
            <ul class="errors-list">
              <li *ngFor="let error of uiState.alert.error.validationErrors">
                {{ error }}
              </li>
            </ul>
          </small>
        </ngb-alert>

        <!-- Success Alert -->
        <ngb-alert *ngIf="uiState.alert.success.isVisible" type="success" (close)="
            uiState.alert.success.isVisible = !uiState.alert.success.isVisible
          ">
          <small>{{ uiState.alert.success.message }}</small>
        </ngb-alert>

        <!-- Form Inputs -->
        <div class="inputs">
          <!-- #region Email  -->

          <!-- Input -->
          <div class="input-group flex-container">
            <i class="input-icon fa fa-user flex-centered"></i>

            <input formControlName="email" type="email" [placeholder]="'LABELS.Generic.Email' | translate" />
          </div>

          <!-- Validation Errors -->
          <div class="validation" *ngIf="uiState.login.isSubmitting && passwordResetForm.email.errors">
            <small class="v-message" *ngIf="passwordResetForm.email.errors.required">
              {{ "VALIDATION.RequiredField" | translate }}
            </small>
            <small class="v-message" *ngIf="passwordResetForm.email.errors.pattern">
              {{ "VALIDATION.InvalidEmail" | translate }}
            </small>
          </div>

          <!-- #endregion -->

          <!-- #region Verification Code -->

          <!-- Input -->
          <div *ngIf="uiState.passwordReset.step === 'ResetRequest'" class="input-group flex-container"
            style="width: 100%">
            <i class="input-icon fa fa-lock flex-centered"></i>

            <input formControlName="codeCtrl" [placeholder]="'LABELS.Generic.OtpCode' | translate" type="text"
              class="verification-code-ipt" />
          </div>

          <!-- Validation Errors -->
          <div class="validation" *ngIf="
              uiState.passwordReset.step === 'ResetRequest' &&
              uiState.login.isSubmitting &&
              passwordResetForm.codeCtrl.errors
            ">
            <small class="v-message" *ngIf="passwordResetForm.codeCtrl.errors.required">
              {{ "VALIDATION.RequiredField" | translate }}
            </small>
          </div>

          <!-- #endregion -->

          <!-- #region New Password -->

          <!-- Input -->
          <div *ngIf="uiState.passwordReset.step === 'ResetRequest'" class="input-group flex-container"
            style="width: 100%">
            <i class="input-icon fa fa-key flex-centered"></i>

            <input formControlName="newpassword" [placeholder]="'LABELS.Generic.NewPassword' | translate"
              [type]="uiState.isPasswordVisible ? 'text' : 'password'" class="verification-code-ipt" />

            <i (click)="uiState.isPasswordVisible = !uiState.isPasswordVisible" class="password-eye bouncy" [ngClass]="
                uiState.isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'
              "></i>
          </div>

          <!-- Validation Errors -->
          <div *ngIf="
              uiState.passwordReset.step === 'ResetRequest' &&
              uiState.login.isSubmitting &&
              passwordResetForm.newpassword.errors
            " class="validation">
            <small class="v-message" *ngIf="passwordResetForm.newpassword.errors.required">
              {{ "VALIDATION.RequiredField" | translate }}
            </small>
          </div>

          <!-- #endregion -->
        </div>

        <!-- Issue Reset Request Button -->
        <button *ngIf="uiState.passwordReset.step === 'ResetIssue'" (click)="issuePasswordReset()"
          class="submit-btn bouncy flex-centered">
          <span *ngIf="uiState.passwordReset.isLoading" class="icon spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <fa-icon *ngIf="!uiState.passwordReset.isLoading" icon="user-check" size="md"></fa-icon>
        </button>

        <!-- Password Reset -->
        <button *ngIf="uiState.passwordReset.step === 'ResetRequest'" (click)="resetPassword()"
          class="submit-btn bouncy flex-centered">
          <span *ngIf="uiState.passwordReset.isLoading" class="icon spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <fa-icon *ngIf="!uiState.passwordReset.isLoading" icon="user-check" size="md"></fa-icon>
        </button>
      </form>

      <!-- #endregion -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                                  About                                  -->
      <!-- ----------------------------------------------------------------------- -->
      <!-- #region -->

      <div *ngIf="uiState.visibleTab.value === 'About'" class="flex-container" style="width: 100%">
        <div class="flex-centered">
          <span>{{ "AUTHENTICATION.SideMenu.About.Title" | translate }}</span>
        </div>
      </div>

      <!-- #endregion -->

      <div *ngIf="uiState.visibleTab.value === 'OTP'" class="container justify-content-center pt-4 pb-5">
        <div class="d-flex justify-content-end mx-2">
          <fa-icon icon="arrow-alt-circle-left" class="cross" (click)="closeActiveModal()"></fa-icon>
        </div>
        <div class="pb-4 d-flex text-center flex-column">
          <div>
            <img src="../../../assets/illustrations/envelope-circle-check-solid.svg" width="100" height="100">
          </div>
          <h4>{{ "AUTHENTICATION.sendOtp" | translate }}</h4>
          <span class="email">{{ "AUTHENTICATION.sendOtpSubtitle" | translate }} {{loginForm.email.value}}</span>
        </div>
        <div class="d-flex flex-column justify-content-between">
          <div class="bg-white text-center">
            <!-- ------------------------------ Alert(s) ------------------------------- -->
            <!-- Error(s) Alert -->
            <div *ngIf="
              uiState.alert.error.isVisible ||
              uiState.alert.success.isVisible
                " class="alert-wrapper mt-1">
              <ngb-alert *ngIf="uiState.alert.error.isVisible" type="danger" [dismissible]="true" (onClosed)="
                    isErrorAlertVisible =
                      !isErrorAlertVisible
                  ">
                <small>{{ this.uiState.alert.error.message }}</small>
                <small *ngIf="uiState.alert.error.validationErrors">
                  <ul class="errors-list">
                    <li *ngFor="let error of uiState.alert.error.validationErrors">
                      {{ error }}
                    </li>
                  </ul>
                </small>
              </ngb-alert>
              <!-- Success Alert -->
              <ngb-alert *ngIf="uiState.alert.success.isVisible" type="success" [dismissible]="true" (onClosed)="
                    uiState.alert.success.isVisible =
                      !uiState.alert.success.isVisible
                  ">
                <small>{{ uiState.alert.success.message }}</small>
              </ngb-alert>
            </div>
            <div class="flex-centered" style="width: 100%">
              <!-- Validation Code Input -->
              <div class="col-lg-12 row d-flex justify-content-center">
                <ng-otp-input (onInputChange)="onOtpChange($event)" (keydown.enter)="otp.length === 6 && verifyOTP()"
                  class="w-100" [config]="{length: 6 , allowNumbersOnly:true}"></ng-otp-input>
                <div *ngIf="
                      isSubmitting &&
                      (!otp ||
                      otp.toString().length === 0)
                    ">
                  <div class="v-meassage mx-5">
                    <span>{{ "Errors.EmptyInputField" | translate }}</span>
                  </div>
                </div>
                <!-- -->
                <button [disabled]="otp.length !== 6" [ngStyle]="otp.length !== 6 ? { 'background' : '#6c757d' } : {
                      'background': 'transparent linear-gradient(130deg, #06aecc 0%, #3269c4 100%) 0% 0%'
                    }" (click)="verifyOTP()" class="verification-code-btn btn-main mt-3" type="submit"
                  style="color: white">
                  <span class="mx-2 binner">{{ "AUTHENTICATION.ContinueText" | translate }}</span>
                  <i *ngIf="!uiState.login.isLoadingOTP" class="ion-md-done-all"></i>
                  <span *ngIf="uiState.login.isLoadingOTP" class="spinner-border spinner-border-sm binner" role="status"
                    aria-hidden="true"></span>
                </button>
              </div>

              <!-- Resend email link -->
              <div class="col-lg-12 mt-4">
                <span class="verification mb-2">
                  {{ "AUTHENTICATION.Duration" | translate }}
                  <span class="text-dark">{{ transform(counter) }}</span>
                </span>
                <div class="timer d-flex justify-content-center align-items-center">
                  <div class="foot">{{ "AUTHENTICATION.RESEIVE_CODE" | translate }}</div>
                  <div class="btn-foot">
                    <button (click)="resendOTP()" [disabled]="counter !== 0" [ngStyle]="
                            counter !== 0 ? { color: '#7f8087' } : { cursor: 'pointer' }
                          ">
                      {{ "AUTHENTICATION.SEND_AGAIN" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- #endregion -->
  </div>
</div>

<!-- verify template -->
<ng-template #verify let-c="close" let-d="dismiss" id="verifyAccount">
  <div class="container justify-content-center pt-4 pb-5">
    <div class="d-flex justify-content-end mx-2">
      <fa-icon icon="times-circle" class="cross" (click)="closeActiveModal()"></fa-icon>
    </div>
    <div class="pb-4 d-flex text-center flex-column">
      <div>
        <img src="../../../assets/illustrations/envelope-circle-check-solid.svg" width="100" height="100">
      </div>
      <h4>{{ "AUTHENTICATION.sendOtp" | translate }}</h4>
      <span>{{ "AUTHENTICATION.sendOtpSubtitle" | translate }} {{loginForm.email.value}}</span>
    </div>
    <div class="d-flex flex-column justify-content-between">
      <div class="bg-white text-center">
        <!-- ------------------------------ Alert(s) ------------------------------- -->
        <!-- Error(s) Alert -->
        <div *ngIf="
          uiState.alert.error.isVisible ||
          uiState.alert.success.isVisible
            " class="alert-wrapper mt-1">
          <ngb-alert *ngIf="uiState.alert.error.isVisible" type="danger" [dismissible]="true" (onClosed)="
                isErrorAlertVisible =
                  !isErrorAlertVisible
              ">
            <small>{{ this.uiState.alert.error.message }}</small>
            <small *ngIf="uiState.alert.error.validationErrors">
              <ul class="errors-list">
                <li *ngFor="let error of uiState.alert.error.validationErrors">
                  {{ error }}
                </li>
              </ul>
            </small>
          </ngb-alert>
          <!-- Success Alert -->
          <ngb-alert *ngIf="uiState.alert.success.isVisible" type="success" [dismissible]="true" (onClosed)="
                uiState.alert.success.isVisible =
                  !uiState.alert.success.isVisible
              ">
            <small>{{ uiState.alert.success.message }}</small>
          </ngb-alert>
        </div>
        <div class="flex-centered" style="width: 100%">
          <!-- Validation Code Input -->
          <div class="col-lg-12 row d-flex justify-content-center">
            <ng-otp-input (onInputChange)="onOtpChange($event)"
              [config]="{length: 6 , allowNumbersOnly:true}"></ng-otp-input>
            <div *ngIf="
                  isSubmitting &&
                  (!otp ||
                  otp.toString().length === 0)
                ">
              <div class="v-meassage mx-5">
                <span>{{ "Errors.EmptyInputField" | translate }}</span>
              </div>
            </div>
            <!-- -->
            <button [disabled]="otp.length !== 6" [ngStyle]="otp.length !== 6 ? { 'background' : '#6c757d' } : {
                  'background': 'transparent linear-gradient(130deg, #06aecc 0%, #3269c4 100%) 0% 0%'
                }" (click)="verifyOTP()" class="verification-code-btn btn-main mt-3" type="submit"
              style="color: white">
              <span class="mx-2 binner">{{ "AUTHENTICATION.ContinueText" | translate }}</span>
              <i *ngIf="!uiState.login.isLoadingOTP" class="ion-md-done-all"></i>
              <span *ngIf="uiState.login.isLoadingOTP" class="spinner-border spinner-border-sm binner" role="status"
                aria-hidden="true"></span>
            </button>

          </div>

          <!-- Resend email link -->
          <div class="col-lg-12 mt-4">
            <span class="verification mb-2">
              {{ "AUTHENTICATION.Duration" | translate }}
              <span class="text-dark">{{ transform(counter) }}</span>
            </span>
            <div class="d-flex justify-content-center align-items-center">
              <div class="me-1 foot">{{ "AUTHENTICATION.RESEIVE_CODE" | translate }}</div>
              <div class="btn-foot">
                <button (click)="resendOTP()" [disabled]="counter !== 0" [ngStyle]="
                        counter !== 0 ? { color: '#7f8087' } : { cursor: 'pointer' }
                      ">
                  {{ "AUTHENTICATION.SEND_AGAIN" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>