import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { BehaviorSubject, interval } from "rxjs";
import Swal from "sweetalert2";
import { LanguageService } from "./app/language.service";

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  private UPDATE_CHECK_INTERVAL = 30000;
  isUpdateAvailable: BehaviorSubject<{
    hasUpdate: boolean;
  }> = new BehaviorSubject<{ hasUpdate: boolean }>({ hasUpdate: false });

  constructor(
    public update: SwUpdate,
    private languageService: LanguageService
  ) {
    this.scheduleUpdateChecks();
  }

  private scheduleUpdateChecks(): void {
    if (this.update.isEnabled) {
      interval(this.UPDATE_CHECK_INTERVAL).subscribe(() => {
        this.update
          .checkForUpdate()
          .then(() => console.log("[App] Checking for updates."));
      });
    }
  }

  public checkForUpdates(): void {
    console.log("check for update");
    this.update.available.subscribe((event) => {
      // Set available update flag (used to display update notification to remind user)
      this.setIsUpdateAvailable({ hasUpdate: true });

      // Prompt the user about the update
      this.promptUser();
    });
  }

  private promptUser(): void {
    console.log("[App] New update available, prompting user.");
    this.update.activateUpdate().then(() => this.displayUpdateDialog());
  }

  private displayUpdateDialog(): void {
    let isCurrentLangEn = this.languageService.getAppLang().value === "en";

    const swal = Swal.mixin({
      customClass: { confirmButton: "btn primary", cancelButton: "btn danger" },
      buttonsStyling: false,
    });
    const message = `
      ${
        isCurrentLangEn
          ? "Do you want to refresh the page to update now?"
          : "هل تريد إعادة تحميل الصفحة للتحديث الأن؟"
      }`;

    swal
      .fire({
        title: isCurrentLangEn ? "There is a new update" : "هنالك تحديث جديد",
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: isCurrentLangEn ? "Yes" : "نعم",
        cancelButtonText: isCurrentLangEn ? "No, Later" : "لاحقا",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => document.location.reload(),
      })
      .then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          swal.fire(
            isCurrentLangEn ? "Cancelled" : "تم الإلغاء",
            isCurrentLangEn
              ? `Refresh manually later to update`
              : "الرجاء إعادة تحميل الصفحة لاحقاً للتحديث",
            "warning"
          );
        }
      });
  }

  public getIsUpdateAvailable(): BehaviorSubject<{ hasUpdate: boolean }> {
    return this.isUpdateAvailable;
  }

  public setIsUpdateAvailable(isUpdateAvailable: { hasUpdate: boolean }): void {
    this.isUpdateAvailable.next(isUpdateAvailable);
  }
}
