<div class="dark-body-only">
  <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">

    <app-header></app-header>
    <nav class="navbar navbar-expand-sm navbar-dark bg-primary  p-0" style="margin-top: 50px; ">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ">
          <li class="nav-item " *ngFor="let item of menuItems;index as i">
            <a class="nav-link " [ngClass]="uiState.appLang == 'ar' ? 'mr-0':'ml-0'"
              [routerLink]="item.path||item.children[0].path" routerLinkActive="active"><fa-icon
                [icon]="item.icon"></fa-icon> <span class="mx-2 " style="text-wrap: nowrap;">{{uiState.appLang ===
                "ar" ?
                item.titleAr :item.title}}</span> </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">

      <div class="page-sidebar " [ngClass]="navServices.screenWidth < 992 ? '':'d-none'" id="pages-sidebar"
        [class.open]="navServices.collapseSidebar" [ngClass]="customizer.data.settings.sidebar_backround"
        [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
        <app-sidebar *ngIf="navServices.screenWidth < 992 "></app-sidebar>
      </div>

      <!-- [ngStyle]="navServices.isOpenMobile ? 'margin-top: 120px !important;':'margin-top: 75px !important;'" -->
      <div class="page-body"
        [style]=" navServices.collapseSidebar === false && uiState.appLang !=='ar' ?
            'margin-left: 126px !important;margin-top: 120px !important;width:calc(100% - 126px) !important;margin-right: 0px !important;'
            :navServices.collapseSidebar === true && uiState.appLang =='ar' || uiState.appLang !=='ar' ?
            'margin-left: 0px !important;margin-top: 75px !important;width:calc(100% - 0px) !important;margin-right: 0px !important;':'margin-right: 126px !important;'">
        <main style="min-height: 100%;">
          <router-outlet></router-outlet>
        </main>
      </div>

      <footer class="footer" [style]="navServices.screenWidth < 992 && navServices.collapseSidebar === false ?
             'margin-left: 0px !important;width: calc(100% - 126px) !important;margin-right: 0px !important'
             : navServices.collapseSidebar === true && uiState.appLang !=='ar' || uiState.appLang =='ar'?
             'margin-left: 0px !important;width: calc(100% - 0px) !important;margin-right: 0px !important':''">
        <app-footer></app-footer>
      </footer>

    </div>

  </div>
</div>