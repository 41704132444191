import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiRoutes } from '../../models/app/ApiRoutes';
import { User } from '../../models/users/User';
import { UserFilters } from '../../models/users/UserFilters';
import { UserCreationRequest } from '../../payloads/requests/users/UserCreationRequest';
import { UserUpdateRequest } from '../../payloads/requests/users/UserUpdateRequest';
import { UsersListResponse } from '../../payloads/response/users/UsersListResponse';
import { PasswordResetIssueReq } from '../../payloads/requests/users/PasswordResetIssueReq';
import { PasswordResetReq } from '../../payloads/requests/users/PasswordResetReq';
import { UserPasswordChangeReq } from '../../payloads/requests/users/UserPasswordChangeReq';


@Injectable({
   providedIn: 'root'
})


export class UserService {


   apiUrl = environment.apiUrl;


   constructor(
      private http: HttpClient
   ) { }


   public getUsersList(userFilters: UserFilters): Observable<any> {
      return this.http.post<UsersListResponse>(`${this.apiUrl}${ApiRoutes.Users.List}`, userFilters, { observe: 'response' });
   }


   public getUser(userId: string): Observable<User> {
      return this.http.get<User>(`${this.apiUrl}${ApiRoutes.Users.Details}/${userId}`);
   }


   public addUser(userCreationReq: UserCreationRequest): Observable<any> {
      return this.http.post(`${this.apiUrl}${ApiRoutes.Users.Create}`, userCreationReq);
   }


   public updateUser(userId: string, userUpdateReq: UserUpdateRequest): Observable<any> {
      return this.http.post(`${this.apiUrl}${ApiRoutes.Users.Update}?Id=${userId}`, userUpdateReq);
   }


   public issuePasswordReset(passwordResetIssueReq: PasswordResetIssueReq): Observable<any> {
      console.log("Sending password reset issue request");
      return this.http.post(`${this.apiUrl}${ApiRoutes.Users.PasswordResetRequest}`, passwordResetIssueReq);
   }


   public resetPassword(passwordResetReq: PasswordResetReq) {
      return this.http.post(`${this.apiUrl}${ApiRoutes.Users.PasswordReset}`, passwordResetReq);
   }


   public changeUserPassword(passwordChangeReq: UserPasswordChangeReq) {
      return this.http.post(`${this.apiUrl}${ApiRoutes.Users.PasswordChange}`, passwordChangeReq);
   }

}
