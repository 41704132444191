import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrintablePageProcess } from '../../models/printed-pages/PrintablePageProcess';

/**
 * Manages the printable pages printing process, contains the behaviour subject which allows for printing the pages
 *
 * @export
 * @class PrintingService
 */
@Injectable({
   providedIn: 'root'
})


export class PrintingService {

   /**
    * Contains the printable page process of the page to be printed
    *
    * @type {BehaviorSubject<PrintablePageProcess>}
    * @memberof PrintingService
    */
   activePrintablePage: BehaviorSubject<PrintablePageProcess> = new BehaviorSubject<PrintablePageProcess>(undefined);


   constructor() { }


   /**
    * Sets the page to be printed, the printable page is printed when a value is passed via this setter
    *
    * @param {PrintablePageProcess} pageProcess
    * @memberof PrintingService
    */
   setActivePrintablePage(pageProcess: PrintablePageProcess): void {
      this.activePrintablePage.next(pageProcess);
   }


   /**
    * Gets the current printable page
    *
    * @returns {BehaviorSubject<PrintablePageProcess>}
    * @memberof PrintingService
    */
   getActivePrintablePage(): BehaviorSubject<PrintablePageProcess> {
      return this.activePrintablePage;
   }

}
