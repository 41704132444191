import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";


export const ClientsColumnDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "Reference No.",
      field: "referenceId",
      sortable: false,
      minWidth: 140,
   },
   {
      headerName: "Identity Number",
      field: "identityNumber",
      sortable: false,
      minWidth: 140,
   },
   {
      headerName: "Full Name",
      field: "fullName",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 210,
   },
   {
      headerName: "Gender",
      field: "genderName",
      sortable: false,
      minWidth: 120,
   },
   {
      headerName: "Birth Date",
      field: "birthDate",
      sortable: false,
      minWidth: 140,
   },
   {
      headerName: "Identity Type",
      field: "identityTypeId",
      cellRenderer: (e) => CellRenderers.identityTypeEnRenderer(e),
      sortable: false,
      minWidth: 140,
   },
   {
      headerName: "Identity Issue Place",
      field: "identityIssuePlaceName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 200,
   },
   {
      headerName: "Education Level",
      field: "educationLevelName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      minWidth: 150,
   },
   {
      headerName: "Nationality",
      field: "nationalityName",
      sortable: false,
      minWidth: 150,
   },
   {
      headerName: "Phone Number",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberEnRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Email",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 230,
   },
   {
      headerName: "Branch",
      field: "financialEntityBranchName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      minWidth: 150,
   },
   {
      headerName: "Submitted On",
      field: "createdDate",
      cellRenderer: DateRenderer.specificDateFormatterEn,
      sortable: false,
      minWidth: 200,
   },
   {
      headerName: "Submitted By",
      field: "createdBy",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 250,
   }
];

export const ClientsColumnDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "الرقم المرجعي",
      field: "referenceId",
      sortable: false,
      minWidth: 140,
   },
   {
      headerName: "رقم الهوية",
      field: "identityNumber",
      sortable: false,
      minWidth: 130,
   },
   {
      headerName: "الأسم الكامل",
      field: "fullNameAr",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 200,
   },
   {
      headerName: "الجنس",
      field: "genderNameAr",
      sortable: false,
      minWidth: 100,
   },
   {
      headerName: "تاريخ الميلاد",
      field: "birthDate",
      cellRenderer: (e) => CellRenderers.variantDateArRenderer(e),
      sortable: false,
      minWidth: 180,
   },
   {
      headerName: "نوع الهوية",
      field: "identityTypeId",
      cellRenderer: (e) => CellRenderers.identityTypeArRenderer(e),
      sortable: false,
      minWidth: 150,
   },
   {
      headerName: "مكان إصدار الهوية",
      field: "identityIssuePlaceNameAr",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      minWidth: 180,
   },
   {
      headerName: "مستوى التعليم",
      field: "educationLevelNameAr",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      minWidth: 180,
   },
   {
      headerName: "الجنسية",
      field: "nationalityNameAr",
      sortable: false,
      minWidth: 150,
   },
   {
      headerName: "رقم الهاتف",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "البريد الإلكتروني",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 230,
   },
   {
      headerName: "الفرع",
      field: "financialEntityBranchNameAr",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      minWidth: 170,
   },
   {
      headerName: "تاريخ الإنشاء",
      field: "createdDate",
      cellRenderer: DateRenderer.specificDateFormatterAr,
      sortable: false,
      minWidth: 200,
   },
   {
      headerName: "أنشئ بواسطة",
      field: "createdBy",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 250,
   }
];
