/* eslint-disable @typescript-eslint/naming-convention */
export const ApiRoutes = {
  Auth: {
    Login: "account/login",
    Otp: "account/Otp",
  },

  Endorsements: {
    addDriver: "addDriver",
    confirmAddDriver: "confirmAddDriver",
  },

  client: {
    search: "clients/search",
    keywordSearch: "clients/searchbyname",
    details: "client/details/",
    update: "client/update",
    create: "client/create",
    vehicles: "client/vehicles",
    VehicleUpdate: "client/vehicle/update",
    VehicleAdd: "client/vehicle/create",
    VehicleDelete: "clients/Deletevehicle/",
    DraftPolicies: "client/draftpolicies",
    policies: "client/policies",
    invoices: "client/invoices",
    quotation: "client/quotation",
    quotationOpts: "client/quotesOptions",
    Licenses: "client/licenses",
    Addresses: "client/address",
    LicenseCreate: "client/createlicenses",
    LicenseUpdate: "client/license/update",
    AddressUpdate: "client/address/update",
    Contacts: "client/contacts",
    ContactUpdate: "client/contact/update",
    QuotationDetails: "client/Quotation/Details",
    insuranceAccount: "client/ShadowAccount",
  },

  logger: {
    historylog: "Logger/historylog",
    smslog: "logger/smslog",
  },

  Dashboard: {
    Analytics: "home/dashboard",
  },

  CancelledPolicies: {
    search: "policy/CancelatedPolicies",
  },

  PendingCancelled: {
    search: "policy/PendingCancelledPolicies",
  },

  Policies: {
    Search: "policy/search",
    details: "policy/details/",
    printinvoice: "policy/printinvoice/",
    Drafts: "policy/drafts",
    Issue: "policy/issue",
    Print: "policy/print",
    cancel: "policy/ICCancel",
    WataniyaPrint: "policy/cert",
    feature: "policy/endorsement/feature",
    RenewalReminder: "policy/remind",
    SendSMSOrEmail: "policy/SendShareTemplate",
    AddFeature: "QueryFeature",
    purchaseFeature: "purchaseFeature",
    initCancel: "policy/initialcancelrequest",
  },

  Reports: {
    Policies: "report/policies",
    ActivePolicies: "policy/ExportActivePolicy",
    Finance: "report/financeReport",
    Yaqeen: "report/yakeenServiceReport",
    insuranceAccountReport: "report/SAMAShadowAccounts",
    // insuranceAccountReport: 'report/ShadowAccountsReport',
  },

  DraftPolicies: {
    Issue: "policy/draft/issue",
    WataniyaIssue: "policy/wataniya/draft/issue",
    Print: "quotation/print/",
  },

  Quotation: {
    search: "quotation/search",
    details: "quotation/details/",
    PreviewQuote: "quotation/preview",
    PreviewAttachment: "quotation/preview/attachment",
    RequestQuote: "quotation/request",
    InquireQuote: "quotation/inquire",
    saveOrder: "quotation/Save",
    session: "quotations/",
    QuotePrint: "quotation/print",
    AllQuotesPrint: "quotation/printallquotes",
    ComparisonSheetPrint: "quotation/printcompressionsheet",
    Details: "request/details/",
  },

  Users: {
    Create: "account/create",
    Update: "account/update",
    List: "account/users",
    Details: "account/user/details",
    ChangePassword: "account/changepassword",
    ResetRequest: "account/resetrequest",
    Reset: "account/reset",
    LoginHistory: "account/loginhistory",
    PasswordResetRequest: "account/resetrequest",
    PasswordReset: "account/reset",
    PasswordChange: "account/changepassword",
  },

  MasterTable: {
    VehicleDetails: "master/VehicleDetails",
    ProductType: "master/products",
    Benefit: "master/benefits",
    Violation: "master/violations",
    DiscountTypes: "master/DiscountTypes",
    PremiumBreakdown: "master/PremiumBreakdowns",
    PromoCode: "master/PromoCodes",
    NCDFreeYear: "master/NCDFreeYears",
    IdentityType: "master/IdentityTypes",
    Gender: "master/Genders",
    EducationLevel: "master/EducationLevels",
    SocialStatus: "master/SocialStatus",
    Occupations: "master/Occupations",
    Countries: "master/Countries",
    Region: "master/Regions",
    Cities: "master/Cities",
    NationalAddressCities: "master/NationalAddressCities",
    LicenseType: "master/LicenseTypes",
    DriverType: "master/DriverTypes",
    DrivingPercentage: "master/DrivingPercentages",
    MedicalCondition: "master/MedicalConditions",
    Mileage: "master/Mileages",
    VehicleIdType: "master/VehicleIdTypes",
    VehicleMaker: "master/VehicleMakers",
    VehicleModel: "master/VehicleModels",
    VehiclePlateType: "master/VehiclePlateTypes",
    VehiclePlateLetter: "master/VehiclePlateLetters",
    TransmissionType: "master/TransmissionTypes",
    VehicleBodyType: "master/VehicleBodyTypes",
    VehicleAxlesWeight: "master/VehicleAxlesWeights",
    VehicleColor: "master/VehicleColors",
    VehicleEngineSize: "master/VehicleEngineSizes",
    VehicleSpecifications: "master/VehicleSpecifications",
    VehicleUse: "master/VehicleUses",
    VehicleRepairMethod: "master/VehicleRepairMethods",
    ParkingLocation: "master/ParkingLocations",
    PaymentMethod: "master/PaymentMethods",
    Bank: "master/Banks",
    Relation: "master/relations",
    Attachment: "master/Attachments",
    FinancialEntityBranch: "master/FinancialEntityBranch",
    FinancialEntities: "master/FinancialEntity",
    Users: "master/users",
    InsuranceCompanies: "master/InsuranceCompany",
  },

  insurance: {
    getQuotes: "quotation/request",
    getQuotesAggr: "quotation/aggr",
    regenerateQuotes: "quotation/RequenrateQuote",
    renewalInquiry: "policy/renewalInquiry",
  },

  ThirdParty: {
    YaqeenClientData: "YakeenService/clientAndAddress",
    YaqeenClient: "YakeenService/client",
    YaqeenAddress: "YakeenService/Address",
    YaqeenVehicle: "YakeenService/Vehicle",
  },
};
