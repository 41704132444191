import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";


export const UsersColumnsDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => { return e.node.rowIndex + 1; },
      minWidth: 70,
   },
   {
      headerName: "Status",
      field: "isActive",
      cellStyle: StatusBadgeRenderer.userStatusBadgeEn,
      cellRenderer: (e) => { if (e.value) { return 'Active' } else { return 'Inactive' }},
      minWidth: 180,
   },
   {
      headerName: "Full Name",
      field: "fullName",
      tooltip: (e) => { return e.value; },
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "Email",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 220,
   },
   {
      headerName: "Phone Number",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberEnRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "Branch",
      field: "branchName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 140,
   },
   {
      headerName: "No. of Clients",
      field: "numberOfClients",
      sortable: false,
      filter: false,
      minWidth: 140,
   },
   {
      headerName: "Submitted On",
      field: "createdDate",
      sortable: false,
      filter: false,
      minWidth: 180,
      cellRenderer: DateRenderer.specificDateFormatterEn,
   },
   {
      headerName: "Submitted By",
      field: "createdByName",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 250,
   }
];

export const UsersColumnsDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => { return e.node.rowIndex + 1; },
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "الحالة",
      field: "isActive",
      cellStyle: StatusBadgeRenderer.userStatusBadgeAr,
      cellRenderer: (e) => { if (e.value) { return 'نشط' } else { return 'غير نشط' }},
      minWidth: 180,
      sortable: false,
      filter: false,
   },
   {
      headerName: "الأسم الكامل",
      field: "fullName",
      tooltip: (e) => { return e.value; },
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "البريد الإلكتروني",
      field: "email",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 220,
   },
   {
      headerName: "رقم الهاتف",
      field: "phoneNumber",
      cellRenderer: (e) => CellRenderers.phoneNumberArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 170,
   },
   {
      headerName: "الفرع",
      field: "branchNameAr",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 140,
   },
   {
      headerName: "عدد العملاء",
      field: "numberOfClients",
      sortable: false,
      filter: false,
      minWidth: 140,
   },
   {
      headerName: "تاريخ الإنشاء",
      field: "createdDate",
      sortable: false,
      filter: false,
      minWidth: 180,
      cellRenderer: DateRenderer.specificDateFormatterAr,
   },
   {
      headerName: "أنشئ بواسطة",
      field: "createdByName",
      tooltip: (e) => e.value,
      sortable: false,
      minWidth: 250,
   }
];
