<div class="loading-container">

   <!-- ----------------------------------------------------------------------- -->
   <!--                                 Loader                                  -->
   <!-- ----------------------------------------------------------------------- -->


   <div class="loading-wrapper">

      <div class="loading-brand-container">

         <div class="loading-brand-wrapper">
            <div class="spinner-border text-primary" role="status">
               <span class="sr-only">Loading...</span>
            </div>
         </div>
      </div>

      <!-- <svg class="first-letter" width="52" height="73" viewBox="0 0 52 73" fill="none"
         xmlns="http://www.w3.org/2000/svg">
         <path d="M6 24V49.2L32.4923 66L47 61.6" stroke="#1E366C" stroke-width="11" />
         <path d="M6 49V23.8L32.4923 7L47 11.4" stroke="#1E366C" stroke-width="11" />
         <path d="M32.6686 59.5908L32.0753 71.4149L50.2949 65.9473L47.0329 55.0953L32.6686 59.5908Z" fill="#00A2AD"
            stroke="#00A2AD" />
         <path d="M32.5823 13.3214L31.992 1.53759L50.436 7.0118L47.1233 17.8215L32.5823 13.3214Z" fill="#00A2AD"
            stroke="#00A2AD" />
      </svg> -->

   </div>


   <!-- ----------------------------------------------------------------------- -->
   <!--                              Loading Message                            -->
   <!-- ----------------------------------------------------------------------- -->
   <div class="loading-msg">
      <span *ngIf="message">{{ message }}</span>
   </div>

</div>