import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ToastService } from "./shared/services/widgets/toast.service";
import { LanguageService } from "./shared/services/app/language.service";
import { PrintingService } from "./shared/services/printing/printing.service";
import AppUtils from "./shared/helpers/utilities/AppUtils";
import { UpdateService } from "./shared/services/update.service";
import { LocallyStoredItemsKeys } from "./shared/models/app/LocallyStoredItemsKeys";
import * as updatesList from "../assets/json/latest-updates.json";
import { NavService } from "./shared/services/nav.service";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { AppService } from "./shared/services/app/app.service";
import { ActivatedRoute } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";
import { TenantService } from "./shared/services/app/tenant.service";
const appVersion = require("../../package.json").version;
const netest = require("netest");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  //#region States

  state = {};

  uiState = {
    pagePrint: {
      isPrinting: false,
    },
    appUpdate: {
      hasUpdates: false,
    },
    connectionChecks: {
      intervalSubscription: new Subscription() as Subscription,
    },
    appVersionStr: undefined,
    appVersion: undefined,
    appLang: "",
  };

  //#endregion

  constructor(
    private route: ActivatedRoute,
    public toastService: ToastService,
    private langService: LanguageService, // Do not remove, it is imported at startup to initialize startup language
    private printingService: PrintingService,
    private updateService: UpdateService,
    private navService: NavService,
    private appService: AppService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.watchConnectionStatus();
    this.watchPrintingProcess();
    this.uiState.appLang = this.langService.getAppLang().value;
    this.tenantService.initTenantTheme();
  }

  ngAfterViewInit(): void {
    this.initUpdatesCheck();
    this.initConnectionCheckState();
    this.watchConnectionChecksActivity();
    setTimeout(() => this.initDarkThemeState(), 1000);
  }

  /* -------------------------------------------------------------------------- */
  /*                               Initialization                               */
  /* -------------------------------------------------------------------------- */
  //#region

  private initApp(): void {
    // Disable logs on production
    // if (environment.production) window.console.log = function () {};
  }

  private initUpdatesCheck(): void {
    // Get current App version
    let currentAppVer = appVersion.split(".").join("");
    let storedAppVer = localStorage.getItem(LocallyStoredItemsKeys.AppVersion);
    this.uiState.appVersionStr = appVersion;
    this.uiState.appVersion = currentAppVer;

    // Save current App version on local storage if it wasn't there
    if (!storedAppVer)
      localStorage.setItem(LocallyStoredItemsKeys.AppVersion, currentAppVer);

    let url = window.location.pathname;

    // Check if there an update has occured
    if (
      storedAppVer &&
      parseInt(currentAppVer) > parseInt(storedAppVer) &&
      url !== "/login" &&
      updatesList.default.updates.length > 0
    ) {
      // Display the updates on the notifications bar
      this.navService.isNotificationBarOpen.next(true);

      // Update the stored version with the latest version
      localStorage.setItem(LocallyStoredItemsKeys.AppVersion, currentAppVer);
    }

    // Initiate new version periodical checks
    this.updateService.checkForUpdates();
  }

  /**
   * Activates dark theme according to the stored user preference
   *
   * @private
   * @memberof AppComponent
   */
  private initDarkThemeState(): void {
    let isDarkThemeActivated;

    try {
      isDarkThemeActivated = JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.DarkTheme)
      ).isDarkThemeActivated;
    } catch (ex) {
      isDarkThemeActivated = false;
    }

    if (isDarkThemeActivated) document.body.classList.add("dark-theme");
    else document.body.classList.remove("dark-theme");
  }

  /**
   * Activates continuous connection speed checks according to the stored user preference
   *
   * @private
   * @memberof AppComponent
   */
  private initConnectionCheckState(): void {
    let isConnectionCheckActivated;

    try {
      isConnectionCheckActivated = JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.ConnectionChecks)
      ).isConnectionCheckActivated;
    } catch (ex) {
      isConnectionCheckActivated = false;
    }

    if (isConnectionCheckActivated) this.initSpeedChecks();
    else this.uiState.connectionChecks.intervalSubscription.unsubscribe();
  }

  private initSpeedChecks(): void {
    let isAr = this.uiState.appLang === "ar";

    let speedCheckInterval = 3 * 60 * 1000; // 1check/3mins

    let opts = {
      slowSpeedThreshold: 100, // Slow connection speed thresold in kb/s, any lower and user's connection is considered slow
      numOfChecks: 3, // Number of downloads/checks/iterations that get averaged
      failIfTooLong: true, // Default: true
      timeUntilLoadFailure: speedCheckInterval / 3, // Default: speedCheckInterval/numOfChecks
      loadFailureCheckInterval: 2000, // Default: 2secs
      imageSource:
        "https://upload.wikimedia.org/wikipedia/commons/b/b9/Pizigani_1367_Chart_1MB.jpg", // Default: TODO: host a 1MB image
      imageSize: 1040, // Image size in Kb, TODO: Calculate automatically
    };

    let checkInterval = new BehaviorSubject(speedCheckInterval);
    this.uiState.connectionChecks.intervalSubscription = checkInterval
      .pipe(
        switchMap((i) => interval(i)),
        tap(() => {
          netest(opts)
            .then((result) => {
              if (result.isSlow) {
                this.toastService.show(
                  isAr
                    ? "الرجاء التحقق من الإنترنت"
                    : "Please check your internet connection",
                  {
                    classname: "bg-danger text-light",
                    headertext: isAr
                      ? "سرعة الإنترنت بطيئة"
                      : "Slow Connection",
                    delay: 300000,
                  }
                );
              }
            })
            .catch((error) => {
              if (error.hasFailed) {
                this.toastService.show(
                  isAr
                    ? "الرجاء التحقق من الإنترنت"
                    : "Please check your internet connection",
                  {
                    classname: "bg-danger text-light",
                    headertext: isAr
                      ? "سرعة الإنترنت بطيئة"
                      : "Slow Connection",
                    delay: 300000,
                  }
                );
              }
            })
            .finally(() => checkInterval.next(speedCheckInterval));
        })
      )
      .subscribe();
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                  Listeners                                 */
  /* -------------------------------------------------------------------------- */
  //#region

  private watchConnectionStatus(): void {
    window.addEventListener("offline", () => {
      this.toastService.toasts = [];
      this.toastService.show("There is no internet connection", {
        classname: "bg-danger text-light",
        headertext: "Bad Connection",
        delay: 999999,
      });
    });

    window.addEventListener("online", () => {
      this.toastService.toasts = [];
      this.toastService.show("Your connection is stable", {
        classname: "bg-success text-light",
        headertext: "Stable Connection",
      });
    });
  }

  private watchConnectionChecksActivity(): void {
    // Watch for connection checks activation switch change
    this.appService.getConnectionChecksActivity().subscribe((isActivated) => {
      // console.log('isActivated :>> ', isActivated);
      // console.log('this.appService.areConnectionChecksActive().isConnectionCheckActivated :>> ', this.appService.areConnectionChecksActive().isConnectionCheckActivated);
      if (!isActivated)
        this.uiState.connectionChecks.intervalSubscription.unsubscribe();
      if (
        this.appService.areConnectionChecksActive()
          .isConnectionCheckActivated ||
        isActivated
      )
        this.initSpeedChecks();
    });
  }

  private watchPrintingProcess(): void {
    this.printingService
      .getActivePrintablePage()
      .subscribe((printingProcess) => {
        if (printingProcess) {
          this.uiState.pagePrint.isPrinting = true;
          AppUtils.disableScrolling();
        } else {
          this.uiState.pagePrint.isPrinting = false;
          AppUtils.enableScrolling();
        }
      });
  }

  //#endregion
}
