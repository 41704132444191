export enum ExportedBatchDataTypes {
  Policies,
  DraftPolicies,
  Quotations,
  Clients,
  ShadowAccounts,
  CancelledPolicies,
  SmsLogger,
  PendingCancellation,
}
