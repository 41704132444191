import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaterangepickerComponent } from './daterangepicker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslationModule } from '../../translation.module';


@NgModule({

   declarations: [
      DaterangepickerComponent
   ],

   imports: [
      CommonModule,
      NgxDaterangepickerMd.forRoot(),
      FormsModule,
      FontAwesomeModule,
      TranslationModule
   ],

   exports: [
      CommonModule,
      NgxDaterangepickerMd,
      FormsModule,
      DaterangepickerComponent,
   ]

})


export class DaterangepickerModule {
   constructor(library: FaIconLibrary) {
      library.addIcons(
         faTimes
      );
   }
}
