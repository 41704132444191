import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from "@angular/router";
import { AppRoutes } from "../../models/app/AppRoutes";
import { LoadingComponent } from "./loading.component";


const routes: Routes = [
   {
      path: "",
      children: [
         {
            path: AppRoutes.App.Error.sub,
            component: LoadingComponent,
         },
      ],
   },
];


@NgModule({

   declarations: [
   ],
   
   imports: [
      CommonModule, RouterModule.forChild(routes)
   ],

   exports: [
      RouterModule
   ],

})


export class LoadingRoutingModule { }
