import { DateRenderer } from "./DateRenderer";

export class CellRenderers {
  public static indexCellRenderer = (e) => {
    return e.node.rowIndex + 1;
  };
  public static nullableCellRenderer = (e) => {
    if (!e.value || e.value === 0) {
      return "-";
    } else return e.value;
  };
  public static variantDateArRenderer = (e) => {
    if (e.value && e.value[0] == "1") {
      return DateRenderer.hijriDateFormatterAr(e);
    } else {
      return DateRenderer.dateFormatterAr(e);
    }
  };

  public static isMigratedRendererEn = (e) => {
    if (e.value === true) {
      return "Yes";
    } else return "No";
  };
  public static isMigratedRendererAr = (e) => {
    if (e.value === true) {
      return "نعم";
    } else return "لا";
  };

  public static isRenewalRendererEn = (e) => {
    if (e.value === true) {
      return "Renewal";
    } else return "New Policy";
  };
  public static isRenewalRendererAr = (e) => {
    if (e.value === true) {
      return "تجديد";
    } else return "وثيقة جديدة";
  };

  public static isSmsSendRendererEn = (e) => {
    if (e.value && e.value === true) {
      return "Send";
    } else return "Failed";
  };
  public static isSmsSendRendererAr = (e) => {
    if (e.value && e.value === true) {
      return "تم الإرسال";
    } else return "فشل الإرسال";
  };

  public static monetaryValueArRenderer = (e) => {
    if (e.value) {
      return `رس ${e.value}.00`;
    } else return "-";
  };
  public static monetaryValueRenderer = (e) => {
    if (e.value) {
      return `${e.value}.00 SAR`;
    } else return "-";
  };

  public static dayValueArRenderer = (e) => {
    if (e.value) {
      return `يوم ${e.value}`;
    } else return "-";
  };
  public static dayValueRenderer = (e) => {
    if (e.value) {
      return `${e.value} Day`;
    } else return "-";
  };

  public static moneyValueArRenderer = (e) => {
    if (e.value) {
      return `رس ${e.value}`;
    } else return "-";
  };
  public static moneyValueRenderer = (e) => {
    if (e.value) {
      return `${e.value} SAR`;
    } else return "-";
  };

  public static leasingYearValueArRenderEn = (e) => {
    switch (e.value) {
      case 1:
        return "First Year";
      case 2:
        return "Second Year";
      case 3:
        return "Third Year";
      case 4:
        return "Fourth Year";
      case 5:
        return "Fifth Year";
      default:
        return "-";
    }
  };
  public static leasingYearValueRendererAr = (e) => {
    switch (e.value) {
      case 1:
        return "السنة الأولى";
      case 2:
        return "السنة الثانية";
      case 3:
        return "السنة الثالثة";
      case 4:
        return "السنة الرابعة";
      case 5:
        return "السنة الخامسة";
      default:
        return "-";
    }
  };

  public static leasingYearBalance = (e: any, index: any = null) => {
    if (e.value) {
      switch (index) {
        case "one":
          return e.value[0].leasingYearBalance;
        case "two":
          return e.value[1].leasingYearBalance;
        case "three":
          return e.value[2] ? e.value[2].leasingYearBalance : "-";
        case "four":
          return e.value[3] ? e.value[3].leasingYearBalance : "-";
        case "five":
          return e.value[4] ? e.value[4].leasingYearBalance : "-";
        default:
          return "-";
      }
    }
  };
  public static percentageValueRenderer = (e) => {
    if (e.value) {
      return `${e.value}%`;
    } else return "-";
  };
  public static vatPercentageValueRenderer = (e) => {
    if (e.value) {
      return `${e.value * 100}%`;
    } else return "-";
  };

  public static saudiLicenseCheckEnRenderer = (e) => {
    if (e.value) {
      return "Saudi";
    } else return "Foreign";
  };
  public static saudiLicenseCheckArRenderer = (e) => {
    if (e.value) {
      return "سعودي";
    } else return "أجنبي";
  };

  public static phoneNumberEnRenderer = (e) => {
    if (e.value) {
      return `${"+966"} ${e.value}`;
    } else {
      return "-";
    }
  };
  public static phoneNumberArRenderer = (e) => {
    if (e.value) {
      return `${e.value} ${"966+"}`;
    } else {
      return "-";
    }
  };

  public static identityTypeEnRenderer = (e) => {
    if (!e.value) {
      return "-";
    } else if (e.value == 1) {
      return "Non-Saudi";
    } else {
      return "Saudi";
    }
  };
  public static identityTypeArRenderer = (e) => {
    if (!e.value) {
      return "-";
    } else if (e.value == 1) {
      return "غير سعودية";
    } else {
      return "سعودية";
    }
  };

  public static expiryStateEnRenderer = (e) => {
    if (e.data && e.data?.expiryingIn > 0) {
      return "Active";
    } else if (e.data?.expiryingIn <= 0) {
      return "Expired";
    }
  };
  public static expiryStateArRenderer = (e) => {
    if (e.data && e.data?.expiryingIn > 0) {
      return "نشطة";
    } else if (e.data?.expiryingIn <= 0) {
      return "منتهية";
    }
  };

  public static expiryTimeEnRenderer = (e) => {
    if (e.value < 0) {
      return "Expired";
    } else return `${e.value} Days`;
  };
  public static expiryTimeArRenderer = (e) => {
    if (e.value < 0) {
      return "منتهية";
    } else if (e.value == 1) return `يوم`;
    else if (e.value == 2) return `يومين`;
    else if (e.value > 2 && e.value > 11) return `${e.value} أيام`;
    else return `${e.value} يوم`;
  };

  public static najmStatusEnRenderer = (e) => {
    if (e.value) {
      return "Updated";
    } else return "Pending";
  };
  public static najmStatusArRenderer = (e) => {
    if (e.value) {
      return "تم التحديث";
    } else return "قيد الإنتظار";
  };

  public static nullableDateEnRenderer = (e) => {
    if (!e || !e.value) {
      return "-";
    } else return DateRenderer.stringDateFormatterEn(e.value);
  };

  public static nullableDateArRenderer = (e) => {
    if (!e || !e.value) {
      return "-";
    } else return DateRenderer.stringDateFormatterAr(e.value);
  };
}
