import { Quote } from "./../../models/quote/Quote";
import { ExpiredQuotesState } from "../../models/quote/ExpiredQuotesState";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { LocallyStoredItemsKeys } from "src/app/shared/models/app/LocallyStoredItemsKeys";
import { ComparisonQuoteService } from "./comparison-quote.service";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { ApiRoutes } from "../../models/app/ApiRoutes";
import QuoteUtils from "../../helpers/utilities/QuoteUtils";
import AppUtils from "../../helpers/utilities/AppUtils";
import { Benefit } from "../../models/quote/Benefit";

@Injectable({
  providedIn: "root",
})
export class QuoteService {
  //#region

  apiUrl = environment.apiUrl;

  quotes: BehaviorSubject<Quote[]> = new BehaviorSubject<Quote[]>([]);
  backupQuotes: BehaviorSubject<Quote[]> = new BehaviorSubject<Quote[]>([]);
  selectedQuote: BehaviorSubject<Quote> = new BehaviorSubject<Quote>(
    {} as Quote
  );
  expiredQuotesState: BehaviorSubject<ExpiredQuotesState> = new BehaviorSubject<ExpiredQuotesState>(
    {
      totalQuotes: 0,
      expiredQuotes: [],
      hasAllQuotesExpired: false,
    }
  );

  //#endregion

  constructor(
    private quotesComparisonService: ComparisonQuoteService,
    private http: HttpClient
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                                   Filters                                  */
  /* -------------------------------------------------------------------------- */
  //#region

  sortByPriceDescending() {
    // Create distinct quotes with a single product each, in order to sort the products by price
    // let sortedQuotes: Quote[] = this.getDistinctQuotesProducts(this.getQuotes().value.concat());
    let quotes = this.quotes.value;

    // Sort the quotes by their single product's price
    quotes = quotes.sort(
      (q1, q2) =>
        q2.products[0].selectedDeductible.totalPaidAmount -
        q1.products[0].selectedDeductible.totalPaidAmount
    );

    quotes.forEach((e) => (e.isLowerPrice = false));
    quotes[0].isLowerPrice = true;

    this.setQuotes(quotes);
  }

  sortByPriceAscending() {
    // Create distinct quotes with a single product each, in order to sort the products by price
    // let sortedQuotes: Quote[] = this.getDistinctQuotesProducts(this.getQuotes().value.concat());
    let quotes = this.quotes.value;

    // Sort the quotes by their single product's price
    quotes = quotes.sort(
      (q1, q2) =>
        q1.products[0]?.selectedDeductible.totalPaidAmount -
        q2.products[0]?.selectedDeductible.totalPaidAmount
    );

    quotes.forEach((e) => (e.isLowerPrice = false));
    quotes[0].isLowerPrice = true;

    this.setQuotes(quotes);
  }

  // get minimum Paid Amount
  getMinQtPrice(): Quote {
    return this.quotes.value.reduce((prev, curr: Quote) => {
      console.log(
        curr.insuranceCompany.name,
        curr.products[0].selectedDeductible?.totalPaidAmount
      );
      if (
        prev.products[0].selectedDeductible?.totalPaidAmount >
        curr.products[0].selectedDeductible?.totalPaidAmount
      ) {
        curr.isLowerPrice = false;
        prev.isLowerPrice = true;
      } else {
        curr.isLowerPrice = true;
        prev.isLowerPrice = false;
      }
      this.sortByPriceAscending();
      return curr;
    }, this.quotes.value[0]);
  }

  compareAllQuotes(): void {
    // Clear the current comparison list
    this.quotesComparisonService.clearComparisonProducts();

    // Add all quotes' products to comparison list
    this.quotes.value.forEach((quote) => {
      this.quotesComparisonService.addComparisonProduct(
        quote.products[0],
        quote
      );
    });
  }

  /**
   * Selects a deductible with a chosen deductible value for all quotes' products that have it
   *
   * @param {number} deductibleValue
   * @memberof QuoteService
   */
  setCommonDeductible(deductibleValue: number): void {
    // Get products that have the chosen deductible
    this.quotes.value
      .map((q) =>
        q.products.filter(
          (p) =>
            p.deductibles.filter((d) => d.deductibleValue == deductibleValue)
              .length > 0
        )
      )
      // Flatten the array
      .reduce((a, b) => {
        return a.concat(b);
      }, [])
      .forEach((p) => {
        // Set the selected deductible for these products
        p.selectedDeductible = p.deductibles.filter(
          (d) => d.deductibleValue == deductibleValue
        )[0];

        p.benefits.forEach((benefit: Benefit) => {
          if (benefit.isChecked) {
            // Recalculate the total additional benefits of the newly selected deductible
            p.selectedDeductible.totalAdditionalBenefits +=
              benefit.benefitAmount;

            // Recalculate the VAT for selected benefits of the newly selected deductible
            let vatPremiumBreakdown = p.selectedDeductible.premiumBreakdowns.filter(
              (pmbd) => pmbd.breakdownTypeId == 5
            )[0];
            vatPremiumBreakdown.breakdownAmount +=
              benefit.benefitAmount * environment.SaudiVatPercentage;
          }
        });
      });
    this.getMinQtPrice();
  }

  resetFilters(): void {
    this.quotes.next(this.backupQuotes.value);
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                   Storage                                  */
  /* -------------------------------------------------------------------------- */
  //#region

  getStoredQuotes(): Quote[] {
    return JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.Quotes));
  }

  getQuotes(): BehaviorSubject<Quote[]> {
    if (this.quotes.value.length === 0) {
      this.setQuotes(
        JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.Quotes))
      );
    }

    return this.quotes;
  }

  getQuotesArr(): Quote[] {
    return JSON.parse(localStorage.getItem(LocallyStoredItemsKeys.Quotes));
  }

  resetQuotes() {
    const unAlteredQuotes = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.Quotes)
    );
    if (unAlteredQuotes) {
      this.quotes.next(unAlteredQuotes);
    }
  }

  setQuotes(quotes: Quote[]) {
    // Store quotes in local storage
    localStorage.setItem(
      LocallyStoredItemsKeys.AlteredQuotes,
      JSON.stringify(quotes)
    );

    // Set quotes
    this.quotes.next(quotes);

    // Set backup quotes (used to reset filters)
    this.backupQuotes.next(quotes);
  }

  getSelectedQuote(): BehaviorSubject<Quote> {
    // Get the selected quote from local storage
    const storedSelectedQuote = localStorage.getItem(
      LocallyStoredItemsKeys.SelectedQuote
    );

    // Set it as the selected quote if it exists
    if (storedSelectedQuote.length > 0) {
      // this.setSelectedQuote(JSON.parse(storedSelectedQuote));
    }

    return this.selectedQuote;
  }

  setExpiredQuotesState(expiredQuotesState: ExpiredQuotesState) {
    this.expiredQuotesState.next(expiredQuotesState);
  }

  addExpiredQuote(quote: Quote) {
    /* Add the quote to the expired quotes list */
    let expiredQuotesState = this.getExpiredQuotesState().value;
    expiredQuotesState.expiredQuotes.push(quote);

    /* Calculate total quotes */
    let totalQuotes = this.getQuotes().value;
    expiredQuotesState.totalQuotes = totalQuotes.length;

    /* Check if all quotes are expired */
    if (expiredQuotesState.expiredQuotes.length >= totalQuotes.length) {
      expiredQuotesState.hasAllQuotesExpired = true;
    }

    /* Update the state */
    this.setExpiredQuotesState(expiredQuotesState);
  }

  getExpiredQuotesState(): BehaviorSubject<ExpiredQuotesState> {
    return this.expiredQuotesState;
  }

  getPreviewQuote() {
    return JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteResponse)
    );
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                Miscellaneous                               */
  /* -------------------------------------------------------------------------- */
  //#region

  /**
   * Returns an array of distinct quotes each with a single product
   * @param quotes The quotes with the products that would be separated
   */
  private getDistinctQuotesProducts(quotes: Quote[]): Quote[] {
    let distinctQuotesProducts: Quote[] = [];

    quotes.forEach((quote) => {
      quote.products.forEach((product) => {
        // Create a new temp quote object clone (different reference)
        let tempQuote: Quote = JSON.parse(JSON.stringify(quote));

        // Set its products as the single product
        tempQuote.products = [product];

        // Add it to the array
        distinctQuotesProducts.push(tempQuote);
      });
    });

    return distinctQuotesProducts;
  }

  //#endregion

  /* -------------------------------------------------------------------------- */
  /*                                  Printing                                  */
  /* -------------------------------------------------------------------------- */
  //#region

  printQuote(
    quote: Quote,
    selectedVehicleId?: string,
    depreciationRate?: number
  ): Observable<any> {
    return this.http
      .post(
        `${this.apiUrl}${ApiRoutes.Quotation.QuotePrint}`,
        QuoteUtils.constructQuotePrintReq(
          quote,
          selectedVehicleId,
          depreciationRate
        ),
        { responseType: "blob", observe: "response" }
      )
      .pipe(tap((res) => AppUtils.saveFile(res, "application/pdf")));
  }

  printAllQuotes(): Observable<any> {
    let quotesPrintRequests = this.quotes.value.map((q) =>
      QuoteUtils.constructQuotePrintReq(q)
    );

    return this.http
      .post(
        `${this.apiUrl}${ApiRoutes.Quotation.AllQuotesPrint}`,
        quotesPrintRequests,
        { responseType: "blob", observe: "response" }
      )
      .pipe(tap((res) => AppUtils.saveFile(res, "application/zip")));
  }

  printComparisonSheet(): Observable<any> {
    let quotesPrintRequests = this.quotes.value.map((q) =>
      QuoteUtils.constructQuotePrintReq(q, undefined, 0.15)
    );

    return this.http
      .post(
        `${this.apiUrl}${ApiRoutes.Quotation.ComparisonSheetPrint}`,
        quotesPrintRequests,
        { responseType: "blob", observe: "response" }
      )
      .pipe(tap((res) => AppUtils.saveFile(res, "application/pdf")));
  }

  //#endregion
}
