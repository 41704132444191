import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function HttpLoaderFactory(httpClient: HttpClient) {
   return new TranslateHttpLoader(httpClient);
}


@NgModule({

   declarations: [
   ],

   imports: [
      CommonModule,
      TranslateModule.forChild({})
   ],

   exports: [
      TranslateModule,
   ],

   providers: [
   ],

})


export class TranslationModule {
}
