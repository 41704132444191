import { Component, OnInit } from '@angular/core';
import AppUtils from '../../helpers/utilities/AppUtils';
import { ComparisonSheetData, ComparisonSheetProcess } from '../../models/printed-pages/ComparisonSheetPage';
import { PrintablePages } from '../../models/printed-pages/PrintablePages';
import { PrintablePageComponent } from '../../models/printed-pages/PrintablePageComponent';
import { LanguageService } from '../../services/app/language.service';
import { PrintingService } from '../../services/printing/printing.service';
import { ScriptService } from '../../services/scripts/script.service';
import { AppLanguage } from '../../models/app/AppLanguage';


declare let html2pdf: any;


@Component({
   selector: 'app-comparison-sheet',
   templateUrl: './comparison-sheet.component.html',
   styleUrls: ['./comparison-sheet.component.scss']
})


export class ComparisonSheetComponent implements OnInit, PrintablePageComponent {

   //#region States

   state = {
      comparisonSheetData: undefined as ComparisonSheetData
   };

   uiState = {
      appLang: undefined,
      pdfOpts: {
         margin: [0, 0, -1, 0], // Counter clockwise
         filename: 'Comparison-Sheet.pdf',
         image: { type: 'jpeg', quality: 1 },
         html2canvas: { scale: 6 },
         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
         pageBreak: 'avoid-all'
      },
   };

   //#endregion


   constructor(
      private printingService: PrintingService,
      private scriptService: ScriptService,
      private langService: LanguageService
   ) { }


   ngOnInit(): void {
      this.watchPrintingProcess();
      this.uiState.appLang = this.langService.getAppLang().value;
      this.initLanguageSpecificOpts();
   }


   private initLanguageSpecificOpts(): void {
      switch(this.uiState.appLang) {
         case (AppLanguage.ENGLISH): this.uiState.pdfOpts.margin = [0, 0, -1, -200]; break;
         case (AppLanguage.ARABIC): this.uiState.pdfOpts.margin = [0, 0, -1, 0]; break;
         default: this.uiState.pdfOpts.margin = [0, 0, -1, 0]; break;
      }
   }


   watchPrintingProcess(): void {
      this.printingService.getActivePrintablePage().subscribe(async (process) => {

         if (process && process.pageType == PrintablePages.ComparisonSheet) {

            // Cast the process input to comparison sheet proccess
            let comparisonSheetProccess: ComparisonSheetProcess = process; 
            this.state.comparisonSheetData = comparisonSheetProccess.input;

            // Load the PDF generation scripts
            await this.scriptService.load('jspdf');
            await this.scriptService.load('html2canvas');
            await this.scriptService.load('html2pdf')
            .then(async () => {
               if (comparisonSheetProccess.output == 'save') await this.downloadPdf();
               else if (comparisonSheetProccess.output == 'print') await this.printPdf();
            })
            .then(() => {
               AppUtils.enableScrolling();
               this.printingService.setActivePrintablePage(undefined);
            })
            .catch(() => this.onPrintingError());

         }
      });
   }


   onPrintingError(): void {
      console.error('Invoice generation error');
   }


   async downloadPdf(): Promise<any> {
      let printablePage = await window.document.getElementsByClassName("printable-page")[0];
      await html2pdf().set(this.uiState.pdfOpts).from(printablePage).save();
   }


   async printPdf(): Promise<any> {
      let printablePage = await window.document.getElementsByClassName("printable-page")[0];
      let pdf = await html2pdf().set(this.uiState.pdfOpts).from(printablePage).toPdf().get('pdf');
      pdf.autoPrint();
      window.open(pdf.output('bloburl'), '_blank');
   }

}
