import { DateRenderer } from "./DateRenderer";
import { CellRenderers } from "./CellRenderers";
import { StatusBadgeRenderer } from "./StatusBadgeRenderer";


export const DraftPoliciesColumnsDefsEn = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "Status",
      field: "status",
      sortable: false,
      filter: false,
      minWidth: 130,
      cellStyle: StatusBadgeRenderer.quotationStatusBadgeEn
   },
   {
      headerName: "Reference No.",
      field: "requestReferenceId",
      sortable: false,
      filter: false,
      minWidth: 130,
   },
   {
      headerName: "Lessee ID",
      field: "insuredIdentityNumber",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 110,
   },
   {
      headerName: "Lessee Name",
      field: "insuredFullName",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "Insured Birth Date",
      field: "insuredBirthDate",
      sortable: false,
      filter: false,
      minWidth: 180,
   },
   {
      headerName: "NCD Eligibility",
      field: "ncdFreeYearName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "Vehicle",
      field: "vehicleSummary",
      cellRenderer: 'ImageCell',
      tooltip: () => 'Click to display vehicle details',
      sortable: false,
      filter: false,
      minWidth: 230,
   },
   {
      headerName: "Vehicle Color",
      field: "vehicleMajorColorNameAr",
      sortable: false,
      filter: false,
      minWidth: 120,
   },
   {
      headerName: "Vehicle Sum Insured",
      field: "vehicleSumInsured",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 180
   },
   {
      headerName: "Deductible Value",
      field: "deductibleValue",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150
   },
   {
      headerName: "Policy Perminum",
      field: "policyPremium",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150
   },
   {
      headerName: "Additional Benefits",
      field: "totalAdditioanlBenefits",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Taxable Amount",
      field: "taxableAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Total VAT",
      field: "totalVatAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Paid Amount",
      field: "totalPaidAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "Submitted On",
      cellRenderer: DateRenderer.specificDateFormatterEn,
      field: "createdDate",
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "Submitted By",
      field: "createdBy",
      sortable: false,
      filter: false,
      minWidth: 230,
   }
];

export const DraftPoliciesColumnsDefsAr = [
   {
      headerName: "#",
      valueGetter: (e) => CellRenderers.indexCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 70,
   },
   {
      headerName: "الحالة",
      field: "statusAr",
      sortable: false,
      filter: false,
      minWidth: 130,
      cellStyle: StatusBadgeRenderer.quotationStatusBadgeAr
   },
   {
      headerName: "الرقم المرجعي",
      field: "requestReferenceId",
      sortable: false,
      filter: false,
      minWidth: 130,
   },
   {
      headerName: "رقم الهوية",
      field: "insuredIdentityNumber",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 110,
   },
   {
      headerName: "أسم المستأجر",
      field: "insuredFullNameAr",
      tooltip: (e) => e.value,
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "تاريخ الميلاد",
      field: "insuredBirthDate",
      sortable: false,
      filter: false,
      minWidth: 180,
   },
   {
      headerName: "أحقية عدم وجود مطالبات",
      field: "ncdFreeYearName",
      cellRenderer: (e) => CellRenderers.nullableCellRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 230,
   },
   {
      headerName: "المركبة",
      field: "vehicleSummary",
      cellRenderer: 'ImageCell',
      tooltip: () => 'أضغط لعرض تفاصيل المركبة',
      sortable: false,
      filter: false,
      minWidth: 230,
   },
   {
      headerName: "لون المركبة",
      field: "vehicleMajorColorNameAr",
      sortable: false,
      filter: false,
      minWidth: 120,
   },
   {
      headerName: "القيمة التقديرية",
      field: "vehicleSumInsured",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 180
   },
   {
      headerName: "قيمة التحمل",
      field: "deductibleValue",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150
   },
   {
      headerName: "سعر الوثيقة",
      field: "policyPremium",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150
   },
   {
      headerName: "الفوائد الإضافية",
      field: "totalAdditioanlBenefits",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "الإجمالي قبل الضريبة",
      field: "taxableAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "ضريبة القيمة المضافة",
      field: "totalVatAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "القيمة المدفوعة",
      field: "totalPaidAmount",
      cellRenderer: (e) => CellRenderers.monetaryValueArRenderer(e),
      sortable: false,
      filter: false,
      minWidth: 150,
   },
   {
      headerName: "تاريخ الإنشاء",
      cellRenderer: DateRenderer.specificDateFormatterAr,
      field: "createdDate",
      sortable: false,
      filter: false,
      minWidth: 200,
   },
   {
      headerName: "أنشئ بواسطة",
      field: "createdBy",
      sortable: false,
      filter: false,
      minWidth: 230,
   }
];
