export interface AppScript {
   name: string;
   src: string;
   loaded?: boolean;
}

export const AppScripts: AppScript[] = [
   { name: 'jspdf', src: '../../../assets/scripts/jspdf.js' },
   { name: 'html2canvas', src: '../../../assets/scripts/html2canvas.js' },
   { name: 'html2pdf', src: '../../../assets/scripts/html2pdf.js' }
];
